/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect, useContext, useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ContactHeaderCard from './ContactHeaderCard'
import EditContactModal from '../EditContactModal'
import DeleteModal from 'components/DeleteModal'
import { getContact, deleteContacts, saveContact } from 'api/contacts'
import { getUserFollows } from 'api/user_follows'
import { setFollow } from 'api/follows'
import CustomizedSnackbar from 'components/CustomizedSnackbar'
import CustomField from 'classes/customFields'
import { queryClient } from 'App'
import FormSubmissionModal from 'pages/forms/FormSubmissionModal'
import ContactAbout from './ContactAbout'
import ContactCompanyInfo from './ContactCompanyInfo'
import Account from 'classes/accounts'
import AccountActivity from 'pages/accounts/AccountDashboard/AccountActivity'
import AccountPageProvider from 'pages/accounts/AccountDashboard/AccountPageProvider'
import DomainDataModal from 'pages/accounts/AccountDashboard/DomainDetails/DomainDataModal'
import ContactInsightsCard from './ContactInsightsCard'
import { ShareContext } from 'share-context'
import AccessControl from 'components/AccessControl'
import { components } from 'session-context'

const useStyles = makeStyles(theme => ({
  contactPage: {
    backgroundColor: '#F5F5F5'
  },
  headerDiv: {
    padding: '10px 20px 20px 20px'
  },
  mainDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px'
  },
  left: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px'
  },
  right: {
    width: '66%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px'
  },
  card: {
    marginBottom: '20px'
  },
  sideDiv: {
    gridArea: 'side',
    padding: '0px 20px 10px 0px',
    height: 'calc(100vh - 150px)'
  },
  statsDiv: {
    gridArea: 'stats',
    padding: '20px 20px 20px 0'
  }
}))

function ContactPage ({ match }) {
  const classes = useStyles()
  const personID = match.params.contact_id
  const [person, setPerson] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [redirect, setRedirect] = useState<string | null>(null)
  const [followDict, setFollowDict] = useState({})
  const [snackState, setSnackState] = useState({
    open: false,
    variant: 'success',
    message: 'Your changes have been saved'
  })
  const { data: { list: customFields }, isLoading } = CustomField.loadAll(undefined, 'people')
  const [submissionModalOpen, setSubmissionModalOpen] = useState(false)
  const [submissionID, setSubmissionID] = useState<number>()
  const { data: acc } = Account.loadOneByDomain(person?.attributes?.domain)

  const getFollowStatus = useCallback(() => {
    getUserFollows().then(response => {
      const dict = Object.assign({}, ...response.data.map((x: any) => ({ [x.id]: x.attributes.followed })))
      setFollowDict(dict)
    })
  }, [])

  function setFollowStatus (value: any) {
    const followed = value
    const person_ids = [parseInt(personID)]
    // Changes state in database
    setFollow({ followed, person_ids })

    // Changes follow dict
    setFollowDict({ ...followDict, [personID]: value })
  }

  const editContact = () => {
    setSnackState({
      open: true,
      variant: 'success',
      message: 'Your changes have been saved'
    })
  }

  const { flags } = useContext(ShareContext)

  const dbAttributes = [
    'first_name',
    'last_name',
    'honorific',
    'phone',
    'mobile_phone',
    'email',
    'title',
    'description',
    'lead_source',
    'other_email',
    'other_phone',
    'custom_fields',
    'company_name',
    'company_website',
    'company_industry',
    'company_employee_count',
    'company_revenue',
    'city',
    'state',
    'country',
    'bio',
    'role',
    'seniority',
    'linkedin_handle',
    'company_sector',
    'company_phone',
    'company_description',
    'company_location',
    'company_timezone',
    'company_twitter_handle',
    'company_tech',
    'company_linkedin_handle',
    'avatar_url',
    'company_avatar_url',
    'facebook_handle',
    'twitter_handle',
    'instagram_handle'
  ]

  function savePerson (values: any) {
    const account_id = values.account_id
    const personAttributes = {}
    dbAttributes.forEach(attr => {
      personAttributes[attr] = values[attr]
    })
    saveContact({ personAttributes, personID, account_id }).then(_ => {
      getContact({
        personID,
        includes: ['account', 'created_by_user']
      }).then(person => {
        setPerson(person)
      })
    })
  }

  function beginDelete () {
    deleteContacts({ contactIDs: [personID] }).then(() => {
      setDeleteModalOpen(false)
      setRedirect('/contact_management')
    })
  }

  useEffect(() => {
    if (personID) {
      getContact({
        personID,
        includes: ['account', 'created_by_user', 'meetings']
      }).then(person => {
        setPerson(person)
      })
    } else {
      setPerson({})
    }
  }, [personID])

  useEffect(() => {
    getFollowStatus()
    queryClient.invalidateQueries('custom_fields')
  }, [getFollowStatus, personID])

  if (redirect) {
    return <Redirect to={redirect} push />
  }

  if (!person) {
    return <></>
  }

  if (isLoading) {
    return <></>
  }

  if (customFields.length === 1 && !customFields[0]) {
    return <></>
  }

  return (
    <AccessControl requiredComponent={components.CONTACTS}>
      <AccountPageProvider
        id={acc?.id}
      >
        <div className={classes.contactPage}>
          <div className={classes.headerDiv}>
            <ContactHeaderCard
              setFollow={setFollowStatus}
              person={person}
              edit={() => setModalOpen(true)}
              delete={() => setDeleteModalOpen(true)}
              followDict={followDict}
              savePerson={savePerson}
              accountID={acc?.id}
            />
          </div>
          {flags['ai-summaries'] && personID && (
            <div style={{ width: '100%', padding: 20 }}>
              <ContactInsightsCard contactId={personID} />
            </div>
          )}
          <div className={classes.mainDiv}>
            <div className={classes.left}>
              <div className={classes.card}>
                <ContactAbout contact={person?.attributes} customFields={customFields} setEditModalOpen={setModalOpen} />
              </div>
              <div className={classes.card}>
                <ContactCompanyInfo account={acc} />
              </div>
            </div>
            <div className={classes.right}>
              {acc?.domain && (
                <div className={classes.card}>
                  <AccountActivity
                    setContact={() => console.log('setContact (NOOP for contact page)')}
                    setSubmissionID={setSubmissionID}
                    setSubmissionModalOpen={setSubmissionModalOpen}
                    accountName={acc?.name}
                    contactID={person?.id}
                    accountID={acc?.id}
                    domain={acc?.domain}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <FormSubmissionModal
          open={submissionModalOpen}
          setOpen={setSubmissionModalOpen}
          submissionID={submissionID || 0}
          contact={person}
        />
        <EditContactModal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          person={person.attributes}
          customFields={customFields || []}
          savePerson={(values: any) => {
            savePerson(values)
            setModalOpen(false)
            editContact()
          }}
        />
        <DomainDataModal
          company={acc}
          onSave={() => console.log('save')}
        />
        <DeleteModal
          open={deleteModalOpen}
          onHide={() => setDeleteModalOpen(false)}
          deleteObject={beginDelete}
          message='Are you sure you want to delete this contact?'
          title='Delete Contact'
        />
        <CustomizedSnackbar state={snackState} handler={setSnackState} />
      </AccountPageProvider>
    </AccessControl>
  )
}

export default ContactPage
