import { makeStyles } from '@material-ui/core/styles'
import Form, { fieldReducer } from 'classes/forms'
import TextField from '@material-ui/core/TextField'
import { useEffect, useReducer } from 'react'
import { Divider, Radio, Button, Icon, Checkbox, MenuItem } from 'library/materialUI'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { Select } from 'cf-components/material-wrappers/MaterialComponents'
import { IntegrationMappingCls } from 'classes/IntegrationMapping'
import { useHistory } from 'react-router-dom'
import { FormControl, Grid, Select as MuiSelect } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: '25px'
  },
  sectionTitle: {
    color: '#343434',
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '5px'
  },
  sectionSubtitle: {
    color: '#808080',
    fontFamily: 'poppins',
    fontSize: '12px',
    marginTop: '5px'
  },
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  checkboxLabel: {
    color: '#343434',
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: 'bold'
  },
  richText: {
    border: '1px solid #000000'
  },
  unmappedWarning: {
    backgroundColor: '#FFF8E3',
    margin: '10px',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '5px'
  },
  iconMenu: {
    padding: 0,
    margin: '0 10px 0 10px',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiTouchRipple-root': {
      display: 'none'
    }
  },
  iconContainer: {
    cursor: 'pointer',
    padding: '12px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  iconSelectContainer: {
    '& .MuiSelect-root': {
      padding: '0 30px 0 10px'
    }
  },
  iconSelect: {
    height: 35,
    padding: 10
  }
}))

interface ButtonSettingsProps {
  dispatch: any
  buttonText: string
  buttonPlacement: string
  buttonIcon: string
  useButtonIcon: boolean
  setCanSave: (value: boolean) => void
}

interface FieldSettingsProps {
  form: Form
  dispatch: any
  row: number
  itemIndex: number
  fieldID: string
  fieldType: string
  setCanSave: (value: boolean) => void
  integration: string
  isCustomField?: boolean
}

export function ButtonSettings ({ dispatch, buttonText, buttonPlacement, buttonIcon, useButtonIcon, setCanSave }: ButtonSettingsProps) {
  const classes = useStyles()

  const iconOptions = ['keyboardArrowRight', 'matArrowForward', 'callMade', 'expandCircleRight', 'arrowCircleRight', 'outboundFill', 'expandCircleRightWhite', 'arrowCircleRightWhite', 'outboundFillWhite']
  return (
    <div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          Button Text
        </div>
        <Divider />
        <div className={classes.sectionSubtitle}>
          Enter the text you would like to display on your button
        </div>
        <div>
          <TextField
            value={buttonText}
            onChange={(event) => { dispatch({ type: 'buttonText', value: event.target.value }); setCanSave(true) }}
            margin='dense'
            variant='outlined'
          />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          Button Alignment
        </div>
        <Divider />
        <div className={classes.sectionSubtitle}>
          Choose how you'd like your button to be aligned
        </div>
        <div>
          <Radio
            label='Left'
            value={buttonPlacement === 'left'}
            checked={buttonPlacement === 'left'}
            handleChange={() => { dispatch({ type: 'buttonPlacement', value: 'left' }); setCanSave(true) }}
          />
          <Radio
            label='Center'
            value={buttonPlacement === 'center'}
            checked={buttonPlacement === 'center'}
            handleChange={() => { dispatch({ type: 'buttonPlacement', value: 'center' }); setCanSave(true) }}
          />
          <Radio
            label='Right'
            value={buttonPlacement === 'right'}
            checked={buttonPlacement === 'right'}
            handleChange={() => { dispatch({ type: 'buttonPlacement', value: 'right' }); setCanSave(true) }}
          />
          <Radio
            label='Full-width'
            value={buttonPlacement === 'full'}
            checked={buttonPlacement === 'full'}
            handleChange={() => { dispatch({ type: 'buttonPlacement', value: 'full' }); setCanSave(true) }}
          />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          Button Icon
        </div>
        <Divider />
        <div className={classes.sectionSubtitle}>
          Display an icon after the text on your button
        </div>
        <div className={classes.checkboxDiv}>
          <Checkbox
            checked={useButtonIcon}
            onChange={(value: boolean) => {
              dispatch({ type: 'useButtonIcon', value: value }); setCanSave(true)
            }}
          />
          <div className={classes.checkboxLabel}>
            Include icon in button
          </div>
        </div>
        {useButtonIcon && (
          <div>
            <FormControl variant='outlined' className={classes.iconSelectContainer}>
              <MuiSelect
                value={buttonIcon}
                MenuProps={{
                  disablePortal: true,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
                renderValue={(value) => <Icon icon={value} />}
                displayEmpty
                className={classes.iconSelect}
              >
                <MenuItem value='' className={classes.iconMenu}>
                  <Grid container style={{ width: 'fit-content' }}>
                    {iconOptions.map((iconType, index) => (
                      <Grid item xs={4} key={index} style={{ display: 'flex', justifyContent: 'center', width: 16, height: 45 }}>
                        <div
                          className={classes.iconContainer}
                          onClick={() => {
                            dispatch({ type: 'buttonIcon', value: iconType })
                            setCanSave(true)
                          }}
                        >
                          <Icon icon={iconType} size='md' />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </MenuItem>
              </MuiSelect>
            </FormControl>
          </div>
        )}
      </div>
    </div>
  )
}

export default function FieldSettings ({ form, dispatch, row, itemIndex, fieldID, fieldType, setCanSave, integration, isCustomField }: FieldSettingsProps) {
  const classes = useStyles()
  const layout = form?.formLayout || []
  const [field, updateField] = useReducer(fieldReducer, layout[row][itemIndex])
  const { data: crmContactMapping, isLoading } = IntegrationMappingCls.loadOne('contacts', integration)
  const mapped = crmContactMapping?.mapping?.find((item) => (item.internal_field === fieldID))
  const history = useHistory()
  const handleChange = (action: string, newVal: any) => {
    updateField({ type: action, value: newVal })
    setCanSave(true)
  }
  useEffect(() => {
    dispatch({ type: 'updateRow', value: [...layout[row].slice(0, itemIndex), field, ...layout[row].slice(itemIndex + 1)], index: row })
    setCanSave(true)
    // eslint-disable-next-line
  }, [field])

  if (isLoading) {
    return (
      <></>
    )
  }

  if (fieldType === 'title' || fieldType === 'subtitle') {
    return (
      <div>
        <div className={classes.section}>
          <div className={classes.sectionTitle}>
            {fieldType === 'title' ? 'Header Text' : 'Paragraph Text'}
          </div>
          <Divider />
          <div className={classes.sectionSubtitle}>
            Enter the text you would like to display
          </div>
          <div className={classes.richText}>
            <RichTextEditor
              message={field?.fieldLabel}
              onChange={(value) => handleChange('updateLabel', value)}
              lines={4}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {!mapped && (
        <div className={classes.unmappedWarning}>
          <Icon icon='warning' color='#F4D451' />
          <div style={{ marginLeft: '15px', marginRight: '15px' }}>
            This field is not mapped and won’t be sent to your CRM
          </div>
          <div style={{ minWidth: '100px' }}>
            <Button
              color='primary'
              variant='outlined'
              onClick={() => history.push('/settings/' + integration)}
            >
              Map Field
            </Button>
          </div>
        </div>
      )}
      {isCustomField && (
        <div className={classes.unmappedWarning}>
          <Icon icon='warning' color='#F4D451' />
          <div style={{ marginLeft: '15px', marginRight: '15px' }}>
            Changes made here will only affect this form. Go to the custom field page if you need to edit the custom field.
          </div>
          <div style={{ minWidth: '100px' }}>
            <Button
              color='primary'
              variant='outlined'
              onClick={() => window.open('/#/contact_management/custom_fields', '_blank')}
            >
              Custom Fields
            </Button>
          </div>
        </div>
      )}
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          Field Label
        </div>
        <Divider />
        <div className={classes.sectionSubtitle}>
          Label your field. This will be visible to visitors.
        </div>
        <div>
          <TextField
            value={field?.fieldLabel}
            onChange={(event) => handleChange('updateLabel', event.target.value)}
            margin='dense'
            variant='outlined'
          />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          Placeholder Text
        </div>
        <Divider />
        <div className={classes.sectionSubtitle}>
          Add an example inside the field
        </div>
        <div>
          <TextField
            value={field?.placeholder}
            onChange={(event) => handleChange('updatePlaceholder', event.target.value)}
            margin='dense'
            variant='outlined'
          />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          Helper Text
        </div>
        <Divider />
        <div className={classes.sectionSubtitle}>
          Add a short description below the field
        </div>
        <div>
          <TextField
            value={field?.helperText}
            onChange={(event) => handleChange('updateHelper', event.target.value)}
            margin='dense'
            variant='outlined'
          />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          Required
        </div>
        <Divider />
        <div className={classes.sectionSubtitle}>
          Prevent submission if this field is empty
        </div>
        <div className={classes.checkboxDiv}>
          <Checkbox
            checked={field?.isRequired}
            name='Required'
            onChange={() => handleChange('updateRequired', !field.isRequired)}
          />
          <div className={classes.checkboxLabel}>
            Required
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          Hidden
        </div>
        <Divider />
        <div className={classes.sectionSubtitle}>
          Hide this field from visitors
        </div>
        <div className={classes.checkboxDiv}>
          <Checkbox
            checked={field?.isHidden}
            name='Hidden'
            onChange={() => handleChange('updateHidden', !field.isHidden)}
          />
          <div className={classes.checkboxLabel}>
            Hidden
          </div>
        </div>
      </div>
      {field?.isHidden && (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>
            Default Value
          </div>
          <Divider />
          <div className={classes.sectionSubtitle}>
            Enter a default value that will be assigned to this field for everyone who fills out the form
          </div>
          <div>
            {field?.options?.length > 0 && field?.options[0] !== '' ? (
              <div>
                <Select
                  onChange={(value) => handleChange('updateHiddenDefault', value)}
                  value={field?.hiddenDefault}
                  options={field.options.map((item) => ({ value: item, label: item }))}
                // disableClear
                />
              </div>
            ) : (
              <TextField
                value={field?.hiddenDefault}
                onChange={(event) => handleChange('updateHiddenDefault', event.target.value)}
                margin='dense'
                variant='outlined'
              />
            )}
          </div>
        </div>
      )}
      {field.options.length > 0 && (
        <div className={classes.section}>
          <div className={classes.sectionTitle}>
            Options Preview
          </div>
          <Divider />
          <div className={classes.sectionSubtitle}>
            Preview the options that your visitors will be able to select
          </div>
          <div>
            <Select
              onChange={() => null}
              value={null}
              options={field.options.map((item) => ({ value: item, label: item }))}
            // disableClear
            />
          </div>
        </div>
      )}
      {(fieldID.includes('email') || fieldID.includes('other_email')) && (
        <>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>
              Invalid Email Addresses
            </div>
            <Divider />
            <div className={classes.checkboxDiv}>
              <Checkbox
                checked={field?.blockEmail}
                name='Block unwatned email addresses'
                onChange={() => handleChange('updateBlock', !field.blockEmail)}
              />
              <div className={classes.checkboxLabel}>
                Block unwanted email addresses
              </div>
            </div>
            <div className={classes.sectionSubtitle}>
              Which types of email addresses are unwanted?
            </div>
            <div className={classes.checkboxDiv}>
              <Checkbox
                checked={field?.spamUnwanted}
                name='Spam domains'
                onChange={() => handleChange('updateSpam', !field.spamUnwanted)}
              />
              <div className={classes.checkboxLabel}>
                Spam domains
              </div>
            </div>
            <div className={classes.checkboxDiv}>
              <Checkbox
                checked={field?.nonBusinessUnwanted}
                name='Non-business emails'
                onChange={() => handleChange('updateNonBusiness', !field.nonBusinessUnwanted)}
              />
              <div className={classes.checkboxLabel}>
                Non-business emails
              </div>
            </div>
            <div className={classes.checkboxDiv}>
              <Checkbox
                checked={field?.blockedDomainsUnwanted}
                name='Blocked domains'
                onChange={() => handleChange('updateBlockedDomain', !field.blockedDomainsUnwanted)}
              />
              <div className={classes.checkboxLabel}>
                Blocked domains
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
