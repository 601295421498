import { doPost, doGet } from './api'

function getRawROIReport () {
  return doPost({ path: '/api/salesforce/reports/roi/raw' })
}

function getTenantROIOppSummaryReport ({ tenantID, start, end }: { tenantID: number, start: string, end: string }) {
  return doGet({
    path: `/api/salesforce/reports/roi/opp_summary/${tenantID}?filter[start]=${start}&filter[end]=${end}`
  })
}

function getROIOppSummaryReport ({ start, end }: { start: string, end: string }) {
  return doGet({ path: `/api/salesforce/reports/roi/opp_summary?filter[start]=${start}&filter[end]=${end}` })
}

function getAgentReport ({ start, end }: { start: string, end: string }) {
  return doGet({ path: `/reports/basic/agent1?filter[start]=${start}&filter[end]=${end}`, useChatURL: true })
}

function getTrafficCampaigns ({ start, end }: { start: string, end: string }) {
  return doGet({ path: '/reporting/traffic_campaigns', useChatURL: true, searchParams: { filters: [[{ field: 'start_date', operator: 'gte', value: start }, { field: 'end_date', operator: 'lte', value: end }]] } })
}

function getTrafficMediums ({ start, end }: { start: string, end: string }) {
  return doGet({ path: '/reporting/traffic_mediums', useChatURL: true, searchParams: { filters: [[{ field: 'start_date', operator: 'gte', value: start }, { field: 'end_date', operator: 'lte', value: end }]] } })
}

function getAgentPerformance ({ startDate, endDate }: { startDate: string, endDate: string }) {
  return doPost({
    path: '/reports/basic/agent_performance',
    useChatURL: true,
    data: {
      attributes: {
        start_date: startDate,
        end_date: endDate
      }
    }
  })
}

function getConversationSummary ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/basic/conversation_summary',
    data: { attributes },
    useChatURL: true
  })
}

function getHeatMap ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/basic/conversation_heat_map',
    data: { attributes },
    useChatURL: true
  })
}

function getConversationTags ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/basic/conversation_tags',
    data: { attributes },
    useChatURL: true
  })
}

function getCountryDrillDown ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/basic/country_drilldown',
    useChatURL: true,
    data: { attributes }
  })
}

function getFunnel ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/basic/conversation_funnel',
    data: { attributes },
    useChatURL: true
  })
}

function getLineChart ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/basic/conversation_timeline',
    data: { attributes },
    useChatURL: true
  })
}

function getFAQOverview ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/faq/overview',
    data: { attributes },
    useChatURL: true
  })
}

function getFAQPieChart ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/faq/pie_chart',
    data: { attributes },
    useChatURL: true
  })
}

function getFAQTimeline ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/faq/timeline',
    data: { attributes },
    useChatURL: true
  })
}

function getMissingFAQs ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/faq/missing_topics',
    data: { attributes },
    useChatURL: true
  })
}

function FAQExport ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/faq:export',
    data: { attributes },
    useChatURL: true,
    returnText: true
  })
}

function FAQMissedTopicExport ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/faq/missing_topics:export',
    data: { attributes },
    useChatURL: true,
    returnText: true
  })
}

function ConversationExport ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/conversations:export_csv',
    data: { attributes },
    useChatURL: true,
    returnText: true
  })
}
function TrafficSourceExport ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/conversations/traffic_sources:export_csv',
    data: { attributes },
    useChatURL: true,
    returnText: true
  })
}

function getFormPerformance ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/forms/form_performance',
    data: { attributes },
    useChatURL: true
  })
}

function getBotPerformance ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/bots/bot_performance',
    data: { attributes },
    useChatURL: true
  })
}

function getEmailPerformance ({ attributes }: { attributes: any }) {
  return doPost({
    path: '/reports/emails/email_performance',
    data: { attributes },
    useChatURL: true
  })
}

function getVisitExport ({ attributes }: { attributes: any }) : Promise<any> {
  return doPost({
    path: '',
    data: { attributes },
    useChatURL: true,
    returnText: true
  })
}

export {
  ConversationExport,
  FAQExport,
  FAQMissedTopicExport,
  getAgentPerformance,
  getAgentReport,
  getBotPerformance,
  getEmailPerformance,
  getConversationSummary,
  getConversationTags,
  getCountryDrillDown,
  getFAQOverview,
  getFAQPieChart,
  getFAQTimeline,
  getFormPerformance,
  getFunnel,
  getHeatMap,
  getLineChart,
  getMissingFAQs,
  getRawROIReport,
  getROIOppSummaryReport,
  getTenantROIOppSummaryReport,
  getTrafficCampaigns,
  getTrafficMediums,
  TrafficSourceExport,
  getVisitExport
}
