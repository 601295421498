import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip, Button, IconButton, Avatar, Typography } from '@material-ui/core'
import { Avatar as UserAvatar, getInitials } from 'cf-components/Avatars'
import { SignalsScoreGear } from 'cf-components/SignalsScoreGear'
import { useResizeDetector } from 'react-resize-detector'
import SalesForceCloud from 'img/Icon simple-salesforce.svg'
import HubspotLogo from 'img/hubspotClear.png'
import 'react-circular-progressbar/dist/styles.css'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import NotificationsIcon from '@material-ui/icons/Notifications'
import SnoozeIcon from '@material-ui/icons/Snooze'
import { abbreviateName } from 'helpers/strings'
import { Icon } from 'library/materialUI/Icon'
import OppStatusText from 'pages/dashboard/OppStatusText'
import { Modal, ModalSection } from 'library/Modal'
import Picker from 'library/Picker'
import Account from 'classes/accounts'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 85
  },
  cards: {
    display: 'flex'
  },
  cardValue: {
    fontSize: '16px',
    color: '#343434',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#707070',
    fontSize: '14px'
  },
  headerCard: {
    borderRadius: '5px',
    padding: 10,
    textAlign: 'center',
    minWidth: 220,
    marginRight: 20,
    display: 'grid',
    gridTemplateRows: '40px auto',
    gridGap: 5,
    border: '1px solid #D3D3D3',
    backgroundColor: '#FFFFFF'
  },
  buttonIcon: {
    margin: 0
  },
  button: {
    backgroundColor: '#343434'
  },
  buttonSize: {
    minWidth: 30
  },
  accountTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  accountDetails: {
    color: theme.palette.primary.main,
    display: 'grid',
    paddingBottom: 5,
    alignContent: 'center',
    marginLeft: '20px'
  },
  accountName: {
    display: 'block',
    fontSize: '24px',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px'
  },
  accountNameContainer: {
    display: 'flex',
    alignItems: 'center',
    wrap: 'flex-wrap'
  },
  accountDomain: {
    fontSize: '16px',
    cursor: 'pointer'
  },
  buttonsDiv: {
    width: 118,
    borderRadius: '5px',
    marginLeft: '16px'
  },
  crmButton: {
    backgroundColor: 'white',
    height: 'calc(100% - 2px)',
    width: '100%',
    cursor: 'pointer',
    borderRadius: 5,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    border: '1px solid #D3D3D3'
  },
  hiddenText: {
    display: 'grid',
    textAlign: 'center',
    gridGap: 10,
    padding: 5
  }
}))

function ActionButton (props) {
  const classes = useStyles()
  const snoozed = props.snoozed || false
  const style = props.styled || {}

  let tooltip = props.tooltip || ''
  let buttonColor = 'primary'
  if (snoozed === true) {
    buttonColor = 'default'
    style.backgroundColor = undefined
    tooltip = 'Unsnooze account'
  }

  return (
    <Tooltip title={tooltip}>
      <Button
        variant='contained'
        color={buttonColor}
        classes={{ startIcon: classes.buttonIcon, containedPrimary: classes.button, contained: classes.buttonSize }}
        startIcon={props.icon}
        size='medium'
        onClick={props.onClick}
        style={style}
      >
        {props.text && (
          <span style={{ marginLeft: 3 }}>{props.text}</span>
        )}
      </Button>
    </Tooltip>
  )
}

function HeaderCard (props) {
  const classes = useStyles()
  const editFunction = props.editFunction

  if (props.tooltip) {
    return (
      <div>
        <div className={classes.headerCard}>
          <Tooltip
            className={classes.hiddenText}
            title={props.tooltip}
          >
            <div>
              <div className={classes.cardValue}>
                {props.value}
              </div>
              <div className={classes.cardTitle}>
                {props.title}
              </div>
            </div>
          </Tooltip>
        </div>
        {editFunction && (
          <div style={{ cursor: 'pointer', position: 'absolute', top: '6px', right: '25px' }}>
            <Icon icon='edit' onClick={editFunction} />
          </div>
        )}
      </div>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.headerCard}>
        <div className={classes.cardValue}>
          {props.value}
        </div>
        <div className={classes.cardTitle}>
          {props.title}
          {props.helpInfo && (
            <Tooltip
              className={classes.hiddenText}
              title={props.helpInfo}
            >
              <div>
                <Icon icon='info' specificSize={15} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {editFunction && (
        <div style={{ cursor: 'pointer', position: 'absolute', top: '6px', right: '25px' }}>
          <Icon icon='edit' onClick={editFunction} />
        </div>
      )}
    </div>
  )
}

function SocialMediaLinks (account) {
  account = account.account
  const linkedinLink = account.linkedIn
  const twitterLink = account.twitterURL
  const facebookLink = account.facebookURL

  const handleClick = (link) => window.open(link, '_blank')

  return (
    <div>
      <IconButton color='primary' size='small' disabled={!linkedinLink} onClick={() => handleClick(linkedinLink)}>
        <Icon icon='linkedin' color={linkedinLink ? '#0072B1' : '#C4C4C4'} />
      </IconButton>
      <IconButton color='primary' size='small' disabled={!twitterLink} onClick={() => handleClick(twitterLink)}>
        <Icon icon='xSocial' color={twitterLink ? 'primary' : '#C4C4C4'} />
      </IconButton>
      <IconButton color='primary' size='small' disabled={!facebookLink} onClick={() => handleClick(facebookLink)}>
        <Icon icon='facebook' color={facebookLink ? '#316FF6' : '#C4C4C4'} />
      </IconButton>
    </div>
  )
}

export function LogoDiv (props) {
  const classes = useStyles()
  const account = props.account
  const [imageType, setImageType] = useState(null)

  const domain = account.domain
  const openDomain = () => window.open('https://' + domain, '_blank')
  const openAccount = () => window.open('#/accounts/' + account.id, '_blank')
  const logoSrc = `/api/logo-service/logo/${domain}.png`

  const oppStatus = account?.opportunityStatus || 'No Opp'

  useEffect(() => {
    if (account) {
      // eslint-disable-next-line
      const img = new Image()
      img.onload = function () {
        const width = this.width
        const height = this.height
        if (width > 1.5 * height) {
          setImageType('rectangle')
        } else {
          setImageType('square')
        }
      }
      img.onerror = function () {
        setImageType('error')
      }
      img.src = logoSrc
    }
  }, [account, logoSrc])

  if (!(account && imageType)) {
    return (
      <div style={{ height: 80 }} />
    )
  } else if (imageType === 'rectangle') {
    return (
      <img src={logoSrc} height={80} alt='Logo' />
    )
  }

  const initials = getInitials(account.name, 'A')

  return (
    <div className={classes.accountTitle}>

      <Avatar
        style={{ width: 80, height: 80, cursor: 'pointer' }}
        src={logoSrc}
        variant='rounded'
        onClick={openAccount}
      >
        {initials}
      </Avatar>
      <div className={classes.accountDetails}>
        <div className={classes.accountNameContainer}>
          <div className={classes.accountName} onClick={openAccount}>
            {props.account.name}
          </div>
          {account.companyIsICP && (
            <div style={{ height: '100%', marginRight: '10px' }}>
              <Tooltip title='This company matches your Ideal Customer Profile (ICP)' specificSize={30}>
                <div>
                  <Icon icon='icp' />
                </div>
              </Tooltip>
            </div>
          )}
          <div style={{ maxWidth: '100px' }}>
            <OppStatusText status={oppStatus} />
          </div>
        </div>
        <div className={classes.accountDomain} onClick={openDomain}>
          {props.account.domain}
        </div>
        <SocialMediaLinks account={props.account} />
      </div>
    </div>
  )
}

function ActionButtons (props) {
  const classes = useStyles()
  const account = props.account
  const handleSnooze = props.snoozeAccount
  const addAccount = props.addAccount
  const addAlert = props.addAlert

  if (!account) {
    return (
      <div className={classes.buttonsDiv} />
    )
  }

  if (account.sourceIntegrationName) {
    const action = () => window.open(account.crmLink, '_blank')
    return (
      <>
        <div className={classes.buttonsDiv}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ height: '40px', width: '108px', marginBottom: '4px' }}>
              <div className={classes.crmButton} onClick={action}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ paddingTop: 10, paddingBottom: 10, paddingRight: 5 }}>
                    {account.sourceIntegrationName === 'salesforce' &&
                      <img className={classes.imageIcon} src={SalesForceCloud} alt='Salesforce cloud' height={16} />}
                    {account.sourceIntegrationName === 'hubspot' &&
                      <img className={classes.imageIcon} src={HubspotLogo} alt='Hubspot' height={16} />}
                  </div>
                  <div style={{ textAlign: 'right', marginTop: 5 }}>
                    VIEW
                  </div>
                </div>
              </div>
            </div>
            <ActionButton
              icon={<NotificationsIcon />}
              onClick={addAlert}
              styled={{ marginBottom: 5, height: '36px', width: '108px', color: 'black', backgroundColor: 'white', border: '1px solid #D3D3D3' }}
              text='Alert'
              tooltip='Create alert for account'
            />
          </div>
        </div>
      </>
    )
  }
  const showAdd = !account || !account.domain || !account.sourceIntegrationName

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div className={classes.buttonsDiv}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {account.id ? (
            <>
              <ActionButton
                icon={<SnoozeIcon />}
                onClick={handleSnooze}
                snoozed={props.snoozed}
                styled={{ marginRight: 5, color: 'black', backgroundColor: 'white', border: '1px solid #D3D3D3' }}
                tooltip='Snooze account'
              />
              {showAdd && (
                <div>
                  <ActionButton
                    icon={<AddCircleRoundedIcon />}
                    onClick={addAccount}
                    tooltip='Add account'
                    styled={{ marginRight: 5, color: 'black', backgroundColor: 'white', border: '1px solid #D3D3D3' }}
                  />
                </div>
              )}
              <ActionButton
                icon={<NotificationsIcon />}
                onClick={addAlert}
                text='Alert'
                styled={{ marginTop: 5, width: '112px', color: 'black', backgroundColor: 'white', border: '1px solid #D3D3D3' }}
                tooltip='Create alert for account'
              />
            </>
          ) : (
            <ActionButton
              icon={<NotificationsIcon />}
              onClick={addAlert}
              text='Alert'
              styled={{ marginRight: 5, width: '108px' }}
              tooltip='Create alert for account'
            />

          )}
        </div>
      </div>
    </div>
  )
}

export function AccountOwner ({ accountOwner }) {
  const name = accountOwner.attributes.name
  const nameIsTooLong = name.length > 15
  const displayName = nameIsTooLong ? abbreviateName(accountOwner.attributes.name) : name
  const accountOwnerDiv = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <UserAvatar user={accountOwner} size='xxs' />
      <Typography style={{ marginLeft: 10 }}>{displayName}</Typography>
    </div>
  )
  if (nameIsTooLong) {
    return (
      <Tooltip title={name}>
        {accountOwnerDiv}
      </Tooltip>
    )
  }
  return accountOwnerDiv
}

function AccountHeader (props) {
  const classes = useStyles()
  const account = props.account
  const accountOwner = props.accountOwner
  const domain = account.domain
  const { width, ref } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 0 })
  const [editOwnerModal, setEditOwnerModal] = useState(false)
  const [editTypeModal, setEditTypeModal] = useState(false)
  const [agent, setAgent] = useState(null)
  const [type, setType] = useState(null)

  let status = null
  if (props.online) {
    status = 'available'
  } else if (!props.online) {
    status = 'unavailable'
  }

  const handleSaveOwner = () => {
    setEditOwnerModal(false)
    Account.saveManualAttributes({ manualAccountID: account.id, manualOwnerID: agent?.id })
  }

  const handleSaveType = () => {
    setEditTypeModal(false)
    Account.saveManualAttributes({ manualAccountID: account.id, manualType: type?.id })
  }

  return (
    <div className={classes.header} ref={ref}>
      <LogoDiv
        account={account}
        status={status}
      />
      <div className={classes.cards}>
        <div style={{ display: width < 1200 ? 'none' : 'flex' }}>
          {domain &&
            <HeaderCard
              value={<SignalsScoreGear score={account.engagementScore} placement='bottom' domain={domain} />}
              title='SIGNALS SCORE'
              helpInfo='Hover over the score to see the reasons for the score.'
            />}
        </div>
        {accountOwner &&
          <div style={{ display: width < 800 ? 'none' : 'flex' }}>
            <HeaderCard
              value={<AccountOwner accountOwner={accountOwner} />}
              title='ACCOUNT OWNER'
              editFunction={() => setEditOwnerModal(true)}
            />
          </div>}
        <div>
          <HeaderCard
            value={account.accountType || 'Unknown'}
            title='ACCOUNT TYPE'
            editFunction={() => setEditTypeModal(true)}
          />
        </div>
        {/* <div style={{ display: width < 1000 ? 'none' : 'flex' }}>
          <HeaderCard
            value={oppStatus.toUpperCase()}
            title='OPPORTUNITY'
          />
        </div>
        <div style={{ display: width < 1400 ? 'none' : 'flex' }}>
          <HeaderCard
            value={numConversations}
            title='CONVERSATIONS'
          />
        </div> */}
        <ActionButtons
          account={props.account}
          editAccount={props.editAccount}
          deleteAccount={props.deleteAccount}
          snoozeAccount={props.snoozeAccount}
          addAccount={props.addAccount}
          addAlert={props.addAlert}
          snoozed={props.snoozed}
        />
      </div>
      {editOwnerModal && (
        <Modal
          title='Edit Account Owner'
          onHide={() => setEditOwnerModal(false)}
          handleSave={() => handleSaveOwner()}
          open={editOwnerModal}
          size='sm'
        >
          <ModalSection
            title='Agents'
            subtitle='Select an agent to be the account owner'
          >
            <Picker
              selection={accountOwner}
              setSelection={(data) => setAgent(data)}
              objectType='users'
            />
          </ModalSection>
        </Modal>
      )}
      {editTypeModal && (
        <Modal
          title='Edit Account Type'
          onHide={() => setEditTypeModal(false)}
          handleSave={() => handleSaveType()}
          open={editTypeModal}
          size='sm'
        >
          <ModalSection
            title='Account Type'
            subtitle='Select an account type for this account'
          >
            <Picker
              selection={type?.name || ''}
              setSelection={(data) => setType(data)}
              objectType='accountTypes'
            />
          </ModalSection>
        </Modal>
      )}
    </div>
  )
}

export default AccountHeader
