import { TextBox } from 'library/materialUI'
import { SelectorProps } from './ValueSelector'

export function TextSelector (props: SelectorProps) {
  const { state, setState, handleComplete, option } = props
  const onEnter = () => {
    if (state.value) {
      handleComplete(state)
    }
  }
  return (
    <TextBox
      type={option.type === 'numeric' ? 'number' : undefined}
      value={state.value}
      onChange={(value: number | string) => setState({ ...state, value })}
      autoFocus
      onEnter={onEnter}
    />
  )
}
