import { useState, useEffect, useCallback } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import dateFormat from 'dateformat'
import InformationIcon from 'img/InformationIcon.svg'
import { getUsers } from 'api/users'
import { getAgentReport } from 'api/reports'
import { convertDateRange } from 'cf-components/FilterBar/FilterBar'
import AppPage from 'cf-components/AppPage'
import Table from 'cf-components/Table'
import { ListItemCard } from 'cf-components/CardList'
import { ContactInfoAvatar } from 'cf-components/Avatars'
import { downloadCSV } from 'components/downloadCSV'
import { getSessionDateRange } from 'cf-components/FilterBar/CustomDateRanges'

const cardGridColumns = '3fr 0.8fr 0.8fr 0.8fr 1fr 1fr 1fr 1fr 1fr'

const useStyles = makeStyles(theme => ({
  floatingButton: {
    position: 'absolute',
    padding: 0,
    minWidth: 0,
    top: 40,
    right: 40
  },
  headerClass: {
    display: 'grid',
    gridTemplateColumns: cardGridColumns,
    paddingLeft: 20,
    paddingBottom: 12,
    color: theme.palette.text.primary,
    '& > div': {
      fontSize: '0.75rem',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'flex-end'
    }
  },
  agentCard: {
    padding: '12px 10px',
    display: 'grid',
    gridTemplateColumns: cardGridColumns
  },
  data: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'default',
    borderRight: '2px solid #cccccc',
    '&.no-border': { borderRight: 'unset' }
  }
}))

function convertTime (s) {
  if (s === null) {
    return '---'
  }
  s = Math.round(s)
  if (s < 60) {
    return s + ' sec'
  }
  s = (s / 60).toFixed(1)
  if (s < 60) {
    return s + ' min'
  }
  s = Math.round(s / 60)
  if (s < 24) {
    return s + ' hr'
  } else {
    return '> 24 hr'
  }
}

const sortValues = [
  { label: 'AGENT NAME', id: 'agentName', header: true },
  { label: 'CHATS', id: 'conversations', header: true },
  { label: 'EMAILS', id: 'emailsCaptured', header: true },
  { label: 'MEETINGS', id: 'meetingsBooked', header: true },
  { label: 'BOOK RATE', id: 'sortAllBookRate', header: true },
  { label: 'RESPONSE TIME', id: 'sortResponseTime', header: true },
  { label: 'RESPONSE RATE', id: 'sortResponseRate', header: true },
  { label: 'CHAT LENGTH', id: 'sortConversationLength', header: true },
  { label: 'HRS AVAIL', id: 'timeAvailable', header: true }
]

const AgentReport = props => {
  const classes = useStyles()
  const [users, setUsers] = useState(null)
  const [teamFilter, setTeamFilter] = useState([])
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }
  const [dateRange, setDateRange] = useState(startingDate)
  const [reportResponse, setReportResponse] = useState(null)
  const convertedRange = convertDateRange(dateRange)
  const start = convertedRange?.start_date
  const end = convertedRange?.end_date

  const loadReport = useCallback(() => {
    getAgentReport({ start, end })
      .then(response => {
        const flat = response.data.filter(i => (Object.keys(users).map(id => parseInt(id))).includes(i.id)).map(i => {
          const data = i.attributes
          const convos = data.conversations || 0
          const rate = data.response_rate ? Math.round(data.response_rate * 100, 2) + '%' : '---'
          const sortAllBookRate = data.all_calendars_dropped ? Math.round((data.all_meetings_booked / data.all_calendars_dropped) * 100, 2) : 0
          const allBookRate = data.all_calendars_dropped ? sortAllBookRate + '%' : '---'
          return {
            id: i.id,
            user: users[i.id],
            agentName: users[i.id].attributes.name || '',
            agentEmail: users[i.id].attributes.email,
            searchField: (users[i.id].attributes.name || '') + ' ' + users[i.id].attributes.email,
            teams: data.teams,
            conversations: convos,
            emailsCaptured: data.emails_captured,
            calendarsDropped: data.calendars_dropped,
            meetingsBooked: data.meetings_booked,
            sortResponseRate: data.response_rate,
            responseRate: rate,
            sortResponseTime: data.avg_response_time,
            responseTime: convertTime(data.avg_response_time),
            sortConversationLength: data.avg_conversation_length,
            conversationLength: convertTime(data.avg_conversation_length),
            timeAvailable: Math.round(data.minutes_available / 60),
            allCalendarsDropped: data.all_calendars_dropped,
            allMeetingsBooked: data.all_meetings_booked,
            allBookRate,
            sortAllBookRate
          }
        })
        setReportResponse(flat)
      })
  }, [end, start, users])

  useEffect(() => {
    getUsers()
      .then(response => {
        let userDict = {}
        response.data.map(
          user => (userDict = { ...userDict, [user.id]: user })
        )
        setUsers(userDict)
      })
  }, [])

  useEffect(() => {
    let mounted = true
    if (start && end && users && mounted) {
      loadReport()
    }

    return () => { mounted = false }
  }, [users, loadReport, start, end])

  let rows = null
  if (reportResponse) {
    rows = reportResponse.filter(row => {
      if (teamFilter.length) {
        const teamIDs = teamFilter.map(team => team.id)
        for (const id of teamIDs) {
          if (row.teams.includes(id)) {
            return true
          }
        }
      } else {
        return true
      }
      return false
    })
  }

  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false },
      { value: 'Team', label: 'Filter by Team', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange,
    teamFilter: teamFilter,
    setTeamFilter: setTeamFilter
  }

  function exportAgentReport () {
    const date = new Date().toString()
    const filename = 'Signals_Agent_Performance' + dateFormat(date, 'isoDate') + '.csv'
    const headers = ['Agent Name', 'Conversations', 'Emails Captured', 'Calendars Shared', 'Meetings Booked', 'All Calendars Dropped', 'All Meetings Booked', 'All Book Rate', 'Avg. Response Time', 'Response Rate', 'Avg. Chat Length', 'Hours Available']

    let csvContent = headers.join(',') + '\n'
    csvContent += reportResponse
      .filter(row => teamFilter.length
        ? teamFilter.some(team => row.teams.includes(team.id)) : true)
      .map(row => [
        row.agentName, row.conversations, row.emailsCaptured, row.calendarsDropped,
        row.meetingsBooked, row.allCalendarsDropped, row.allMeetingsBooked, row.allBookRate,
        row.responseTime, row.responseRate, row.conversationLength, row.timeAvailable
      ]).map(row => row.join(',')).join('\n')
    downloadCSV(filename, csvContent)
  }

  document.title = 'Agent Performance | Signals'

  return (
    <AppPage
      title='Agent Performance'
      padding={0}
    >
      <Button
        className={classes.floatingButton}
        onClick={() => { window.open('https://help.getsignals.ai/article/4hb9h4ejn6-agent-performance-report', '_blank') }}
      >
        <Tooltip title='Click to learn how these numbers are calculated and the best way to use them.'>
          <img src={InformationIcon} alt='Information Icon' />
        </Tooltip>
      </Button>
      <div>
        <Table
          rows={rows}
          card={AgentReportCard}
          header
          sortValues={sortValues}
          filterOptions={filterOptions}
          handleExport={exportAgentReport}
          headerClass={classes.headerClass}
          startingSortColumn='conversations'
          sortAscending={false}
        />
      </div>
    </AppPage>
  )
}

function AgentReportCard (props) {
  const classes = useStyles()
  const row = props.row
  const conversationLength = row.conversationLength === '0 sec' ? '---' : row.conversationLength

  return (
    <ListItemCard>
      <div className={classes.agentCard}>
        <ContactInfoAvatar user={row.user} />
        <div className={classes.data}>{row.conversations}</div>
        <div className={classes.data}>{row.emailsCaptured}</div>
        <div className={classes.data}>{row.meetingsBooked}</div>
        {row.allCalendarsDropped !== 0 ? (
          <DataTooltip
            data={[
              { title: 'Calendar Drops:', value: row.allCalendarsDropped },
              { title: 'Scheduled Meetings:', value: row.allMeetingsBooked }
            ]}
          >
            <div className={classes.data}>{row.allBookRate}</div>
          </DataTooltip>
        ) : (
          <div className={classes.data}>{row.allBookRate}</div>
        )}
        <div className={classes.data}>{row.responseTime}</div>
        <div className={classes.data}>{row.responseRate}</div>
        <div className={classes.data}>{conversationLength}</div>
        <div className={classes.data + ' no-border'}>{row.timeAvailable}</div>
      </div>
    </ListItemCard>
  )
}

const DataTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.text.primary,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 42%)',
    fontSize: '0.7rem',
    '& .custom-data-tooltip-ctn': {
      margin: '3px 0'
    },
    '& .custom-data-tooltip-value': {
      color: theme.palette.primary.main
    }
  }
}))(
  /**
   * @param {{
   * data: { title: string; value?: string | number; }[]
   * } & Omit<import('@material-ui/core/Tooltip').TooltipProps, 'title'>} props
   */
  ({ data, ...props }) => (
    <Tooltip
      {...props}
      title={
        <>
          {data.map(({ title, value }, i) => (
            <div key={i} className='custom-data-tooltip-ctn'>
              {title}
              {value !== undefined && (
                <span className='custom-data-tooltip-value'> {value}</span>
              )}
            </div>
          ))}
        </>
      }
    />
  )
)

export default AgentReport
