import AppPage from 'cf-components/AppPage'
import { makeStyles } from '@material-ui/core/styles'

import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel';

const useStyles = makeStyles(theme => ({
  tabPlaceholder: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 20
  }
}))

const BugReportsPage = (): JSX.Element => {
  const classes = useStyles()
  return (
    <AppPage
      title='Bug Reports'
      padding={0}
    >
      <div id='bug-reports' hx-get='/api/core/web/bugs' hx-trigger='revealed'>
        <div>
          <div style={{ display: 'flex', justifyContent: 'right', padding: 10 }}>
            <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
            <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
          </div>
          <SkeletonTableLoader
            rowCount={7}
            types={['label', 'label', 'label', 'squareAvatar&Label', 'avatar']}
            columns='2fr 1fr 1fr 1fr 50px'
            justifyCenter
          />
        </div>
      </div>
    </AppPage>
  )
}

export default BugReportsPage
