import { useState, useEffect } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import 'jsplumb'
import { jsPlumbUtil } from 'jsplumbtoolkit'
import useNodeStyles from './NodeStyles'
import Divider from '@material-ui/core/Divider'
import CalendarIcon from '@material-ui/icons/DateRange'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { getMeetingTypes } from 'api/meetings'
import OptionPort from '../OptionPort'
import { FormControlLabel, Checkbox, Button } from '@material-ui/core'
import { getEmails } from 'api/emails'
import AddIcon from '@material-ui/icons/Add'
import { RichTextEditor } from 'library/richText/RichTextEditor'
import { QueryClient, QueryClientProvider } from 'react-query'
import Picker from 'cf-components/Pickers'
import { EVENT_TYPE, Emitter } from 'emitter'
import { RoutingSelector } from 'library/routing/RoutingCriteria'
import { Modal, ModalSection } from 'library/Modal'
import theme from 'theme'

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: '#999',
    fontFamily: 'Poppins, sans serif',
    fontSize: '12px'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 8
  },
  input: {
    padding: 6,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    borderRadius: 30,
    paddingLeft: 10,
    width: '300px',
    fontFamily: 'Poppins, sans serif',
    fontSize: '14px',
    color: '#5C5C5C'
  },
  questionText: {
    textAlign: 'left',
    font: 'normal normal normal 11px/15px Poppins',
    letterSpacing: 0,
    color: '#989898',
    opacity: 1,
    marginBottom: 10
  },
  contentClass: {
    overflow: 'auto',
    padding: 20,
    height: 360
  },
  sectionHeader: {
    fontSize: '15px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

// const routeValues = [
//   { value: '', label: 'Select an option below', disabled: true },
//   { value: 'single_agent', label: 'Route to single agent' },
//   { value: 'group', label: 'Route to group of agents' },
//   { value: 'team', label: 'Route to a team' },
//   { value: 'account_owner', label: 'Route by account owner' }
// ]

function CalendarModal (props) {
  const classes = useStyles(props)

  // These three variables are deprecated, do not use them
  const selectedTeamId = props.selectedTeamId || ''
  const selectedUserIds = props.selectedUserIds || []
  const method = props.method

  const [textMessageReminders, setTextMessageReminders] = useState(props.textMessageReminders)
  const [emailID, setEmailID] = useState(props.emailID || '')
  const [emails, setEmails] = useState([])
  const [chatServiceUrl, setChatServiceUrl] = useState(null)
  const [followUpEmail, setFollowUpEmail] = useState(props.followUpEmail)
  const [body, setBody] = useState(props.body)
  const [meetingType, setMeetingType] = useState(props.meetingType)
  const showTextMessageToggle = props.showTextMessageToggle
  const [routeOptions, setRouteOptions] = useState(props.routeOptions || [{ method: 'single_agent', type: 'round_robin', objectIDs: [] }])
  const [routeByAccountOwnerPermission, setRouteByAccountOwnerPermission] = useState(false)
  const completeState = props?.completeState

  function MeetingTypeSelect (props) {
    return (
      <div className={classes.select}>
        <Picker
          selection={props.value}
          setSelection={props.handleChange}
          objectType='meetingTypes'
        />
      </div>
    )
  }

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setChatServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
          if (response.data.attributes.perms.tenant_route_by_account_owner) {
            setRouteByAccountOwnerPermission(true)
          }
        }
      })
  }, [])

  useEffect(() => {
    setBody(props.body)
  }, [props.body])

  useEffect(() => {
    if (chatServiceUrl) {
      getEmails({ chatServiceUrl }).then(response => {
        setEmails(response.data.map((email) => ({ name: email.attributes.name, value: email.id })))
      })
    }
  }, [chatServiceUrl])

  function editText (value, delta, source, editor) {
    setBody(value)
  }

  function cancelChanges () {
    props.onHide()
    setRouteOptions(props.routeOptions || [{ method: 'single_agent', type: 'round_robin', objectIDs: [] }])
    setFollowUpEmail(props.followUpEmail)
    setEmailID(props.emailID || '')
  }

  const save = () => {
    props.save({
      user_ids: selectedUserIds,
      method,
      team_id: selectedTeamId,
      body,
      meetingType,
      textMessageReminders: showTextMessageToggle && textMessageReminders,
      followUpEmail,
      emailID,
      routeOptions
    })
  }

  if (!props.open) {
    return <></>
  }

  Emitter.on(EVENT_TYPE.BROWSER_BACK, cancelChanges)

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={props.open}
        onHide={cancelChanges}
        handleSave={save}
        title='Calendar Invite'
        contentClass={classes.contentClass}
        helplink='home/bots/nodes/Calendar'
        helplinkLabel='Learn more about this skill'
        saveIcon='save'
      >
        {!props.hideBody &&
          <div style={{ marginBottom: 20 }} className='customQuill'>
            <ModalSection title='Pre-Drop Message' titleType='h6'>
              <RichTextEditor
                message={body}
                onChange={editText}
                placeholder='What should the bot say to the visitor when it sends the calendar?'
                lines={2}
                showMergeFields
              />
            </ModalSection>
          </div>}
        <ModalSection title='Meeting Type' subtitle='Which type of meeting should be scheduled?' titleType='h6' borderColor={completeState?.meetingType ? undefined : '#EA422A'}>
          <div style={{ marginBottom: 15 }}>
            <MeetingTypeSelect
              meetingTypes={props.meetingTypes}
              value={meetingType}
              handleChange={setMeetingType}
            />
          </div>
        </ModalSection>
        <RoutingSelector
          subtitle='Which agent(s) should this meeting be scheduled for? Only agents with calendars will appear in the list.'
          routeOptions={routeOptions}
          handleChange={setRouteOptions}
          permissions={{ routeByAccountOwner: routeByAccountOwnerPermission }}
          borderColor={completeState?.meetingRouting ? undefined : '#EA422A'}
        />
        {showTextMessageToggle
          ? (
            <ModalSection title='Follow-up Settings' titleType='h6' borderColor={completeState?.meetingEmail ? undefined : '#EA422A'}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={textMessageReminders}
                    onChange={(e) => setTextMessageReminders(e.target.checked)}
                    name='autoToggle'
                    color='primary'
                  />
                }
                style={{ marginTop: 10, fontSize: 12 }}
                label='Prompt visitor for text message reminders.'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!followUpEmail}
                    onChange={(e) => setFollowUpEmail(e.target.checked)}
                    name='autoToggle'
                    color='primary'
                  />
                }
                style={{ marginTop: -5, fontSize: 12 }}
                label='If the visitor doesn’t book a meeting, send a follow-up email.'
              />
              {followUpEmail
                ? (
                  <div style={{ marginBottom: 10 }}>
                    <div className={classes.questionText}>
                      Select an email. If the visitor doesn’t book a meeting within 15 minutes of seeing the calendar invite, the selected email will be sent.
                    </div>
                    <Select
                      fullWidth
                      variant='outlined'
                      value={emailID}
                      onChange={(e) => setEmailID(e.target.value)}
                      displayEmpty
                      margin='dense'
                    >
                      <MenuItem value='' style={{ height: 40 }} disabled>Select an email</MenuItem>
                      {emails.map((email, index) => (
                        <MenuItem value={email.value} key={index}>{email.name}</MenuItem>
                      ))}
                    </Select>
                    <Button
                      style={{ fontSize: 12, color: 'gray', marginTop: 20, borderRadius: 50 }}
                      variant='outlined'
                      size='small'
                      onClick={() => window.open('/#/emails/new')}
                      endIcon={<AddIcon color='primary' style={{ fill: '#975ce6' }} />}
                    >
                      Create a new email
                    </Button>
                  </div>
                ) : <></>}
            </ModalSection>
          )
          : <></>}
      </Modal>
    </ThemeProvider>
  )
}

const queryClient = new QueryClient()

const CalendarNode = props => {
  const base = props.base
  const classes = useNodeStyles(props)
  const [chatServiceUrl, setChatServiceUrl] = useState(null)
  const [meetingTypes, setMeetingTypes] = useState([])
  const [showTextMessageToggle, setShowTextMessageToggle] = useState(false)

  const assignKey = data => {
    data._reactKey = data._reactKey || jsPlumbUtil.uuid()
    return data._reactKey
  }

  const saveChanges = ({ user_ids, method, team_id, body, meetingType, textMessageReminders, followUpEmail, emailID, routeOptions }) => {
    if (!base.toolkit) {
      return
    }
    const node = base.getNode()
    const newData = {
      ...base.state,
      user_ids: user_ids,
      method: method,
      team_id: team_id,
      body: body,
      meeting_type: meetingType,
      text_message_reminders: textMessageReminders,
      follow_up_email: followUpEmail,
      email_id: emailID,
      routeOptions: routeOptions
    }
    base.toolkit.updateNode(node, newData)
    props.setModalOpen(false)
  }

  // Reshape old flow body
  if (base.state && base.state.method && !base.state.routeOptions) {
    if (base.state.method === 'round_robin_group') {
      base.state.routeOptions = [{ method: 'group', objectIDs: base.state.user_ids, type: 'round_robin' }]
    } else if (base.state.method === 'single_agent') {
      base.state.routeOptions = [{ method: 'single_agent', objectIDs: base.state.user_ids, type: '' }]
    } else if (base.state.method === 'round_robin_team') {
      base.state.routeOptions = [{ method: 'team', objectIDs: [base.state.team_id], type: 'round_robin' }]
    }

    // base.state.method = undefined
    // base.state.team_id = undefined
    // base.state.user_ids = undefined
  }
  // End of reshape

  let body
  const method = base.state.routeOptions.length > 0 ? base.state.routeOptions[0].method : undefined
  switch (method) {
    case 'team':
      body = 'Route to a team'
      break
    case 'group':
      body = 'Route to a group'
      break
    case 'account_owner':
      body = 'Route to an account owner'
      break
    default:
      body = 'Route to a single agent'
  }

  useEffect(() => {
    fetch('/api/auth/me', {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.api+json' }
    })
      .then(response => response.json())
      .then(response => {
        if (response.data && response.data.id) {
          setChatServiceUrl(response.data.links.chat_service || 'localhost:8002/api/chat-service/a')
          if (response.data.attributes.perms.tenant_text_message_meeting_reminders) {
            setShowTextMessageToggle(true)
          }
        }
      })
  }, [])

  useEffect(() => {
    if (chatServiceUrl) {
      getMeetingTypes({ chatServiceUrl }).then(response => {
        setMeetingTypes(response.data)
      })
    }
  }, [chatServiceUrl])

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <div
          className={`${classes.dragger} ${classes.agentConnection}`}
        >
          <div className='node-header'>
            <div className={classes.iconDiv}><CalendarIcon /></div>
            <div className={classes.cardTitle}>Calendar Invite</div>
          </div>
          <div className='node-body'>
            <div>
              {body}
            </div>
            <Divider style={{ marginTop: 5 }} />
            <div>
              {base.state.ports.map((c, index) => {
                return (
                  <OptionPort
                    index={index}
                    data={c}
                    key={assignKey(c)}
                    parent={base}
                    kind='agentConnection'
                  />
                )
              })}
            </div>
          </div>
          <jtk-target
            scope='default'
          />
        </div>
      </div>
      <CalendarModal
        open={props.modalOpen}
        onHide={() => props.setModalOpen(false)}
        save={saveChanges}
        selectedUserIds={base.state.user_ids}
        method={base.state.method}
        selectedTeamId={base.state.team_id}
        routeType={base.state.route_type}
        body={base.state.body}
        meetingType={base.state.meeting_type}
        meetingTypes={meetingTypes}
        showTextMessageToggle={showTextMessageToggle}
        textMessageReminders={base.state.text_message_reminders}
        followUpEmail={base.state.follow_up_email}
        emailID={base.state.email_id}
        routeOptions={base.state.routeOptions}
      />
    </QueryClientProvider>
  )
}

export { CalendarNode, CalendarModal }
