/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { debounceDelay } from 'library/constants'
import dateFormat from 'dateformat'
import { useQuery } from 'react-query'
import { getTenants, deleteTenant, getTenantRecentConversations, getTenantIdsByUserSearch } from 'api/admin_only/tenants'
import theme from 'theme'
import { ListItemCard } from 'cf-components/CardList'
import ActionsMenu from 'cf-components/ActionsMenu'
import AppPage from 'cf-components/AppPage'
import DeleteModal from 'components/DeleteModal'
import Table from 'cf-components/Table'

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginBottom: 10,
    fontSize: 14
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 5
  },
  card: {
    padding: 15,
    margin: 20
  },
  header: {
    padding: '5px 10px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '2.4fr 0.5fr 2fr 2fr 2fr 2fr 0.8fr 35px',
    gridGap: 10
  },
  tenantCard: {
    padding: 15,
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '2.4fr 0.5fr 2fr 2fr 2fr 2fr 0.8fr 35px',
    gridGap: 4,
    cursor: 'pointer'
  },
  cardTitle: {
    fontSize: '1rem',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cardTitleCtn: { overflow: 'hidden' },
  subTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipses',
    fontSize: '.8rem',
    color: '#7A7A7A'
  },
  userSearchBarCtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 4,
    marginRight: 30
  },
  imgIcon: { height: 24 },
  inputRoot: { borderRadius: 30 },
  inputMargin: {
    paddingTop: 8,
    paddingBottom: 8
  }
}))

interface TenantRowExtras {
  delete: (id: Tenant['id']) => void
  searchField: string
  recentConversation: string
}

type TenantRow = TenantRowExtras & Tenant['attributes']

const TenantCard = (props: { row: TenantRow }): JSX.Element => {
  const classes = useStyles()
  const row = props.row
  const history = useHistory()
  const { id, delete: rowDelete, name, original_email: email } = row

  const actions = useMemo(() => [
    { name: 'View Users', action: () => history.push('/admin_portal/tenants/' + id + '/users'), icon: EditIcon },
    { name: 'Delete Tenant', action: () => { rowDelete(id) }, icon: DeleteIcon }
  ], [id, history, rowDelete])

  const subtitle = useMemo(() => email === name ? '' : email, [name, email])

  const lastSessionActivity = row.last_session_activity ? dateFormat(row.last_session_activity, 'yyyy-mm-dd') : ''
  const recentConvo = row.recentConversation ? dateFormat(row.recentConversation, 'yyyy-mm-dd') : ''

  return (
    <ListItemCard>
      <div className={classes.tenantCard} onClick={() => history.push('/admin_portal/tenants/' + row.id)}>
        <div className={classes.cardTitleCtn}>
          <h3 className={classes.cardTitle}>
            {name}
          </h3>
          {subtitle && (
            <div className={classes.subTitle}>
              {subtitle}
            </div>
          )}
        </div>
        <div>{id}</div>
        <div>{lastSessionActivity}</div>
        <div>{recentConvo}</div>
        <div>{dateFormat(row.created_timestamp, 'yyyy-mm-dd')}</div>
        <div>{row.product_name}</div>
        <div>{row.total}</div>
        <ActionsMenu
          actions={actions}
        />
      </div>
    </ListItemCard>
  )
}

interface Tenant {
  id: number
  type: string
  attributes: {
    id: number
    name: string
    original_email: string
    created_timestamp: string
    session_length: number
    lock_time: number
    attempts_allowed: number
    session_length_minutes: number
    mfa_requirement: string
    total: number
    customer_organization: null
    customer_first_name: null
    customer_last_name: null
    customer_id: null
    billing_state: null
    product_name: null
    product_id: null
    current_period_ends_timestamp: null
    last_login: string
    last_session_activity: string
  }
}

const AdminTenantsPage = (): JSX.Element => {
  const classes = useStyles()
  const [sortCriteria, setSortCriteria] = useState('cost_in_cents')
  const [sortAscending, setSortAscending] = useState(true)
  const [deleteTenantID, setDeleteTenantID] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, debounceDelay)
  const { data: userSearchMatchingIDs } = useQuery<number[] | undefined>(
    ['tenantUserSearchIDs', `search:${debouncedSearch}`],
    () => !debouncedSearch ? undefined : getTenantIdsByUserSearch({
      search: debouncedSearch
    }).then(res => res.ok ? res.data : undefined),
    { staleTime: 120000, keepPreviousData: true }
  )
  const { data: tenants, refetch: refetchTenants } = useQuery<Tenant[]>(
    ['tenants', 'admin', 'list'],
    () => getTenants().then(res => res.data),
    { staleTime: 120000, keepPreviousData: true }
  )
  const { data: recentConversations } = useQuery<{ [tenantID: number]: string }>(
    ['tenants', 'admin', 'recentConversations'],
    () => getTenantRecentConversations().then(response => Object.assign(
      {},
      ...response.data.map((x: { id: number; attributes: Record<string, any>; }) => ({
        [x.id]: x.attributes.created_timestamp
      }))
    )),
    { staleTime: 120000, keepPreviousData: true }
  )

  const sortOptions = {
    sortCriteria: sortCriteria,
    setSortCriteria: setSortCriteria,
    sortAscending: sortAscending,
    setSortAscending: setSortAscending,
    sortValues: [
      { label: 'Tenant Name', id: 'name', header: 'Tenant Name' },
      { label: 'Id', id: 'id', header: 'Id' },
      { label: 'Last Activity', id: 'last_session_activity', header: 'Last Activity' },
      { label: 'Last Conversation', id: 'last_conversation', header: 'Last Conversation' },
      { label: 'Created Date', id: 'created_timestamp', header: 'Created Date' },
      { label: 'Product Name', id: 'product_name', header: 'Product Name' },
      { label: 'User Count', id: 'total', header: 'User Count' }
    ]
  }

  const beginDelete = (): void => {
    deleteTenant(deleteTenantID).then(_r => {
      refetchTenants()
      setDeleteTenantID(null)
    })
  }

  /**
   * All the Tenant formatted rows
   *
   * The purpose of separating this and the below rows is so
   * we don't have to re-map the tenants on filter changes
   */
  const allRows = useMemo(() => (!tenants || !recentConversations) ? null : tenants.map(i => ({
    ...i.attributes,
    id: i.id,
    delete: setDeleteTenantID,
    searchField: (i.attributes.name + ' ' + i.attributes.original_email + ' ' + i.attributes.product_name).toLowerCase(),
    recentConversation: recentConversations[i.id]
  })), [tenants, recentConversations])

  const rowFilterFn = useMemo<((row: { id: Tenant['id']; searchField: string; }) => boolean) | null>(() => {
    if (!debouncedSearch) return null
    return row => {
      if (row.searchField.includes(debouncedSearch.toLowerCase())) return true
      if (userSearchMatchingIDs) return userSearchMatchingIDs.includes(row.id)
      return false
    }
  }, [debouncedSearch, userSearchMatchingIDs])
  const rows = useMemo(() => (!allRows || !rowFilterFn) ? allRows : allRows.filter(rowFilterFn), [allRows, rowFilterFn])

  return (
    <AppPage
      title='Tenants'
      padding={0}
    >
      <div>
        <div className={classes.userSearchBarCtn}>
          <UserSearchBar
            search={search}
            setSearch={setSearch}
          />
        </div>
        <Table
          rows={rows}
          sortValues={sortOptions.sortValues}
          card={TenantCard}
          header
          headerClass={classes.header}
          hideFilterBar
        />
      </div>
      <DeleteModal
        open={Boolean(deleteTenantID)}
        onHide={() => setDeleteTenantID(null)}
        deleteObject={beginDelete}
        message='Are you sure you want to delete this tenant? If you proceed, any users on this tenant will no longer be able to log in.'
        title='Delete Tenant'
      />
    </AppPage>
  )
}

function UserSearchBar (props: {
  search: string
  setSearch: (value: string) => void
}): JSX.Element {
  const classes = useStyles()
  const [active, setActive] = useState(false)
  const userSearchSVG = useMemo(() => getUserSearchSVG(active), [active])

  return (
    <TextField
      value={props.search}
      onChange={e => props.setSearch(e.target.value)}
      margin='dense'
      variant='outlined'
      type='text'
      placeholder='Search by tenants or users'
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          inputMarginDense: classes.inputMargin
        },
        endAdornment: (
          <InputAdornment position='end'>
            <Tooltip title='User Search'>
              <img
                className={classes.imgIcon}
                src={userSearchSVG}
                alt='User search'
              />
            </Tooltip>
          </InputAdornment>
        )
      }}
    />
  )
}

function getUserSearchSVG (active = false): string {
  const color = active ? theme.palette.primary.main : 'rgba(0,0,0,0.54)'
  const imageTemplate = `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="${color}"><path fill="none" d="M0 0h24v24H0z"/><circle cx="10" cy="8" r="4"/><path d="M10.35 14.01C7.62 13.91 2 15.27 2 18v2h9.54c-2.47-2.76-1.23-5.89-1.19-5.99zM19.43 18.02c.36-.59.57-1.28.57-2.02 0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4c.74 0 1.43-.22 2.02-.57L20.59 22 22 20.59l-2.57-2.57zM16 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/></svg>`
  const base64Data = Buffer.from(unescape(encodeURIComponent(imageTemplate))).toString('base64')
  return 'data:image/svg+xml;base64,' + base64Data
}

export default AdminTenantsPage
