import { useState, useEffect } from 'react'
import { getFeatureFlags } from 'api/feature_flags'
import { makeStyles } from '@material-ui/core/styles'
import { DragDropNodeSource } from './DragDropNodeSource'
import { DragDropSequenceSource } from '../sequences/DragDropSequenceSource'
import { getSequenceTemplates } from 'api/sequences'
import Drawer from '@material-ui/core/Drawer'
import SearchBar from 'components/SearchBar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useDebounce } from 'use-debounce'
import { debounceDelay } from 'library/constants'
// import SalesloftBotBuilderOnboarding from 'cf-components/SalesloftBotBuilderOnboarding'

const useStyles = makeStyles(theme => ({
  drawerContents: {
    padding: 25,
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '98%',
    borderTop: '1px solid rgba(0,0,0,0.2)'
  },
  drawerToggleButton: {
    position: 'fixed',
    bottom: 115,
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    padding: 8,
    paddingLeft: 4,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    boxShadow: '2px 2px 3px #AAA',
    backgroundColor: 'white',
    cursor: 'pointer'
  }
}))

function DrawerToggleButton (props) {
  const classes = useStyles()

  const handleToggle = props.toggleDrawer

  return (
    <div
      className={classes.drawerToggleButton}
      onClick={handleToggle}
    >
      {props.drawerOpen
        ? <ChevronLeftIcon />
        : <ChevronRightIcon />}
    </div>
  )
}

function DragDropDrawer (props) {
  const classes = useStyles(props)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search.toLowerCase(), debounceDelay)
  const [tab, setTab] = useState(0)
  const [templates, setTemplates] = useState([])
  const [flags, setFlags] = useState({})

  useEffect(() => {
    if (props.showSequence) {
      window.refreshSequences = () => getSequenceTemplates()
        .then(response => setTemplates(response.data.map(t => ({ ...t.attributes, id: t.id }))))
      window.refreshSequences()
    }
  }, [props.showSequence])
  useEffect(() => {
    getFeatureFlags().then((flags) => {
      // not using session context because of the challenges w/ jsplumb's React
      setFlags(flags)
    })
  }, [])

  const dataGenerator = (el) => {
    const type = el.getAttribute('data-node-type')
    const flowID = parseInt(el.getAttribute('sequence-flow-id'))
    const sequenceID = parseInt(el.getAttribute('sequence-id'))
    if (type === 'sequence') {
      return {
        type: 'sequence',
        title: el.getAttribute('title'),
        description: el.getAttribute('description'),
        ports: JSON.parse(el.getAttribute('ports')),
        sequenceFlowID: flowID,
        parentSequenceID: sequenceID,
        trackTemplate: true
      }
    }
    return {
      type: el.getAttribute('data-node-type')
    }
  }
  return (
    <>
      <Drawer
        open={props.open}
        onClose={() => { }}
        PaperProps={{ style: { position: 'absolute', left: 2 } }}
        BackdropProps={{ style: { position: 'absolute', left: 2 } }}
        ModalProps={{
          container: document.getElementById('editor'),
          style: { position: 'absolute', left: 2 }
        }}
        variant='persistent'
      >
        <div style={{ width: 300, overflow: 'hidden', zIndex: 0 }}>
          {props.showSequence &&
            <Tabs
              value={tab}
              onChange={(e, val) => setTab(val)}
              variant='fullWidth'
              indicatorColor='primary'
              textColor='primary'
              style={{ width: 300 }}
              classes={{ indicator: classes.indicator }}
            >
              <Tab label='Bot Skills' style={{ minWidth: 150 }} />
              <Tab label='Sequences' style={{ minWidth: 150 }} />
            </Tabs>}
          <div
            className={classes.drawerContents}
            ref={props.dragDropContainerRef}
            style={{ height: props.showSequence ? null : 'calc(100% - 50px)', borderTop: props.showSequence ? null : 'none' }}
          >
            <div style={{ width: 250, marginBottom: 25, display: 'flex', alignItems: 'center' }}>
              <SearchBar search={search} setSearch={setSearch} />
            </div>
            <div
              hidden={tab}
            >
              {props.surface
                ? (
                  <DragDropNodeSource
                    surface={props.surface}
                    selector='.dragger'
                    container={props.dragDropContainerRef.current}
                    dataGenerator={dataGenerator}
                    search={debouncedSearch}
                    flags={flags}
                  />)
                : <></>}
            </div>
            <div
              hidden={!tab}
            >
              {props.surface
                ? (
                  <DragDropSequenceSource
                    templates={templates}
                    surface={props.surface}
                    selector='.dragger'
                    container={props.dragDropContainerRef.current}
                    dataGenerator={dataGenerator}
                    search={debouncedSearch}
                    flags={props.flags}
                  />)
                : <></>}
            </div>
          </div>
        </div>
      </Drawer>
      {/* <SalesloftBotBuilderOnboarding /> */}
    </>
  )
};

export { DragDropDrawer, DrawerToggleButton }
