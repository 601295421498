import { makeStyles } from '@material-ui/core/styles'
import Form from 'classes/forms'
import { Button, ButtonGroup, Divider, Icon, LabeledCheckbox, MenuItem, Select, TextField, Tooltip, Typography } from 'library/materialUI'
import ColorPopOver from 'library/ColorPopOver'
import InformationIcon from 'library/InformationIcon'
import StyleCard from 'library/cards/StyleCard'

const useStyles = makeStyles(theme => ({
  fieldBar: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '700px',
    maxWidth: '700px',
    height: '80vh',
    backgroundColor: '#F9F9F9',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: 10
  },
  fieldCell: {
    width: '244px',
    height: '44px',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '10px',
    paddingLeft: '10px',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '14px',
    cursor: 'pointer'
  },
  groupTitle: {
    justifySelf: 'flex-start',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row'
  },
  iconDiv: {
    marginLeft: '5px',
    cursor: 'pointer'
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '10px 0px'
  },
  cardColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%'
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  tabSelectionDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10
  },
  tabSelection: {
    fontSize: '12px',
    fontFamily: 'poppins',
    cursor: 'pointer',
    width: '138px',
    textAlign: 'center',
    padding: '10px'
  },
  selectionHighlight: {
    height: '2px',
    width: '138px',
    backgroundColor: '#9933FF',
    transition: 'margin 0.2s ease'
  },
  selectionDivider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#C4C4C4',
    marginBottom: '15px',
    marginLeft: 10,
    marginRight: 10
  },
  fieldIconDiv: {
    marginLeft: '10px',
    marginRight: '10px'
  },
  accordionStyle: {
    backgroundColor: '#F9F9F9',
    boxShadow: 'none',
    border: 'none',
    '&.Mui-disabled': {
      backgroundColor: '#F9F9F9'
    }
  },
  accordianSummaryStyle: {
    backgroundColor: '#F9F9F9',
    boxShadow: 'none',
    border: 'none',
    padding: '0px 10px'
  },
  buttonSelected: {
    backgroundColor: '#F1F1F1',
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  },
  button: {
    '&.Mui-disabled': {
      pointerEvents: 'auto'
    }
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '10px 0px',
    backgroundColor: 'transparent'
  },
  sectionTitle: {
    marginBottom: 10,
    marginTop: 10
  }
}))

function SectionTitle ({ title }: { title: string }): JSX.Element {
  const classes = useStyles()

  return (
    <div className={classes.sectionTitle}>
      <Typography variant='h2'>{title}</Typography>
      <Divider style={{ marginTop: 5, backgroundColor: '#C4C4C4' }} />
    </div>
  )
}

interface ButtonAlignmentSelectorProps {
  alignment: string
  form: Form
  dispatch: any
  setCanSave: (value: boolean) => void
}

function ButtonAlignmentSelector ({ alignment, form, dispatch, setCanSave }: ButtonAlignmentSelectorProps): JSX.Element {
  const classes = useStyles()
  return (
    <ButtonGroup style={{ width: '100%', height: '40px' }}>
      <Button
        style={{ width: '25%', padding: 0 }}
        className={alignment === 'left' ? classes.buttonSelected : classes.button}
        onClick={() => {
          dispatch({ type: 'style', value: 'left', field: 'button', subField: 'align' })
          setCanSave(true)
        }}
      >
        <Tooltip title='Left' placement='bottom'>
          <div className={classes.buttonStyle}>
            <Icon icon='horizontalLeft' color={alignment === 'left' ? '#000000' : '#929292'} />
          </div>
        </Tooltip>
      </Button>
      <Button
        style={{ width: '25%', padding: 0 }}
        className={alignment === 'center' ? classes.buttonSelected : classes.button}
        onClick={() => {
          dispatch({ type: 'style', value: 'center', field: 'button', subField: 'align' })
          setCanSave(true)
        }}
      >
        <Tooltip title='Center' placement='bottom'>
          <div className={classes.buttonStyle}>
            <Icon icon='horizontalCenter' color={alignment === 'center' ? '#000000' : '#929292'} />
          </div>
        </Tooltip>
      </Button>
      <Button
        style={{ width: '25%', padding: 0 }}
        className={alignment === 'right' ? classes.buttonSelected : classes.button}
        onClick={() => {
          dispatch({ type: 'style', value: 'right', field: 'button', subField: 'align' })
          setCanSave(true)
        }}
      >
        <Tooltip title='Right' placement='bottom'>
          <div className={classes.buttonStyle}>
            <Icon icon='horizontalRight' color={alignment === 'right' ? '#000000' : '#929292'} />
          </div>
        </Tooltip>
      </Button>
      <Button
        style={{ width: '25%', padding: 0 }}
        disabled={!Form.hasOneField(form)}
        className={alignment === 'inline' ? classes.buttonSelected : classes.button}
        onClick={() => {
          dispatch({ type: 'style', value: 'inline', field: 'button', subField: 'align' })
          setCanSave(true)
        }}
      >
        <Tooltip title={!Form.hasOneField(form) ? 'This option is only available if your form has one field' : 'Inline with field'} placement='bottom'>
          <div className={classes.buttonStyle}>
            <Icon icon='verticalCenter' color={alignment === 'inline' ? '#000000' : '#929292'} />
          </div>
        </Tooltip>
      </Button>
    </ButtonGroup>
  )
}

interface BorderSelectorProps {
  borderRadius: string
  dispatch: any
  setCanSave: (value: boolean) => void
  field: string
  subField: string
  disabled?: boolean
}

function BorderSelector ({ borderRadius, dispatch, setCanSave, field, subField, disabled }: BorderSelectorProps): JSX.Element {
  const classes = useStyles()

  return (
    <div style={{ display: 'flex', borderRadius: '5px', width: '100%' }}>
      <ButtonGroup style={{ width: '100%', height: '40px' }}>
        <Button
          style={{ width: '33.33%' }}
          disabled={disabled}
          onClick={() => {
            dispatch({ type: 'style', value: '0px', field, subField })
            setCanSave(true)
          }}
          className={borderRadius === '0px' ? classes.buttonSelected : undefined}
        >
          <Tooltip title='Sharp corners' placement='bottom'>
            <div className={classes.buttonStyle}>
              <div
                style={{
                  height: 15,
                  width: 15,
                  border: '1px solid',
                  borderColor: borderRadius === '0px' ? '#000000' : '#929292',
                  borderRight: 'none',
                  backgroundColor: '#E2E2E2'
                }}
              />
            </div>
          </Tooltip>
        </Button>
        <Button
          style={{ width: '33.33%' }}
          disabled={disabled}
          onClick={() => {
            dispatch({ type: 'style', value: '5px', field, subField })
            setCanSave(true)
          }}
          className={borderRadius === '5px' ? classes.buttonSelected : undefined}
        >
          <Tooltip title='Rounded corners' placement='bottom'>
            <div className={classes.buttonStyle}>
              <div
                style={{
                  height: 15,
                  width: 15,
                  border: '1px solid',
                  borderColor: borderRadius === '5px' ? '#000000' : '#929292',
                  borderRight: 'none',
                  backgroundColor: '#E2E2E2',
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5
                }}
              />
            </div>
          </Tooltip>
        </Button>
        <Button
          style={{ width: '33.33%' }}
          disabled={disabled}
          onClick={() => {
            dispatch({ type: 'style', value: '50px', field, subField })
            setCanSave(true)
          }}
          className={borderRadius === '50px' ? classes.buttonSelected : undefined}
        >
          <Tooltip title='Fully rounded corners'>
            <div className={classes.buttonStyle}>
              <div
                style={{
                  height: 15,
                  width: 15,
                  border: '1px solid',
                  borderColor: borderRadius === '50px' ? '#000000' : '#929292',
                  borderRight: 'none',
                  backgroundColor: '#E2E2E2',
                  borderTopLeftRadius: 50,
                  borderBottomLeftRadius: 50
                }}
              />
            </div>
          </Tooltip>
        </Button>
      </ButtonGroup>
    </div>
  )
}

interface WidthSelectorProps {
  width: string
  dispatch: any
  setCanSave: (value: boolean) => void
  field: string
  subField: string
}

function WidthSelector ({ width, dispatch, setCanSave, field, subField }: WidthSelectorProps): JSX.Element {
  const widthType = width.endsWith('%') ? '%' : 'px'
  const widthNumber = width.endsWith('%') ? Number(width.substring(0, width.length - 1)) : Number(width.substring(0, width.length - 2))

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <TextField
        size='small'
        variant='outlined'
        type='number'
        value={widthNumber || 0}
        onChange={(e: any) => {
          dispatch({ type: 'style', value: String(e.target.value) + widthType, field, subField })
          setCanSave(true)
        }}
      />
      <Select
        variant='outlined'
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        style={{ height: 40, width: '45%', marginLeft: 10 }}
        value={widthType}
        onChange={(e: any) => {
          dispatch({ type: 'style', value: String(widthNumber) + e.target.value, field, subField })
          setCanSave(true)
        }}
      >
        <MenuItem value='px'>px</MenuItem>
        <MenuItem value='%'>%</MenuItem>
      </Select>
    </div>
  )
}
interface FormStylesBarProps {
  form: Form
  dispatch: any
  setCanSave: (value: boolean) => void
}

export default function FormStylesBar ({ form, dispatch, setCanSave }: FormStylesBarProps) {
  const classes = useStyles()

  return (
    <div className={classes.fieldBar}>
      <StyleCard title='Form Styling' defaultExpanded>
        <div className={classes.tabSelectionDiv}>
          <LabeledCheckbox
            label={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ marginRight: 5 }} variant='body1'>Export form without styling</Typography>
                <InformationIcon
                  message='The form will inherit your site’s styling'
                  color='#808080'
                />
              </div>
            }
            checked={form.noStyle}
            onChange={(checked: boolean) => {
              dispatch({ type: 'noStyle', value: checked })
              setCanSave(true)
            }}
          />
        </div>
      </StyleCard>
      <StyleCard
        title='Form Container'
        disabled={form.noStyle}
      >
        <div className={classes.cardBody}>
          <SectionTitle title='Form Background' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Width</Typography>
              <WidthSelector
                width={form?.style?.form.width || '100%'}
                dispatch={dispatch}
                setCanSave={setCanSave}
                field='form'
                subField='width'
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Padding</Typography>
              <TextField
                size='small'
                variant='outlined'
                type='number'
                value={Number(form?.style?.form.padding.split('px', 1)[0]) || 0}
                onChange={(e: any) => {
                  const value = String(e.target.value) + 'px'
                  dispatch({ type: 'style', value, field: 'form', subField: 'padding' })
                  setCanSave(true)
                }}
              />
            </div>
          </div>
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>BG Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.form.backgroundColor || '#FFFFFF'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'form', subField: 'backgroundColor' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Corner Rounding</Typography>
              <BorderSelector
                borderRadius={form?.style?.form.cornerRadius || '0px'}
                dispatch={dispatch}
                setCanSave={setCanSave}
                field='form'
                subField='cornerRadius'
              />
            </div>
          </div>
          <div className={classes.tabSelectionDiv}>
            <LabeledCheckbox
              label={<Typography variant='body1'>Shadow</Typography>}
              checked={form?.style?.form.dropShadow || false}
              onChange={(checked: boolean) => {
                dispatch({ type: 'style', value: checked, field: 'form', subField: 'dropShadow' })
                setCanSave(true)
              }}
            />
          </div>
        </div>
      </StyleCard>
      <StyleCard
        title='Text'
        disabled={form.noStyle}
      >
        <div className={classes.cardBody}>
          <SectionTitle title='Font' />
          <Typography variant='body1'>Font will be inherited from your website’s styling.</Typography>
          <SectionTitle title='Form Title' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.title.color || '#000000'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'title', subField: 'color' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Size</Typography>
              <TextField
                size='small'
                variant='outlined'
                type='number'
                value={Number(form?.style?.title.fontSize.split('px', 1)[0]) || 0}
                onChange={(e: any) => {
                  const value = String(e.target.value) + 'px'
                  dispatch({ type: 'style', value, field: 'title', subField: 'fontSize' })
                  setCanSave(true)
                }}
              />
            </div>
          </div>
          <SectionTitle title='Form Subtitle' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.subtitle.color || '#000000'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'subtitle', subField: 'color' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Size</Typography>
              <TextField
                size='small'
                variant='outlined'
                type='number'
                value={Number(form?.style?.subtitle.fontSize.split('px', 1)[0]) || 0}
                onChange={(e: any) => {
                  const value = String(e.target.value) + 'px'
                  dispatch({ type: 'style', value, field: 'subtitle', subField: 'fontSize' })
                  setCanSave(true)
                }}
              />
            </div>
          </div>
          <SectionTitle title='Field Labels' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.label.color || '#000000'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'label', subField: 'color' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Size</Typography>
              <TextField
                size='small'
                variant='outlined'
                type='number'
                value={Number(form?.style?.label.fontSize.split('px', 1)[0]) || 0}
                onChange={(e: any) => {
                  const value = String(e.target.value) + 'px'
                  dispatch({ type: 'style', value, field: 'label', subField: 'fontSize' })
                  setCanSave(true)
                }}
              />
            </div>
          </div>
          <SectionTitle title='Helper Text' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.helperText.color || '#000000'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'helperText', subField: 'color' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Size</Typography>
              <TextField
                size='small'
                variant='outlined'
                type='number'
                value={Number(form?.style?.helperText.fontSize.split('px', 1)[0]) || 0}
                onChange={(e: any) => {
                  const value = String(e.target.value) + 'px'
                  dispatch({ type: 'style', value, field: 'helperText', subField: 'fontSize' })
                  setCanSave(true)
                }}
              />
            </div>
          </div>
        </div>
      </StyleCard>
      <StyleCard
        title='Fields'
        disabled={form.noStyle}
      >
        <div className={classes.cardBody}>
          <SectionTitle title='Field Style' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>BG Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.field.backgroundColor || '#FFFFFF'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'field', subField: 'backgroundColor' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
            <Tooltip
              title={form?.style?.field.borderStyle === 'bottom' ? 'Rounding the corners is disabled when there is only a bottom border.' : ''}
              placement='bottom'
            >
              <div
                className={classes.cardColumn}
                style={{
                  opacity: form?.style?.field.borderStyle === 'bottom' ? 0.5 : 1
                }}
              >
                <Typography variant='body1'>Corner Rounding</Typography>
                <BorderSelector
                  disabled={form?.style?.field.borderStyle === 'bottom'}
                  borderRadius={form?.style?.field.cornerRadius || '0px'}
                  dispatch={dispatch}
                  setCanSave={setCanSave}
                  field='field'
                  subField='cornerRadius'
                />
              </div>
            </Tooltip>
          </div>
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Border Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.field.borderColor || '#000000'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'field', subField: 'borderColor' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Border Style</Typography>
              <Select
                variant='outlined'
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }}
                style={{ height: 40 }}
                value={form?.style?.field.borderStyle}
                onChange={(e: any) => {
                  if (e.target.value === 'bottom') {
                    dispatch({ type: 'style', value: '0px', field: 'field', subField: 'cornerRadius' })
                  }
                  dispatch({ type: 'style', value: e.target.value, field: 'field', subField: 'borderStyle' })
                  setCanSave(true)
                }}
              >
                <MenuItem value='all'>All sides</MenuItem>
                <MenuItem value='bottom'>Bottom only</MenuItem>
                <MenuItem value='none'>No Border</MenuItem>
              </Select>
            </div>
          </div>
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Input Text Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.field.textColor || '#000000'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'field', subField: 'textColor' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
          </div>
        </div>
      </StyleCard>
      <StyleCard
        title='Button'
        disabled={form.noStyle}
      >
        <div className={classes.cardBody}>
          <SectionTitle title='Button Color' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Fill Type</Typography>
              <Select
                variant='outlined'
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }}
                style={{ height: 40 }}
                value={form?.style?.button?.fillType}
                onChange={(e: any) => {
                  dispatch({ type: 'style', value: e.target.value, field: 'button', subField: 'fillType' })
                  setCanSave(true)
                }}
              >
                <MenuItem value='solid'>Solid Color</MenuItem>
                <MenuItem value='gradient'>Gradient</MenuItem>
              </Select>
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>BG Color</Typography>
              <div>
                <ColorPopOver
                  borderColor='#C4C4C4'
                  value={form?.style?.button.primaryBackgroundColor || '#3C76E7'}
                  onChange={(color: string) => {
                    dispatch({ type: 'style', value: color, field: 'button', subField: 'primaryBackgroundColor' })
                    setCanSave(true)
                  }}
                  additionalInfoEdit
                  placeholder='Primary'
                />
                {form?.style?.button.fillType === 'gradient' && (
                  <>
                    <div style={{ marginTop: 5, marginBottom: 5 }} />
                    <ColorPopOver
                      borderColor='#C4C4C4'
                      value={form?.style?.button.secondaryBackgroundColor || '#1B54C3'}
                      onChange={(color: string) => {
                        dispatch({ type: 'style', value: color, field: 'button', subField: 'secondaryBackgroundColor' })
                        setCanSave(true)
                      }}
                      additionalInfoEdit
                      placeholder='Secondary'
                    />
                  </>)}
              </div>
            </div>
          </div>
          <div className={classes.tabSelectionDiv}>
            <LabeledCheckbox
              label={<Typography variant='body1'>Shadow</Typography>}
              checked={form?.style?.button.dropShadow || false}
              onChange={(checked: boolean) => {
                dispatch({ type: 'style', value: checked, field: 'button', subField: 'dropShadow' })
                setCanSave(true)
              }}
            />
          </div>
          <SectionTitle title='Button Text & Icon' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Color</Typography>
              <ColorPopOver
                borderColor='#C4C4C4'
                value={form?.style?.button.color || '#FFFFFF'}
                onChange={(color: string) => {
                  dispatch({ type: 'style', value: color, field: 'button', subField: 'color' })
                  setCanSave(true)
                }}
                additionalInfoEdit
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Font Size</Typography>
              <TextField
                size='small'
                variant='outlined'
                type='number'
                value={Number(form?.style?.button.fontSize.split('px', 1)[0]) || 0}
                onChange={(e: any) => {
                  const value = String(e.target.value) + 'px'
                  dispatch({ type: 'style', value, field: 'button', subField: 'fontSize' })
                  setCanSave(true)
                }}
              />
            </div>
          </div>
          <SectionTitle title='Button Formatting' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Button Width</Typography>
              <WidthSelector
                width={form?.style?.button.width || '100%'}
                dispatch={dispatch}
                setCanSave={setCanSave}
                field='button'
                subField='width'
              />
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Button Alignment</Typography>
              <ButtonAlignmentSelector
                alignment={form?.style?.button.align || 'left'}
                form={form}
                dispatch={dispatch}
                setCanSave={setCanSave}
              />
            </div>
          </div>
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>Corner Rounding</Typography>
              <BorderSelector
                borderRadius={form?.style?.button.cornerRadius || '0px'}
                dispatch={dispatch}
                setCanSave={setCanSave}
                field='button'
                subField='cornerRadius'
              />
            </div>
          </div>
          <SectionTitle title='Interaction Status' />
          <div className={classes.cardRow}>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>On Hover</Typography>
              <Select
                variant='outlined'
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }}
                style={{ height: 40 }}
                value={form?.style?.button.onHover}
                onChange={(e: any) => {
                  dispatch({ type: 'style', value: e.target.value, field: 'button', subField: 'onHover' })
                  setCanSave(true)
                }}
              >
                <MenuItem value='none'>None</MenuItem>
                <MenuItem value='darken'>Darken</MenuItem>
                <MenuItem value='lighten'>Lighten</MenuItem>
                <MenuItem value='invert'>Invert</MenuItem>
              </Select>
            </div>
            <div className={classes.cardColumn}>
              <Typography variant='body1'>On Click</Typography>
              <Select
                variant='outlined'
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  }
                }}
                style={{ height: 40 }}
                value={form?.style?.button.onClick}
                onChange={(e: any) => {
                  dispatch({ type: 'style', value: e.target.value, field: 'button', subField: 'onClick' })
                  setCanSave(true)
                }}
              >
                <MenuItem value='none'>None</MenuItem>
                <MenuItem value='shadow'>Shadow</MenuItem>
                <MenuItem value='downward-shift'>Downward shift</MenuItem>
                <MenuItem value='shrink'>Shrink</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </StyleCard>
    </div>
  )
}
