import { Divider } from 'library/materialUI'
import SegmentCriteria from './SegmentCriteria'
import SegmentDisplay from './SegmentDisplay'

export const DynamicSegmentModal = (): JSX.Element => {
  return (
    <>
      <div
        style={{ height: 575, display: 'flex', flexDirection: 'row' }}
      >
        <SegmentCriteria />
        <Divider orientation='vertical' style={{ height: '100%' }} />
        <SegmentDisplay />
      </div>
    </>
  )
}
