import { makeStyles } from '@material-ui/core/styles'
import Account from 'classes/accounts'
import { Icon } from 'library/materialUI'
import DashboardCard from 'pages/dashboard/DashboardCard'
import StaticChip from 'library/StaticChip'
import { useContext, useRef, useState } from 'react'
import { ShareContext } from 'share-context'
import { AccountPageContext } from './AccountPageProvider'
import { formatRevenue } from 'helpers/numbers'
import InteractiveTooltip from 'library/InteractiveTooltip'
import VotingPopper from './VotingPopper'
import { truncateString } from 'library/helpers'

const useStyles = makeStyles(theme => ({
  attribute: {
    marginBottom: '10px'
  },
  label: {
    color: '#808080',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    fontSize: '15px'
  },
  value: {
    fontSize: '14px',
    marginLeft: '30px',
    marginTop: '5px'
  },
  emptyVal: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  detailValue: {
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  accountDetail: {
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap'
  },
  detailTitle: {
    fontWeight: 600,
    color: '#a387d4',
    fontSize: '16px',
    minWidth: 180,
    display: 'flex',
    flexDirection: 'row'
  }
}))

interface AccountDetailProps {
  title?: string
  value: string
  displayValue?: string
  field: string
  domain: string
  icon?: string
  link?: boolean
  maxCharacters?: number
}

function AccountDetail ({ ...props }: AccountDetailProps) {
  const classes = useStyles()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [display, setDisplay] = useState(false)
  const { flags } = useContext(ShareContext)
  const { setDetailsModalOpen, setLastSelectedDetail } = useContext(AccountPageContext)
  const displayValue = props.displayValue || props.value
  const showTitle = props.title
  const characters = props.maxCharacters || 30

  const showValue = () => {
    if (flags['crowd-sourced-company-data'] && displayValue === '---' && props.field !== 'account_type') {
      return (
        <div
          className={classes.emptyVal}
          onClick={() => {
            setDetailsModalOpen(true)
            setLastSelectedDetail(props.field)
          }}
        >
          + Add
        </div>
      )
    }
    return displayValue.length > characters ? displayValue.substring(0, characters - 3) + '...' : displayValue
  }
  const showVoter = display &&
    props.value !== null &&
    props.value !== '---' &&
    props.field !== 'account_type' &&
    flags['crowd-sourced-company-data']
  return (
    <div className={classes.accountDetail}>
      {showTitle && (
        <div className={classes.detailTitle}>
          {props.icon &&
            <div style={{ marginRight: '5px' }}>
              {
                props.icon === 'facebook' ? (
                  <Icon icon='facebookLogo' />
                ) : props.icon === 'linkedin' ? (
                  <Icon icon='linkedinLogo' />
                ) : props.icon === 'twitter' ? (
                  <Icon icon='twitterLogo' />
                ) : <></>
              }
            </div>}
          {props.title}
        </div>
      )}
      <div
        ref={anchorRef}
        onMouseEnter={() => { setOpen(true); setDisplay(true) }}
        onMouseLeave={() => setOpen(false)}
        className={classes.detailValue}
        onClick={() => {
          if (props?.link && props?.value) {
            const link = props.value.substring(0, 4) !== 'http' ? 'https://' + props.value : props.value
            window.open(link, '_blank')
          }
        }}
        style={(props?.link && props?.value !== '---') ? { cursor: 'pointer', color: 'blue' } : undefined}
      >
        {showValue()}
      </div>
      {showVoter && (
        <InteractiveTooltip anchorRef={anchorRef} xOffset={-80} yOffset={30} position='right' color='#00000098' open={open} setDisplay={setDisplay}>
          <VotingPopper domain={props.domain} field={props.field} value={props.value} />
        </InteractiveTooltip>
      )}
    </div>
  )
}

interface AccountAboutProps {
  account: Account
}

export default function AccountAbout ({ account }: AccountAboutProps) {
  const classes = useStyles()
  const { flags } = useContext(ShareContext)
  const { setDetailsModalOpen, setLastSelectedDetail } = useContext(AccountPageContext)
  const [seeMore, setSeeMore] = useState(false)

  let hq = ''
  if (account) {
    if (account.addressCity && account.addressState) {
      hq = account.addressCity + ', ' + account.addressState
    } else if (account.addressCity) {
      hq = account.addressCity
    } else if (account.addressState) {
      hq = account.addressState
    } else if (account.addressCountryCode) {
      hq = account.addressCountryCode
    }
  }

  const displayTech = () => {
    if (account.technologies) {
      return account.technologies.map((tech, index) => {
        return <StaticChip key={index} value={tech} />
      })
    } else if (flags['crowd-sourced-company-data']) {
      return (
        <div
          className={classes.emptyVal}
          onClick={() => {
            setDetailsModalOpen(true)
            setLastSelectedDetail('technologies')
          }}
        >
          + Add
        </div>
      )
    }
    return 'No technologies found'
  }

  return (
    <DashboardCard
      title='About'
      noScrollbox
      titleAction={
        <div onClick={() => { setDetailsModalOpen(true); setLastSelectedDetail('name') }} style={{ border: '1px solid #D3D3D3', borderRadius: '5px', padding: '8px 15px 8px 15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
          <div>
            <Icon icon='edit' color='#808080' size='sm' />
          </div>
          <div style={{ marginLeft: '10px', fontSize: '14px' }}>
            Edit
          </div>
        </div>
      }
    >
      <div style={{ maxHeight: '416px', overflow: 'auto' }}>
        <div style={{ marginBottom: '5px' }}>
          <div>
            {!seeMore ? (
              truncateString(account.description, 172, true)
            ) : (
              account.description
            )}
          </div>
          <div style={{ marginBottom: '20px', color: '#808080', cursor: 'pointer' }}>
            <div
              onClick={() => setSeeMore(!seeMore)}
            >
              {seeMore ? 'See less' : 'See more'}
            </div>
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='domain' color='#808080' />
            <div>
              Industry
            </div>
          </div>
          <div className={classes.value}>
            <AccountDetail value={account.industry || '---'} domain={account.domain} field='industry' maxCharacters={45} />
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='money' color='#808080' />
            <div>
              Annual Revenue
            </div>
          </div>
          <div className={classes.value}>
            <AccountDetail value={String(account.annualRevenue)} displayValue={account.revenueRange || '---'} domain={account.domain} field='annual_revenue' maxCharacters={45} />
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='location' color='#808080' />
            <div>
              HQ Location
            </div>
          </div>
          <div className={classes.value}>
            <AccountDetail value={hq || '---'} domain={account.domain} field='location' maxCharacters={45} />
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='users' color='#808080' />
            <div>
              Employee Count
            </div>
          </div>
          <div className={classes.value}>
            <AccountDetail value={String(account.employeeCount)} displayValue={account.employeeRange || '---'} domain={account.domain} field='number_of_employees' maxCharacters={45} />
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='phone' color='#808080' />
            <div>
              Phone
            </div>
          </div>
          <div className={classes.value}>
            <AccountDetail value={account.phone || '---'} domain={account.domain} field='phone' maxCharacters={45} />
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='money' color='#808080' />
            <div>
              Total Funding
            </div>
          </div>
          <div className={classes.value}>
            <AccountDetail value={String(account?.totalFunding)} displayValue={account.totalFunding ? formatRevenue(account.totalFunding) : '---'} domain={account.domain} field='total_funding' maxCharacters={45} />
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='money' color='#808080' />
            <div>
              Last Funding Date
            </div>
          </div>
          <div className={classes.value}>
            <AccountDetail value={account.lastFundingDate || '---'} domain={account.domain} field='last_funding_date' maxCharacters={45} />
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='money' color='#808080' />
            <div>
              Last Funding Amount
            </div>
          </div>
          <div className={classes.value}>
            <AccountDetail value={String(account?.lastFundingAmount)} displayValue={account.lastFundingAmount ? formatRevenue(account.lastFundingAmount) : '---'} domain={account.domain} field='last_funding_amount' maxCharacters={45} />
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='domain' color='#808080' />
            <div>
              Company Tech
            </div>
          </div>
          <div className={classes.value}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {displayTech()}
            </div>
          </div>
        </div>
      </div>
    </DashboardCard>
  )
}
