import { useState, useContext, useEffect } from 'react'
import { SessionContext } from 'session-context'
import { getGroup, deleteGroups } from 'api/keyword_groups'
import AppPage from 'cf-components/AppPage'
import KeyWordGroupsModal from './KeyWordGroupsModal'
import KeyWordGroupsTable from './KeyWordGroupsTable'
import DeleteModal from 'components/DeleteModal'

function KeyWordGroupsPage (props) {
  const { user } = useContext(SessionContext)
  const [chatServiceUrl] = useState(user.links.chat_service || 'localhost:8002/api/chat-service/a')
  const [modalOpen, setModalOpen] = useState(false)
  const [deleteGroupID, setDeleteGroupID] = useState(0)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [modalState, setModalState] = useState({
    name: '',
    description: '',
    id: null,
    comparison: 'contains',
    keywords: [],
    edit: false
  })

  function editGroup (rowId) {
    getGroup({ chatServiceUrl, group_id: rowId }).then(response => {
      const keywordGroup = response.data.attributes
      setModalState({
        name: keywordGroup.name,
        description: keywordGroup.description,
        id: response.data.id,
        comparison: keywordGroup.comparison,
        keywords: keywordGroup.keywords,
        edit: true
      })
      setModalOpen(true)
    }).catch(error => {
      console.error('Error fetching group', error)
    })
  }

  function newGroup () {
    setModalState({
      name: '',
      description: '',
      id: null,
      comparison: 'contains',
      keywords: [],
      edit: false
    })
    setModalOpen(true)
  }

  useEffect(() => {
    if (!modalOpen && !deleteModalOpen) {
      // Refresh table when either modal is closed
      const tableElement = document.getElementById('keyword-groups')
      if (tableElement) {
        const mywindow = window
        mywindow.htmx.process(tableElement)
        mywindow.htmx.trigger(tableElement, 'paramChange')
      }
    }
  }, [modalOpen, deleteModalOpen])

  function beginDelete () {
    deleteGroups({ group_ids: [deleteGroupID], chatServiceUrl })
      .then(response => {
        setDeleteGroupID(0)
        setDeleteModalOpen(false)
      })
  }

  const deleteMessage = 'Are you sure you want to delete this group?'
  const deleteTitle = 'Delete group'

  document.title = 'Keyword Groups | Signals'

  return (
    <AppPage
      title='Keyword Groups'
      action={newGroup}
      actionText='Create New Group'
    >
      <KeyWordGroupsTable
        editGroup={editGroup}
        handleDelete={(groupID) => {
          setDeleteGroupID(groupID)
          setDeleteModalOpen(true)
        }}
      />
      <KeyWordGroupsModal
        open={modalOpen}
        onHide={() => setModalOpen(false)}
        state={modalState}
        onChange={setModalState}
      />
      <DeleteModal
        open={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
        deleteObject={beginDelete}
        message={deleteMessage}
        title={deleteTitle}
      />
    </AppPage>
  )
}

export default KeyWordGroupsPage
