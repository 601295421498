import { useEffect, useState } from 'react'
import { FilterBar } from './FilterBar/FilterBar'
import { CardList } from './CardList'
import { useDebounce } from 'use-debounce'
import { debounceDelay } from 'library/constants'

export default function Table (props) {
  const [sortAscending, setSortAscending] = useState(typeof props.sortAscending === 'undefined' ? true : props.sortAscending)
  const [sortCriteria, setSortCriteria] = useState(props.startingSortColumn)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, debounceDelay)
  const onExport = props.handleExport
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 10)
  const [pageNumber, setPageNumber] = useState(props.pageNumber || 1)
  HandleSearchParamSortColumn(props.setSortColumn, sortCriteria, props.setSortOrder, sortAscending)
  HandleSearchParamSearch(props.setSearch, search)
  const pageChanged = (n) => {
    if (props.setPageNumber) {
      props.setPageNumber(n)
    }
    setPageNumber(n)
  }
  const pageSizeChanged = (n) => {
    if (props.setRowsPerPage) {
      props.setRowsPerPage(n)
    }
    setRowsPerPage(n)
  }

  let rows = null
  if (props.rows) {
    rows = props.rows.filter(row => row.searchField?.toLowerCase().includes(debouncedSearch.toLowerCase()))
  }

  useEffect(() => {
    setPageNumber(1)
  }, [props?.rows?.length])

  const sortOptions = {
    sortValues: props.sortValues,
    sortCriteria: sortCriteria,
    setSortCriteria: setSortCriteria,
    sortAscending: sortAscending,
    setSortAscending: setSortAscending
  }
  const disableNext = props.disableNext === undefined || props.disableNext === true

  return (
    <div>
      {props.hideFilterBar || (
        <FilterBar
          search={search}
          setSearch={setSearch}
          sortOptions={sortOptions}
          handleExport={onExport}
          filterOptions={props.filterOptions}
        />
      )}
      <CardList
        rows={rows || null}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={pageSizeChanged}
        pageNumber={pageNumber}
        setPageNumber={pageChanged}
        pagination={props.pagination}
        disableNext={disableNext}
        isLoading={props.isLoading}
        card={props.card}
        cardProps={props.cardProps}
        header={props.header}
        sortOptions={sortOptions}
        headerProps={{
          columns: props.sortValues.filter(value => value.header),
          sortCriteria: sortCriteria,
          setSortCriteria: setSortCriteria,
          sortAscending: sortAscending,
          setSortAscending: setSortAscending,
          headerClass: props.headerClass
        }}
      />
    </div>
  )
}

function HandleSearchParamSortColumn (setSortColumn, sortCriteria, setSortOrder, sortAscending) {
  useEffect(() => {
    if (setSortColumn && sortCriteria) {
      setSortColumn(sortCriteria)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortCriteria])
  useEffect(() => {
    if (setSortOrder) {
      const sort = sortAscending ? 'asc' : 'desc'
      setSortOrder(sort)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAscending])
}

function HandleSearchParamSearch (setSearch, search) {
  useEffect(() => {
    if (setSearch) {
      setSearch(search)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])
}
