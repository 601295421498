import { useContext, useState, useReducer, useEffect } from 'react'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'
import EditContactModal from './EditContactModal'
import CustomFieldsModal from 'components/CustomFieldsModal'
import CustomFieldsPage from 'components/CustomFieldsPage'
import StyledSwitch from 'components/StyledSwitch'
import { createCustomField } from 'api/custom_fields'
import { createContact } from 'api/contacts'
import AppPage from 'cf-components/AppPage'
import ContactTable from './ContactTable'
import CustomField from 'classes/customFields'
import { queryClient } from 'App'
import Persona from 'classes/personas'
import BuyingCommitteeTable from 'pages/accounts/AccountManagement/BuyingCommittee/BuyingCommitteeTable'
import BuyingCommitteeModal from 'pages/accounts/AccountManagement/BuyingCommittee/BuyingCommitteeModal'
import AIBuyingCommitteeModal from 'pages/accounts/AccountManagement/BuyingCommittee/AIBuyingCommitteeModal'
import { components, SessionContext } from 'session-context'
import { Icon } from 'library/materialUI'
import AutoLookupSettingsModal from './AutoLookupSettingsModal'
import { updateLookupSettings as saveSettings, getLookupSettings } from 'api/lookup_settings'
import AccessControl from 'components/AccessControl'

const lookupReducer = (state, action) => {
  switch (action.type) {
    case 'setAll':
      console.log('lookupSettings - setAll', action)
      return action.value
    case 'toggle':
      saveSettings({ settings: { ...state, enabled: !state.enabled } })
      return { ...state, enabled: !state.enabled }
    case 'setSegments':
      return { ...state, segments: action.value }
    case 'setSettings':
      return { ...state, settings: action.value }
    default:
      return state
  }
}

const lookupInitialState = {
  enabled: false,
  segments: [],
  settings: {
    contactLimit: 5,
    personaIDs: [],
    emailEnabled: false,
    delaySend: false,
    emailID: null,
    delayHours: 0,
    integrationEnabled: false,
    integrationList: []
  }
}

function ContactManagementPage () {
  const { isOn, canAdd } = useContext(SessionContext)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [fieldModalOpen, setFieldModalOpen] = useState(false)
  const [personaModalOpen, setPersonaModalOpen] = useState(false)
  const [aiPersonaModalOpen, setAiPersonaModalOpen] = useState(false)
  const [showPersonaMenu, setShowPersonaMenu] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [update, setUpdate] = useState(0)
  const { data: customFields } = CustomField.loadAll(undefined, 'people')
  const [anchorEl, setAnchorEl] = useState(null)
  const personasOn = isOn(components.PERSONAS)
  const autoContactLookup = isOn(components.AUTO_CONTACT_LOOKUP)
  const canAddPersonas = canAdd(components.PERSONAS)
  const [lookupModalOpen, setLookupModalOpen] = useState(false)
  const [loadingSettings, setLoadingSettings] = useState(true)
  const [lookupSettings, updateLookupSettings] = useReducer(lookupReducer, lookupInitialState)
  console.log('lookupSettings.enabled:', lookupSettings.enabled)

  function addAccount (account) {
    setAccounts({ ...accounts, [account.id]: account })
  }

  const dbAttributes = [
    'first_name',
    'last_name',
    'honorific',
    'phone',
    'mobile_phone',
    'email',
    'title',
    'description',
    'lead_source',
    'other_email',
    'other_phone',
    'custom_fields',
    'company_name',
    'company_website',
    'company_industry',
    'company_employee_count',
    'company_revenue',
    'city',
    'state',
    'country',
    'bio',
    'role',
    'seniority',
    'linkedin_handle',
    'company_sector',
    'company_phone',
    'company_description',
    'company_location',
    'company_timezone',
    'company_twitter_handle',
    'company_tech',
    'company_linkedin_handle',
    'avatar_url',
    'company_avatar_url',
    'facebook_handle',
    'twitter_handle',
    'instagram_handle'
  ]

  function createPerson (values) {
    const customFieldNames = customFields?.list.map(field => field.name)

    const customAttributes = {}
    customFieldNames.forEach(attr => {
      customAttributes[attr] = values[attr]
    })

    const personAttributes = {}
    dbAttributes.forEach(attr => {
      personAttributes[attr] = values[attr]
    })

    personAttributes.custom_fields = customAttributes

    createContact({ personAttributes })
      .then(response => {
        setUpdate(update + 1)
      })
  }

  function createNewField (values) {
    const forObject = 'people'
    createCustomField({ values, forObject })
      .then(response => {
        queryClient.invalidateQueries('customFields')
      })
  }

  function handlePersonaClick (event) {
    setAnchorEl(event.currentTarget)
    setShowPersonaMenu(true)
  }

  const tabs = [
    { name: 'Contacts', url: '/contact_management/contacts', action: () => setEditModalOpen(true), actionText: 'Add Contact', bgColor: 'white' }
  ]

  if (personasOn) {
    tabs.push(
      {
        name: 'Buying Committee',
        url: '/contact_management/buying_committee',
        action: handlePersonaClick,
        actionText: 'Add Persona',
        disabled: !canAddPersonas || !personasOn,
        tooltip: !canAddPersonas ? (
          'You\'re at the maximum number of personas for your plan. Uprade your plan or delete a persona to create a new one.'
        ) : (
          !personasOn ? (
            'Personas are not available in your current plan. Upgrade your plan to access this feature.'
          ) : undefined
        )
      }
    )
  }
  if (isOn(components.PLAYMAKER)) {
    tabs.push({ name: 'Contact Custom Fields', url: '/contact_management/custom_fields', action: () => setFieldModalOpen(true), actionText: 'Add Custom Field' })
  }

  document.title = 'Contact Management | Signals'

  useEffect(() => {
    if (autoContactLookup) {
      getLookupSettings().then(response => {
        if (response?.data) {
          updateLookupSettings({ type: 'setAll', value: response.data.attributes })
          setLoadingSettings(false)
        }
      })
    }
  }, [autoContactLookup])

  return (
    <AccessControl requiredComponent={components.CONTACTS}>
      <>
        <AppPage
          title='Contact Management'
          tabs={tabs}
          padding={0}
          extraButtons={
            <>
              {autoContactLookup && (
                <>
                  {loadingSettings ? <><div style={{ width: 558, height: 40 }}><SkeletonLabel size='full' /></div></> : (
                    <div style={{ paddingLeft: '20px', paddingRight: '20px', display: 'flex', alignItems: 'center', borderRadius: '50px', height: '40px', backgroundColor: lookupSettings.enabled ? '#EEF9CE' : '#F5F5F5' }}>
                      <div>
                        Automatic Contact Lookup: {lookupSettings.enabled ? 'On' : 'Off'}
                      </div>
                      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <StyledSwitch checked={lookupSettings.enabled} toggle={() => updateLookupSettings({ type: 'toggle' })} />
                      </div>
                      <div onClick={() => setLookupModalOpen(true)} style={{ cursor: 'pointer' }}>
                        <Icon icon='settings' />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          }
        >
          <ContactTable />
          {
            personasOn && (
              <BuyingCommitteeTable
                showPersonaMenu={showPersonaMenu}
                setShowPersonaMenu={setShowPersonaMenu}
                setPersonaModalOpen={setPersonaModalOpen}
                setAiPersonaModalOpen={setAiPersonaModalOpen}
                anchorEl={anchorEl}
              />
            )
          }
          <CustomFieldsPage forObject='people' />
        </AppPage>
        <EditContactModal
          open={editModalOpen}
          onHide={() => setEditModalOpen(false)}
          accounts={accounts}
          addAccount={addAccount}
          person={null}
          customFields={customFields?.list || []}
          savePerson={values => {
            createPerson(values)
            setEditModalOpen(false)
          }}
        />
        {personaModalOpen &&
          <BuyingCommitteeModal
            open={personaModalOpen}
            onHide={() => setPersonaModalOpen(false)}
            persona={new Persona({ attributes: {} })}
          />}
        <AIBuyingCommitteeModal
          open={aiPersonaModalOpen}
          onHide={() => setAiPersonaModalOpen(false)}
          canAddAmount={canAddPersonas}
        />
        <AutoLookupSettingsModal
          open={lookupModalOpen}
          setOpen={setLookupModalOpen}
          allSettings={lookupSettings}
          setAllSettings={updateLookupSettings}
        />
        {
          isOn(components.PLAYMAKER) && (
            <CustomFieldsModal
              open={fieldModalOpen}
              onHide={() => setFieldModalOpen(false)}
              save={createNewField}
            />
          )
        }
      </>
    </AccessControl>
  )
}

export default ContactManagementPage
