/* eslint-disable @typescript-eslint/naming-convention */
import AppPage from 'cf-components/AppPage'
import AvailabilityCard from 'cf-components/AvailabilityCard'
import Team from 'classes/teams'
import { useEffect, useReducer, useState } from 'react'
import { UserListPage } from '../UserManagement/UserListPage'
import { TeamModal } from './TeamModal'

interface TeamPageProps {
  isExact: true
  params: { team_id: string }
  path: string
  url: string
}
const DB_PROPS = ['name', 'teamMemberIDs', 'availabilityType', 'online', 'offlineWhenUnavailable', 'timezone', 'availabilities'] as const

const reducer = (state: any, changes: any): Team => {
  // this is done to function with AvailabilityCard dispatch function
  const currentAvailability = state?.availabilities
  const newTime = {
    days: 'Weekdays',
    start_time: '09:00:00',
    end_time: '17:00:00'
  }
  const firstKey = Object.keys(changes)[0]
  let newAvailability
  switch (changes.availabilityType.type) {
    case 'addTime':
      changes = { availabilities: currentAvailability.concat([newTime]) }
      break
    case 'removeTime':
      changes = {
        availabilities: currentAvailability.filter((_: any, i: any) => i !== changes.availabilityType.index)
      }
      break
    case 'updateTime':
      newAvailability = JSON.parse(JSON.stringify(currentAvailability))
      newAvailability[changes.availabilityType.index][changes.availabilityType.name] = changes.availabilityType.value
      changes = { availabilities: newAvailability }
      break
    case 'toggleSetting':
      if (changes.availabilityType.name === 'online') {
        changes = { online: !state.online }
      } else {
        changes = { offlineWhenUnavailable: !state.offlineWhenUnavailable }
      }
      break
    case 'updateSetting':
      changes = { timezone: changes.availabilityType.value }
      break
    default:
      if (changes[firstKey].value) {
        changes[firstKey] = changes[firstKey].value
      }
  }
  // above can be deleted when availability card uses team class
  return { ...state, ...changes }
}

export function TeamPage ({ match }: { match: TeamPageProps }): JSX.Element {
  const teamID = parseInt(match.params.team_id)
  const { data: team } = Team.loadOne(teamID)
  const [state, dispatch] = useReducer(reducer, team)
  const [editTeamMembers, setEditTeamMembers] = useState<Team | null>(null)
  const [disabled, setDisabled] = useState(true)
  const [saveAttempt, setSaveAttempt] = useState(false)
  const saveSettings = () => { Team.save(state, DB_PROPS); setDisabled(true); setSaveAttempt(true) }

  const saveProps = {
    disabled: disabled,
    save: saveSettings,
    saveText: 'Save Settings'
  }
  const tabs = [
    { name: 'Team Members', url: `/teams/${teamID}/members`, action: () => setEditTeamMembers(team), actionText: 'Add Team Member' },
    { name: 'Chat Availability', url: `/teams/${teamID}/availability`, saveProps: saveProps }
  ]
  const breadcrumbs = [
    { link: '/user_management/teams', text: 'Teams' },
    { link: null, text: 'Team Page' }
  ]

  useEffect(() => {
    const nonTeamObj = { ...team }
    setDisabled(Team.isEqual(state, nonTeamObj))
  }, [state, team])

  // wait for query to return and then set state
  useEffect(() => {
    if (team && !state) {
      dispatch(team)
    }
    // eslint-disable-next-line
  }, [team])

  useEffect(() => {
    if (state?.name !== team?.name) {
      saveSettings()
      console.log(`state: ${state.name}`)
    }
    // eslint-disable-next-line
  }, [state?.name])

  // if (!state) {
  //   return <LoadingScreen />
  // }

  return (
    <>
      <AppPage
        title={state?.name || ''}
        tabs={tabs}
        saveName={(name: string) => {
          dispatch({ name: name, availabilityType: state.availabilityType })
        }}
        breadcrumbs={breadcrumbs}
        editableTitle={state?.name !== null}
        isLoading={!state}
      >
        <UserListPage
          teamMemberIds={state?.teamMemberIDs}
          teamID={teamID}
          actionRef={editTeamMembers}
        />
        <div style={{ padding: 20 }}>
          {state &&
            <AvailabilityCard
              state={state}
              dispatch={(value: string) => dispatch({ availabilityType: value })}
              title='Team Chat Availability'
              teamPage
              scheduled={state.availabilityType === 'scheduled'}
              saveAttempt={saveAttempt}
            />}
        </div>
      </AppPage>
      {editTeamMembers &&
        <TeamModal
          team={editTeamMembers}
          onHide={() => setEditTeamMembers(null)}
        />}
    </>
  )
}
