/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect } from 'react'
import DashboardTabCard from './DashboardTabCard'

interface ABEDashboardProps {
  modalOpen: string | null
  setPotentialAccountModalOpen: (value: boolean) => void
  setModalDomain: (value: string) => void
  accountAdded: string[]
}

function ABEDashboard ({ setPotentialAccountModalOpen, setModalDomain, accountAdded }: ABEDashboardProps) {
  const mywindow = window as any

  useEffect(() => {
    try {
      const ids = ['ip-lookup-banner']
      for (const id of ids) {
        const d = document.getElementById(id)
        mywindow.htmx.process(d)
      }
    } catch (e) {
      console.log(e)
    }
  })

  document.title = 'Dashboard | Signals'

  return (
    <div>
      <div
        id='ip-lookup-banner'
        hx-get='/api/core/web/missing_lookups'
        hx-trigger='load'
      />
      <DashboardTabCard
        setPotentialAccountModalOpen={setPotentialAccountModalOpen}
        setModalDomain={setModalDomain}
        accountAdded={accountAdded}
      />
      <div style={{ textAlign: 'right', fontSize: 12, paddingTop: '7px', paddingRight: '7px' }}>
        Powered by 6sense
      </div>
    </div>
  )
}

export default ABEDashboard
