import { doPost } from 'api/api'

export type ProspectResponseTypes = 'already prospected' | 'already prospected no contacts' | 'prospect starting' | 'prospect already in progress' | 'no more contact credits'

type ProspectPayloadBase = {
  personaIDs: number[]
  contactLimit: number
  gatherAnyways: boolean
  integrations: Record<string, string[]>
  emailEnabled: boolean
  emailID: number
  delaySend: boolean
  delayHours: number
}

type ManualProspectPayload = ProspectPayloadBase & {
  domain: string
}

type BulkProspectPayload = ProspectPayloadBase & {
  domains: string[]
}

export type BulkProspectObject = {
  alreadyProspected: number
  alreadyProspectedNoContacts: number
  goodToProspect: number
  noContacts: number
  noMoreUsage: number
  prospectInProgress: number
  prospectSuccess: number
}

export class ProspectResponse {
  resp: ProspectResponseTypes
  bulkResp: BulkProspectObject

  static prospect (data: ManualProspectPayload): Promise<ProspectResponse> {
    return doPost({ path: '/prospect', data: { attributes: data }, useChatURL: true })
    .then((resp) => {
      return new ProspectResponse({ row: resp.data })
    })
  }

  static bulkProspect (data: BulkProspectPayload): Promise<ProspectResponse> {
    return doPost({ path: '/prospect_bulk', data: { attributes: data }, useChatURL: true })
    .then((resp) => {
      return new ProspectResponse({ row: resp.data })
    })
  }

  constructor ({ row }: { row?: Record<string, any> }) {
    const prospectResponse = row?.attributes
    this.resp = prospectResponse.resp
    this.bulkResp = prospectResponse.bulkResp
  }
}
