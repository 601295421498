import { Popper, PopperPlacementType, ClickAwayListener } from '@material-ui/core'
import { SearchParams } from 'classes/queryHelpers'
import { FilterSubMenu } from 'library/table/FilterButton'
import { FilterOptions } from 'library/table/FilterOption'

interface FilterOptionMenuProps {
  openFilter: string
  secondAnchor: any
  setSecondAnchor: () => void
  listFilterProps?: any
  filterOptions: FilterOptions
  placement?: PopperPlacementType
  searchParams: SearchParams
  handleFilter: (filters: any) => void
}

export default function FilterOptionMenu ({ openFilter, secondAnchor, setSecondAnchor, listFilterProps, placement, filterOptions, handleFilter, searchParams }: FilterOptionMenuProps) {
  const filter = listFilterProps && listFilterProps?.filterOptions ? listFilterProps.filterOptions[openFilter] : filterOptions[openFilter]

  return (
    <ClickAwayListener onClickAway={() => setSecondAnchor()}>
      <Popper
        open={!!openFilter}
        anchorEl={secondAnchor}
        placement={placement || 'left'}
        style={{ zIndex: 1000 }}
      >
        <FilterSubMenu
          {...filter}
          goBack={() => setSecondAnchor()}
          listFilterProps={listFilterProps}
          searchParams={searchParams}
          handleFilter={handleFilter}
        />
      </Popper>
    </ClickAwayListener>
  )
}
