
import { useCallback, useContext, useEffect, useRef, createContext } from 'react'
import useChat from 'pages/chat/agent-chat/useChat'
import { SessionContext } from 'session-context'
import { getToken } from 'api/auth'
import { useQuery, useQueryClient } from 'react-query'

const PostageSocketContext = createContext({})

function PostageSocketProvider ({ children }) {
  const listeners = useRef(new Set())
  const { user } = useContext(SessionContext)
  const url = user.links.ws_url
  const queryClient = useQueryClient()

  const messageCallback = messageObject => {
    const data = JSON.parse(messageObject.data)

    if (data.type === 'data_update' && data.attributes?.data_type) {
      queryClient.invalidateQueries(data.attributes.data_type)
    }

    for (const listener of listeners.current) {
      setTimeout(() => { listener(data) }, 0)
    }
  }

  const { data: token } = useQuery(['postage-socket-token'], () => getToken({}).then(res => res.token))

  const {
    connectionStatus,
    subscribeToConversations
  } = useChat({
    token,
    url,
    messageCallback
  })

  const subscribe = useCallback((i) => {
    listeners.current.add(i)
  }, [])

  const unsubscribe = useCallback((i) => {
    listeners.current.delete(i)
  }, [])

  return (
    <>
      <PostageSocketContext.Provider value={{ connectionStatus, subscribeToConversations, subscribe, unsubscribe }}>
        {children}
      </PostageSocketContext.Provider>
    </>
  )
}

function usePostageSocket (messageCallback) {
  const { connectionStatus, subscribe, unsubscribe, subscribeToConversations } = useContext(PostageSocketContext)

  const subscribedFunctionRef = useRef()

  if (subscribedFunctionRef.current) {
    unsubscribe(subscribedFunctionRef.current)
  }
  subscribe(messageCallback)
  subscribedFunctionRef.current = messageCallback

  useEffect(() => {
    return () => {
      if (subscribedFunctionRef.current) {
        unsubscribe(subscribedFunctionRef.current)
      }
    }
  }, [unsubscribe])

  return { connectionStatus, subscribeToConversations }
}

export {
  usePostageSocket, PostageSocketProvider, PostageSocketContext
}
