/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect, useMemo } from 'react'
import ChatIcon from '@material-ui/icons/Chat'
import TrafficIcon from '@material-ui/icons/Traffic'
import LineChart from './LineChart'
import HeatMap from './HeatMap'
import GeoChart from './GeoChart'
import TagsChart from './TagsChart'
import ConversationSummary from './ConversationSummary'
import { Card, CardHeader, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FilterBar, convertDateRange } from 'cf-components/FilterBar/FilterBar'
import { ConversationExport, TrafficSourceExport } from 'api/reports'
import FunnelChart from './FunnelChart'
import DateFormat from 'dateformat'
import { track } from 'userpilot.js'
import { getSettings } from 'api/settings'
import AppPage from 'cf-components/AppPage'
import { downloadCSV } from 'helpers/csv'
import { useLocation } from 'react-router-dom'
import { Emitter, EVENT_TYPE } from 'emitter'
import { getSessionDateRange } from 'cf-components/FilterBar/CustomDateRanges'

const useStyles = makeStyles(theme => ({
  card: {
    height: 430,
    border: '1px solid rgba(142,90,226,0.5)'
  },
  cardContent: {
    height: 312,
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterDiv: {
    display: 'flex',
    marginBottom: 15,
    height: 47,
    justifyContent: 'space-between'
  },
  select: {
    width: 320
  },
  customDates: {
    marginLeft: 30,
    marginTop: -15
  },
  cardHeader: {
    paddingBottom: 0,
    marginBottom: 20
  },
  dashboard: {},
  charts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 20,
    padding: '0px 20px 20px 20px'
  },
  graphContent: {
    width: '100%'
  }
}))

const loaderStyles = {
  inlineSize: 'max-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 650,
  height: 360,
  iconSize: 30
}

function Dashboard () {
  const classes = useStyles()
  const [timezone, setTimezone] = useState()
  const [sourceFilter, setSourceFilter] = useState([])
  const location = useLocation()
  let startingDate = getSessionDateRange()
  startingDate = { ...startingDate, startDate: new Date(startingDate.startDate), endDate: new Date(startingDate.endDate) }
  const [dateRange, setDateRange] = useState(startingDate)
  const subheader = DateFormat(dateRange.startDate, 'mm/dd/yyyy') + ' - ' + DateFormat(dateRange.endDate, 'mm/dd/yyyy')

  useMemo(() => {
    if (location.search.includes('start_date') && location.search.includes('end_date')) {
      const startDate = location.search.substring(12, location.search.indexOf('&'))
      const endDate = location.search.split('=')[2]
      setDateRange({ startDate: new Date(startDate + ' 12:00:00'), endDate: new Date(endDate + ' 00:00:01') })
    }
  }, [location.search])

  function beginConversationExport () {
    Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: true, type: 'conversations' })
    const dateAttributes = convertDateRange(dateRange)
    const allAttributes = { ...dateAttributes, source_filter: sourceFilter } // attributes was missing the source filter without this
    ConversationExport({ attributes: allAttributes }).then(response => {
      const date = new Date().toString()
      const filename = 'CF_conversation_export_' + DateFormat(date, 'isoDate') + '.csv'
      downloadCSV(filename, response)
      track('Conversation Export Downloaded')
      Emitter.emit(EVENT_TYPE.CSV_DOWNLOAD, { csvIsDownloading: false, type: 'conversations' })
    })
  }
  function beginTrafficSourceExport () {
    const attributes = convertDateRange(dateRange)
    const allAttributes = { ...attributes, source_filter: sourceFilter } // attributes was missing the source filter without this
    TrafficSourceExport({ attributes: allAttributes }).then(response => {
      const date = new Date().toString()
      const filename = 'CF_traffice_source_export_' + DateFormat(date, 'isoDate') + '.csv'
      downloadCSV(filename, response)
      track('Traffic Source Export Downloaded')
    })
  }
  const actions = [
    { name: 'Conversation Report', action: () => beginConversationExport(), icon: ChatIcon },
    { name: 'Traffic Source Report', action: () => beginTrafficSourceExport(), icon: TrafficIcon }
  ]

  const filterOptions = {
    filterValues: [
      { value: 'Date', label: 'Filter by Date', toggle: false },
      { value: 'TrafficSource', label: 'Filter by Traffic Source', toggle: false }
    ],
    dateRange: dateRange,
    setDateRange: setDateRange,
    sourceFilter: sourceFilter,
    setSourceFilter: setSourceFilter
  }

  useEffect(() => {
    getSettings()
      .then(response => {
        const tz = response?.data?.attributes?.timezone
        if (tz) {
          setTimezone(tz)
        }
      })
  }, [])

  document.title = 'Conversations Dashboard | Signals'

  return (
    <AppPage
      title='Conversation Dashboard'
      padding={0}
    >
      <div className={classes.dashboard}>
        <FilterBar
          filterOptions={filterOptions}
          exportActions={actions}
          exportLabel='Export Conversations'
        />
        {dateRange &&
          <div className={classes.charts}>
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title='Visitor Funnel'
                  subheader={subheader}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.graphContent}>
                    <FunnelChart
                      dateValues={dateRange}
                      sourceFilter={sourceFilter}
                      loaderStyles={loaderStyles}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title='Key Metrics'
                  subheader={subheader}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.graphContent}>
                    <ConversationSummary
                      dateValues={dateRange}
                      sourceFilter={sourceFilter}
                      loaderStyles={loaderStyles}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title='Conversations Timeline'
                  subheader={subheader}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.graphContent}>
                    <LineChart
                      dateValues={dateRange}
                      sourceFilter={sourceFilter}
                      loaderStyles={loaderStyles}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title='Conversation Tags'
                  subheader={subheader}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.graphContent}>
                    <TagsChart
                      dateValues={dateRange}
                      sourceFilter={sourceFilter}
                      loaderStyles={loaderStyles}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title={timezone ? `Conversations by Time of Day (${timezone})` : 'Conversations by Time of Day'}
                  subheader={subheader}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.graphContent}>
                    <HeatMap
                      dateValues={dateRange}
                      sourceFilter={sourceFilter}
                      loaderStyles={loaderStyles}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            <div>
              <Card className={classes.card}>
                <CardHeader
                  title='Conversations by Geo-Location'
                  subheader={subheader}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardContent}>
                  <div className={classes.graphContent}>
                    <GeoChart
                      dateValues={dateRange}
                      sourceFilter={sourceFilter}
                      loaderStyles={loaderStyles}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>}
      </div>
    </AppPage>
  )
}

export default Dashboard
