import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from 'cf-components/Avatars'
import { ModalSection } from 'cf-components/Modal'
import { Contact } from 'classes/contacts'
import CustomField from 'classes/customFields'
import Form from 'classes/forms'
import dateFormat from 'dateformat'
import { Modal } from 'library/Modal'
import Loader from 'library/loading/Loader'
import { Icon, Tooltip } from 'library/materialUI'
import { dropdownFields as contFields } from 'pages/settings/Integrations/MappingRow'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  section: {
    margin: '10px'
  },
  sectionTitle: {
    color: '#00000080',
    fontSize: '12px',
    marginBottom: '10px'
  },
  bolded: {
    color: '#000000BF',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    overflow: 'hidden',
    fontSize: '16px'
  },
  subItem: {
    color: '#00000080',
    fontSize: '14px'
  }
}))

export interface FormSubmissionModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  submissionID: number
  contact?: any
  contactName?: string
}

export default function FormSubmissionModal ({ open, setOpen, submissionID, contact, contactName }: FormSubmissionModalProps) {
  const classes = useStyles()
  const [formID, setFormID] = useState<number>()
  const [submission, setSubmission] = useState<any>()
  const [loading, setLoading] = useState(true)
  const contactFields = contFields.filter((field) => (field.group === 'Contact Fields' || field.group === 'Company Fields'))
  const { data: customContactFields, isLoading: contactsLoading } = CustomField.loadAll(undefined, 'people')
  const [orderedFields, setOrderedFields] = useState<string[]>([])
  const [contactData, setContactData] = useState<any>(contact)

  function getFieldName (field: string) {
    const ctField = contactFields.find((contField) => contField.value === field)
    if (ctField) {
      return ctField.label
    }
    const cfField = customContactFields.list.find((contField) => contField.id === field)
    if (cfField) {
      return cfField.label
    }

    return field
  }
  useEffect(() => {
    if (formID) {
      Form.load(formID).then((f) => {
        const fields: string[] = []
        f.formLayout?.forEach((section) => {
          section.forEach((field) => {
            if (field?.fieldID) {
              if ((field.fieldID === 'title' && field.inputType === 'title') || field.fieldID === 'subtitle') {
                return
              }
              fields.push(field.fieldID)
            }
          })
          setLoading(false)
        })
        setOrderedFields(fields)
      })
    }
  }, [formID])

  useEffect(() => {
    if (submissionID) {
      Form.loadSubmission(submissionID)
        .then((resp: any) => {
          setFormID(resp.data.attributes.form_id)
          setSubmission({
            ...resp.data.attributes.data,
            createdTimestamp: resp.data.attributes.created_timestamp
          })
          if (!contact) {
            Contact.getById(resp.data.attributes.contact_id).then((c: any) => {
              setContactData(c.data)
            })
          }
        })
    } else {
      setFormID(undefined)
      setContactData(contact)
    }
    // eslint-disable-next-line
  }, [submissionID])

  return (
    <div>
      <Modal
        open={open}
        onHide={() => setOpen(false)}
        title='Submission'
        size='md'
        handleSave={() => undefined}
        cancelBtnText='Close'
        hideSaveButton
      >
        <ModalSection>
          <div style={{ margin: '10px', padding: '10px', backgroundColor: 'white' }}>
            {loading || contactsLoading ? (
              <Loader />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', rowGap: '10px', columnGap: '10px' }}>
                  <div className={classes.section}>
                    <div className={classes.sectionTitle}>
                      Contact
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {contactData && (
                        <div style={{ marginRight: '15px' }}>
                          <Avatar
                            contact={contactData}
                            size='md'
                          />
                        </div>
                      )}
                      <div>
                        <div className={classes.bolded}>
                          {contactName || (contactData?.attributes?.first_name + ' ' + contactData?.attributes?.last_name) || 'No Name Found'}
                        </div>
                        {contactData && (
                          <div className={classes.subItem}>
                            {contactData?.attributes?.email || 'No Email Provided'}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {contactData && (
                    <div className={classes.section}>
                      <div className={classes.sectionTitle}>
                        Company
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className={classes.bolded}>
                          {contactData?.attributes?.company_name || 'No Company Name Found'}
                        </div>
                        {contactData?.attributes?.companyIsIcp && (
                          <div style={{ marginLeft: '10px', marginTop: '7px' }}>
                            <Tooltip title='This company matches your Ideal Customer Profile (ICP)'>
                              <div>
                                <Icon icon='icp' />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div className={classes.subItem}>
                        {contactData?.attributes?.title || 'No Title Found'}
                      </div>
                    </div>
                  )}
                  <div className={classes.section}>
                    <div className={classes.sectionTitle}>
                      Date Submitted
                    </div>
                    <div className={classes.bolded}>
                      {dateFormat(submission?.createdTimestamp, 'mmm dd, yyyy') || 'No Date Found'}
                    </div>
                  </div>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', rowGap: '10px', columnGap: '10px', marginTop: '20px', justifyContent: 'space-between' }}>
                  {orderedFields?.map((fieldItem, index) => {
                    return (
                      <div key={index} style={{ margin: '10px', flexDirection: 'column' }}>
                        <div className={classes.sectionTitle}>
                          {getFieldName(fieldItem)}
                        </div>
                        <Tooltip title={submission[fieldItem] || ' '}>
                          <div className={classes.bolded}>
                            {submission[fieldItem]}
                          </div>
                        </Tooltip>
                      </div>
                    )
                  })}
                </div>
              </div>)}
          </div>
        </ModalSection>
      </Modal>
    </div>
  )
}
