/* eslint-disable @typescript-eslint/naming-convention */
import { Divider, Typography } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import Email from '@material-ui/icons/Email'
import Phone from '@material-ui/icons/Phone'
import { patchConversation, updateEmailCapture } from 'api/conversations'
import { setFollow } from 'api/follows'
import { getParticipantData } from 'api/participants'
import { getUserFollows } from 'api/user_follows'
import { Avatar } from 'cf-components/Avatars'
import { HiddenLink } from 'cf-components/Link'
import { CopyIconButton } from 'components/CopyIconButton'
import { usePostageSocket } from 'components/PostageSocket'
import SocialMediaWidgets from 'pages/people/ContactPage/SocialMediaWidgets'
import ContactSyncButtons from 'pages/people/ContactPage/integrations/ContactSyncButtons'
import { getContactIntegrationMappings } from 'pages/people/ContactPage/integrations/integration-helpers'
import { Dispatch, SetStateAction, useContext, useEffect, useReducer, useState } from 'react'
import ReactDOM from 'react-dom'
import { ConversationContext } from '../../ConversationContext'
import { SetLeadScore } from '../../LeadScore'
import { getDisplayName } from '../../conversationHelpers'
import { SidebarContext } from '../Sidebar'
import BaseCard from './BaseCard'
import SmallTextField from './SmallTextField'
import CustomField from 'classes/customFields'
import { Icon } from 'library/materialUI'
import { CustomFieldDisplay, CustomFieldEntries } from 'components/CustomFieldEntries'

const useStyles = makeStyles(theme => ({
  avatarDiv: {
    display: 'grid',
    gridTemplateColumns: '42px auto',
    gridGap: 10,
    height: 42
  },
  nameDiv: {
    color: theme.palette.primary.main,
    display: 'grid',
    alignItems: 'center'
  },
  name: {
    fontSize: '16px',
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  info: {
    paddingTop: 5
  },
  infoItemHolder: {
    padding: '6px 0 3px 0',
    display: 'flex',
    justifyContent: 'space-between'
  },
  infoItem: {
    color: '#717171',
    fontSize: '12px',
    marginRight: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  actionsDiv: {
    position: 'absolute',
    top: 11,
    right: 11,
    display: 'flex'
  },
  actionIcon: {
    height: 18,
    cursor: 'pointer'
  },
  followButton: {
    fontSize: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    maxHeight: 22
  },
  nameFields: {
    display: 'grid',
    gridTemplateColumns: '4fr 5fr',
    gridGap: 8,
    alignItems: 'center'
  },
  companyFields: {
    display: 'grid',
    gridTemplateColumns: '5fr 4fr',
    gridGap: 8,
    alignItems: 'center',
    marginTop: 12
  },
  field: {
    marginTop: 15
  },
  leadScoreField: {
    marginTop: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  socialIcon: {
    height: 22,
    cursor: 'pointer'
  },
  hiddenButton: {
    display: 'none',
    height: 0,
    width: 0,
    padding: 0
  },
  actionCtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .left': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .right': {
      display: 'flex',
      alignItems: 'center'
    },
    copyIconContainer: {
      cursor: 'pointer'
    }
  }
}))

function _createLeadScoreConvoEvent (score: number, conversationId: string) {
  const acceptedValues = [0, 1, 2, 3]

  if (!acceptedValues.includes(score)) {
    return
  }

  const data = {
    relationships: {
      lead_score: score
    }
  }

  patchConversation({ conversationID: conversationId, data: data })
}

interface ViewModeProps {
  conversation: any,
  details: any,
  save: (state: Record<string, any>, closeEditMode?: boolean) => void,
  setEditMode: Dispatch<SetStateAction<boolean>>,
  customFields?: CustomField[]
}

function ViewMode (props: ViewModeProps) {
  const classes = useStyles()
  const conversation = props.conversation
  const { company_name, title, email, phone, lead_score, first_name, last_name } = props.details.attributes
  const customFields = props.customFields || []

  const handleLeadScore = (score: number) => {
    const oldScore = props.details?.attributes?.lead_score
    if (oldScore === score) {
      return
    }

    props.save({ lead_score: score })
    // Besides just saving, patch the conversation so the lead score event shows up in chat.
    _createLeadScoreConvoEvent(score, conversation.id)
  }

  let firstName = 'Site Visitor'
  let lastName = ''
  if (first_name || last_name) {
    firstName = first_name
    lastName = last_name
  }

  let avatar = (
    <Avatar
      conversation={conversation}
      size='md'
      initials=''
    />
  )
  if (props.details?.contactID) {
    avatar = (
      <HiddenLink to={'/contacts/' + props.details.contactID}>
        {avatar}
      </HiddenLink>
    )
  }

  return (
    <div>
      <div className={classes.avatarDiv}>
        {avatar}
        <div className={classes.nameDiv}>
          <div className={classes.name}>
            <span onClick={() => props.setEditMode(true)}>{firstName}</span>
            &nbsp;
            <span onClick={() => props.setEditMode(true)}>{lastName}</span>
          </div>
          <div className={classes.title}>
            <span onClick={() => props.setEditMode(true)}>{title}{title && company_name ? ' - ' : ''}</span>
            <span onClick={() => props.setEditMode(true)}>{company_name}</span>
          </div>
        </div>
      </div>
      <div className={classes.info}>
        <div className={classes.infoItemHolder}>
          <div className={classes.infoItem} onClick={() => props.setEditMode(true)}><Email fontSize='small' /> {email || 'Add email'}</div>
          {email === '' ? <></> : <CopyIconButton content={email} />}
        </div>
        <Divider />
        <div className={classes.infoItemHolder}>
          <div className={classes.infoItem} onClick={() => props.setEditMode(true)}><Phone fontSize='small' /> {phone || 'Add phone'}</div>
          {phone === '' ? <></> : <CopyIconButton content={phone} />}
        </div>
        <Divider />
        {customFields.map((field, _) => (
          <div key={field.slug}>
            <Tooltip title={props?.details?.attributes?.customFields?.[field.slug] ? field.label : ''}>
              <div className={classes.infoItemHolder}>
                <div className={classes.infoItem} onClick={() => props.setEditMode(true)}>
                  <Icon icon='editSquare' />
                  {props?.details?.attributes?.customFields?.[field.slug] ? CustomFieldDisplay({ customField: field, value: props?.details?.attributes?.customFields?.[field.slug] }) : 'Add ' + field.label}
                </div>
              </div>
            </Tooltip>
            <Divider />
          </div>
        ))}
      </div>
      <div className={classes.leadScoreField}>
        <SocialMediaWidgets
          socialMediaHandles={props.details.attributes}
          onSave={(media, linkHandle) => {
            props.save({ ...props.details.attributes, [media]: linkHandle })
          }}
          editMode={false}
        />
        <SetLeadScore
          score={lead_score}
          handleChange={(score: number) => handleLeadScore(score)}
        />
      </div>
    </div>
  )
}

const reducer = (state: Record<string, any>, action: Record<string, any>): Record<string, any> => {
  if (action.custom) {
    return {
      ...state,
      custom_fields: {
        ...state.custom_fields,
        [action.property]: action.value
      },
      changes: {
        ...state.changes,
        custom_fields: {
          ...state.changes.custom_fields,
          [action.property]: true
        }
      }
    }
  }
  return {
    ...state,
    [action.property]: action.value,
    changes: {
      ...state.changes,
      [action.property]: true
    }
  }
}

interface EditModeProps {
  details: any,
  conversation: any,
  save: (state: Record<string, any>, closeEditMode?: boolean) => void,
  setEditMode: Dispatch<SetStateAction<boolean>>,
  editMode: boolean,
  customFields?: CustomField[]
}

function EditMode (props: EditModeProps) {
  const classes = useStyles()
  const { contact } = useContext(ConversationContext)
  const [state, dispatch] = useReducer(reducer, { ...contact?.attributes, changes: {} })
  // const [participantID] = useState(props.details.participantID)
  const { editMode, setEditMode, conversation, save } = props
  const { editMode: listEditMode } = useContext(SidebarContext)
  const customFields = props.customFields || []

  const _leadScoreChanged = () => {
    const oldLeadScore = props?.details?.attributes?.lead_score

    if (typeof (oldLeadScore) === 'undefined') {
      return false
    }

    if (state.lead_score !== oldLeadScore) {
      return true
    }
  }

  const handleSave = () => {
    const saveData: Record<string, any> = {}
    Object.keys(state.changes).forEach((k) => {
      saveData[k] = state[k]
    })
    if (state.changes.custom_fields) {
      saveData.custom_fields = state.custom_fields
    }
    save(saveData, true)
    // Update email capture here
    if (saveData?.email) {
      updateEmailCapture(conversation.id)
    }
    // In the future, we probably would like to update the conversation
    // in the backend
    if (_leadScoreChanged()) {
      _createLeadScoreConvoEvent(state?.lead_score, conversation.id)
    }
  }

  const onSubmit = (event: any) => {
    handleSave()
    event.preventDefault()
  }

  const basicProps = {
    state,
    dispatch,
    autoFocusID: editMode
  }

  return (
    <ClickAwayListener onClickAway={handleSave} mouseEvent='onMouseUp'>
      <form
        onSubmit={onSubmit}
      >
        <div>
          {listEditMode ? <></> : (
            <div className={classes.actionsDiv}>
              <Tooltip title='Cancel'>
                <CancelIcon
                  color='primary'
                  className={classes.actionIcon}
                  onClick={() => setEditMode(false)}
                />
              </Tooltip>
            </div>
          )}
          <div className={classes.avatarDiv}>
            <Avatar
              conversation={conversation}
              size='md'
              initials=''
            />
            <div className={classes.nameFields}>
              <SmallTextField
                {...basicProps}
                propertyID='first_name'
                label='First Name'
              />
              <SmallTextField
                {...basicProps}
                propertyID='last_name'
                label='Last Name'
              />
            </div>
          </div>
          <div className={classes.field}>
            <SmallTextField
              {...basicProps}
              propertyID='email'
              label='Email'
            />
          </div>
          <div className={classes.field}>
            <SmallTextField
              {...basicProps}
              propertyID='phone'
              label='Phone'
            />
          </div>
          <div className={classes.companyFields}>
            <SmallTextField
              {...basicProps}
              propertyID='title'
              label='Title'
            />
            <SmallTextField
              {...basicProps}
              propertyID='company_name'
              label='Company'
            />
          </div>
          <Typography variant='body2' style={{ marginTop: 12 }}>Custom Fields</Typography>
          <Divider />
          {customFields.map((field) => {
            return (
              <div key={field.slug} className={classes.field}>
                <CustomFieldEntries
                  customField={field}
                  onChange={(value: any) => dispatch({ property: field.slug, value, custom: true })}
                  value={state?.custom_fields?.[field.slug] || ''}
                />
              </div>
            )
          })}
          <div className={classes.leadScoreField}>
            <SocialMediaWidgets
              socialMediaHandles={{
                linkedin_handle: state.linkedin_handle,
                twitter_handle: state.twitter_handle,
                instagram_handle: state.instagram_handle,
                facebook_handle: state.facebook_handle
              }}
              onSave={(media, linkHandle) => dispatch({ property: media, value: linkHandle })}
              editMode
            />
            <SetLeadScore
              score={state.lead_score}
              handleChange={(score: number) => dispatch({ property: 'lead_score', value: score })}
            />
          </div>
        </div>
        <button className={classes.hiddenButton} type='submit'>Submit</button>
      </form>
    </ClickAwayListener>
  )
}

interface CardContentsProps {
  details: any,
  conversation: any,
  editMode: boolean,
  setEditMode: Dispatch<SetStateAction<boolean>>,
  save: (state: Record<string, any>) => void,
  customFields?: CustomField[]
}

function CardContents (props: CardContentsProps) {
  const { details, conversation, editMode, setEditMode, save, customFields } = props
  if (editMode) {
    return (
      <EditMode
        details={details}
        conversation={conversation}
        save={save}
        setEditMode={setEditMode}
        editMode={editMode}
        customFields={customFields}
      />
    )
  } else {
    return (
      <ViewMode
        setEditMode={setEditMode}
        conversation={conversation}
        details={details}
        save={save}
        customFields={customFields}
      />
    )
  }
}

interface ContactActionsProps {
  followStatus: boolean,
  setFollowStatus: () => void,
  editMode: boolean,
  setEditMode: Dispatch<SetStateAction<boolean>>,
  classes: Record<string, any>,
  contact: any,
  integrationObjectMappings: any,
  refreshContactIntegrationMappings: (contactID: number) => void
}

function ContactActions (props: ContactActionsProps) {
  const { followStatus, setFollowStatus, editMode, setEditMode, classes, contact, integrationObjectMappings, refreshContactIntegrationMappings } = props
  const { editMode: listEditMode } = useContext(SidebarContext)
  let LeftCtn = <></>
  let SyncButtons = <></>
  if (contact?.type === 'contacts') {
    const fabTxt = followStatus ? 'Following' : 'Follow'
    const followTxt = followStatus ? 'Unfollow' : 'Follow'
    const leftCtnStyle: Record<string, any> = {}
    if (editMode || listEditMode) {
      leftCtnStyle.display = 'none'
    }
    SyncButtons = (
      <ContactSyncButtons
        contact={contact}
        integrationObjectMappings={integrationObjectMappings}
        refreshContactIntegrationMappings={refreshContactIntegrationMappings}
      />
    )

    LeftCtn = (
      <div className='left' style={leftCtnStyle}>
        <Tooltip title={followTxt}>
          <Fab
            color='primary'
            variant='extended'
            size='small'
            className={classes.followButton}
            onClick={setFollowStatus}
          >
            {fabTxt}
          </Fab>
        </Tooltip>
      </div>
    )
  } // to fix the colapsing of the title text, the inigration needs to not be a fixed width
  return (
    <div className={classes.actionCtn}>
      {LeftCtn}
      {!editMode && (
        <div className='right'>
          {SyncButtons}
          <Tooltip title='Edit contact'>
            <EditIcon
              color='primary'
              className={classes.actionIcon}
              onClick={() => setEditMode(true)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

const _sanitizeState = (state: Record<string, any>) => {
  return state
}

interface ContactCardProps {
  id: string
}

export default function ContactCard (props: ContactCardProps) {
  const classes = useStyles()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [followDict, setFollowDict] = useState<Record<string, any>>({})
  const { conversation, contact, setContact, integrationObjectMappings, setIntegrationObjectMappings, setRefreshContact, saveContact } = useContext(ConversationContext)
  const contactID = conversation?.relationships.for_contact.data.id
  const participantID = conversation?.relationships.for_participant.data.id
  const { data: customFields, isLoading } = CustomField.loadAll({ searchParams: { queryParams: { can_edit: 'true' } } }, 'people')

  const refreshContactIntegrationMappings = (contactID: number) =>
    getContactIntegrationMappings(contactID).then((response) => {
      setIntegrationObjectMappings(response)
    })

  useEffect(() => {
    let mounted = true
    getUserFollows().then(response => {
      const dict = Object.assign({}, ...response.data.map((x: any) => ({ [x.id]: x.attributes.followed })))
      if (mounted) {
        setFollowDict(dict)
      }
    })
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true
    if (participantID) {
      setEditMode(false)
      getParticipantData({ participantID }).then(response => {
        if (mounted) {
          ReactDOM.unstable_batchedUpdates(() => {
            setContact(response.data)
            if (contactID) {
              refreshContactIntegrationMappings(response.data.id)
            } else {
              setIntegrationObjectMappings({})
            }
          })
        }
      })
    }
    return () => {
      mounted = false
    }
    // eslint-disable-next-line
  }, [participantID, contactID])

  usePostageSocket((msg: Record<string, any>) => {
    if (msg.type === 'conversation_events') {
      if (['capture', 'lead_score', 'chat'].includes(msg.attributes.kind) && msg.relationships.conversation.data.id === conversation?.id) {
        setRefreshContact(true)
      }
    }
  })

  const name = contact ? getDisplayName(contact) : ''
  const companyName = contact?.attributes.company_name || contact?.attributes.domain || ''

  const details = {
    attributes: {
      first_name: contact?.attributes.first_name || '',
      last_name: contact?.attributes.last_name || '',
      lead_score: contact?.attributes.lead_score,
      lead_source: contact?.attributes.lead_source || '',
      company_name: companyName,
      title: contact?.attributes.title || '',
      email: contact?.attributes.email || '',
      phone: contact?.attributes.phone || '',
      linkedin_handle: contact?.attributes.linkedin_handle || '',
      twitter_handle: contact?.attributes.twitter_handle || '',
      instagram_handle: contact?.attributes.instagram_handle || '',
      facebook_handle: contact?.attributes.facebook_handle || '',
      customFields: contact?.attributes.custom_fields || {}
    },
    name: name,
    contactID: contactID,
    participantID: participantID
  }

  // This is done to ensure we are always referring to the correct contact
  useEffect(() => {
    if (contact) {
      const changed = (contact.type === 'contacts' && contactID !== contact.id) || (contact.type === 'participants' && contact.id !== participantID)
      if (changed) {
        getParticipantData({ participantID }).then(response => {
          ReactDOM.unstable_batchedUpdates(() => {
            setContact(response.data)
            if (response.data.type === 'contacts') {
              refreshContactIntegrationMappings(response.data.id)
            } else {
              setIntegrationObjectMappings({})
            }
          })
        })
      }
    }
    // eslint-disable-next-line
  }, [contact, contactID, participantID])

  const saveChanges = (state: Record<string, any>, closeEditMode?: boolean) => {
    /**
     * The purpose of this is that when the participant is updated the updated_timestamp is not changed in the first response.
     * So we set it to now for the integrations to correctly be out of date
     */
    // const newUpdatedTimestamp = dateFormat(new Date(), 'yyyy-mm-dd\'T\'HH:MM:ss.lo', true).replace(/..$/, ':$&').replace(/[+-]..:..$/, '000$&')
    // state = _sanitizeState(state)
    // saveParticipantData({ state, chatServiceUrl, participantID: saveParticipantID })
    //   .then(response => {
    //     refreshConversation()
    //     if (saveParticipantID === participantID) {
    //       response.data.attributes.updated_timestamp = newUpdatedTimestamp
    //       setContact(response.data)
    //     }
    state = _sanitizeState(state)
    saveContact(state)
    if (closeEditMode) {
      setEditMode(false)
    }
    // })
  }

  const setFollowStatus = () => {
    const followed = !followDict[contactID]
    const person_ids = [contactID]
    // Changes state in database
    setFollow({ followed, person_ids })
    // Changes follow dict
    setFollowDict({ ...followDict, [contactID]: followed })
  }

  return (
    <BaseCard
      title='Contact Info'
      id={props.id}
      allowOverflow
      actions={(
        <ContactActions
          classes={classes}
          followStatus={followDict[contactID]}
          setFollowStatus={setFollowStatus}
          editMode={editMode}
          setEditMode={setEditMode}
          contact={contact}
          integrationObjectMappings={integrationObjectMappings}
          refreshContactIntegrationMappings={refreshContactIntegrationMappings}
        />
      )}
    >
      <div style={{ paddingTop: 5, cursor: 'default' }}>
        <CardContents
          editMode={editMode}
          details={details}
          conversation={conversation}
          save={saveChanges}
          setEditMode={setEditMode}
          customFields={isLoading ? [] : customFields.list}
        />
      </div>
    </BaseCard>
  )
}
