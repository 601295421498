import Typography from '@material-ui/core/Typography'
import { Card, CardContent, CardHeader, Button, Chip, Radio, Select, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import TimezonePicker from 'components/TimezonePicker'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  selectedCard: {
    border: '1px solid #8E5AE2',
    backgroundColor: 'rgba(142, 90, 226, 0.05)',
    minHeight: 60,
    marginBottom: 20
  },
  card: {
    border: '1px solid rgba(142, 90, 226, 0.4)',
    minHeight: 60,
    cursor: 'pointer',
    borderRadius: 10,
    backgroundColor: 'white',
    marginBottom: 20
  },
  manualCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15
  },
  timezoneDiv: {
    marginTop: 20
  },
  timezonePicker: {
    marginTop: 10
  },
  hidden: {
    display: 'none'
  },
  cardInterior: {
    marginLeft: 35,
    marginTop: 45
  },
  manual: {
    float: 'left'
  },
  manualToggle: {
    float: 'right',
    marginRight: 40
  },
  radio: {
    margin: 0,
    height: 30
  },
  scheduled: {
    float: 'left',
    width: '100%'
  },
  switch: {
    marginLeft: 5
  },
  cardTitle: {
    fontSize: '17px'
  },
  cardRoot: {
    padding: '8px 24px'
  },
  selectInput: {
    padding: 8,
    paddingLeft: 10,
    borderRadius: 30
  },
  inputRoot: {
    borderRadius: 30
  },
  select: {
    marginLeft: 15,
    marginRight: 15,
    width: 180
  },
  urlLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

function TimePicker (props) {
  const classes = useStyles()
  const handleTimeChange = props.handler

  const times = [
    '00:00:00', '00:30:00', '01:00:00', '01:30:00', '02:00:00', '02:30:00',
    '03:00:00', '03:30:00', '04:00:00', '04:30:00', '05:00:00', '05:30:00',
    '06:00:00', '06:30:00', '07:00:00', '07:30:00', '08:00:00', '08:30:00',
    '09:00:00', '09:30:00', '10:00:00', '10:30:00', '11:00:00', '11:30:00',
    '12:00:00', '12:30:00', '13:00:00', '13:30:00', '14:00:00', '14:30:00',
    '15:00:00', '15:30:00', '16:00:00', '16:30:00', '17:00:00', '17:30:00',
    '18:00:00', '18:30:00', '19:00:00', '19:30:00', '20:00:00', '20:30:00',
    '21:00:00', '21:30:00', '22:00:00', '22:30:00', '23:00:00', '23:30:00',
    '23:59:59'
  ]

  // Format time to 12-hour format
  const formatTime = (time) => {
    const [hours, minutes] = time.split(':')
    const period = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12
    return `${formattedHours}:${minutes} ${period}`
  }

  // If this is the start_time timepicker, show all times except 23:59:59
  // If this is the end_time timepicker, show all times after the start_time
  const options = times.map(time => (
    ((props.name === 'start_time' && time !== '23:59:59') || time > props.startValue) &&
      <option value={time}>{formatTime(time)}</option>
  ))

  return (
    <Select
      native
      value={props.value}
      variant='outlined'
      name={props.name}
      className={classes.select}
      onChange={handleTimeChange}
      margin='dense'
    >
      {options}
    </Select>
  )
}

function HourSelector (props) {
  const classes = useStyles()

  function handleChange (event) {
    const name = event.target.name
    const value = event.target.value
    props.handler({
      type: 'updateTime',
      index: props.index,
      name: name,
      value: value
    })
  }

  // Used when start time is changed to a time after end time
  // to set the end time to the soonest next half hour
  const soonestNextEndTime = (time) => {
    if (time === '23:30:00') return '23:59:59'

    const [hours, minutes, seconds] = time.split(':').map(Number)
    const date = new Date()
    date.setHours(hours, minutes + 30, seconds)

    const newHours = String(date.getHours()).padStart(2, '0')
    const newMinutes = String(date.getMinutes()).padStart(2, '0')
    const newSeconds = String(date.getSeconds()).padStart(2, '0')

    return `${newHours}:${newMinutes}:${newSeconds}`
  }

  useEffect(() => {
    // Reset end time if it's not greater than start time
    if (props.values.end_time <= props.values.start_time) {
      handleChange({
        target: {
          name: 'end_time',
          value: soonestNextEndTime(props.values.start_time)
        }
      })
    }
  })

  return (
    <div>
      <Select
        native
        value={props.values.days}
        variant='outlined'
        name='days'
        margin='dense'
        onChange={handleChange}
        className={classes.select}
      >
        <option value='Everyday'>Everyday</option>
        <option value='Weekdays'>Weekdays</option>
        <option value='Weekends'>Weekends</option>
        <option value='Mondays'>Mondays</option>
        <option value='Tuesdays'>Tuesdays</option>
        <option value='Wednesdays'>Wednesdays</option>
        <option value='Thursdays'>Thursdays</option>
        <option value='Fridays'>Fridays</option>
        <option value='Saturdays'>Saturdays</option>
        <option value='Sundays'>Sundays</option>
      </Select>
      from
      <TimePicker
        name='start_time'
        value={props.values.start_time}
        handler={handleChange}
      />
      to
      <TimePicker
        name='end_time'
        value={props.values.end_time}
        startValue={props.values.start_time}
        handler={handleChange}
      />
      <IconButton
        onClick={() =>
          props.handler({ type: 'removeTime', index: props.index })}
      >
        <HighlightOffIcon style={{ fill: '#8e5ae2' }} />
      </IconButton>
    </div>
  )
}

function AvailabilityCard (props) {
  const classes = useStyles()
  const { state, dispatch } = props

  function setScheduled () {
    dispatch({ type: 'changeAvailabilityType', value: 'scheduled' })
  }

  function setManual () {
    dispatch({ type: 'changeAvailabilityType', value: 'manual' })
  }

  const scheduled = props.scheduled
  const availabilities = state.availabilities
  const online = state.online
  const autoToggle = state.offline_when_unavailable ? state.offline_when_unavailable : state.offlineWhenUnavailable
  const timezone = state.timezone
  const attempt_save = state.attempt_save ? state.attempt_save : props.saveAttempt
  const mobile_disabled = state.mobile_disabled

  return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        classes={{ title: classes.cardTitle }}
        title={props.title}
      />
      <CardContent classes={{ root: classes.cardRoot }}>
        <Card className={classes.manualCard} classes={{ root: scheduled ? classes.card : classes.selectedCard }} onClick={setManual}>
          <div className={classes.manual}>
            <FormControlLabel
              checked={!scheduled}
              control={<Radio color='primary' />}
              label='Manually set availability'
              className={classes.radio}
            />
          </div>
          <div
            className={scheduled ? classes.hidden : classes.manualToggle}
          >
            <Chip
              label={online ? 'Online' : 'Offline'}
              color={online ? 'primary' : 'default'}
            />
            <Checkbox
              color='primary'
              checked={online}
              name='online'
              onClick={() => dispatch({ type: 'toggleSetting', name: 'online' })}
            />
          </div>
        </Card>
        <Card classes={{ root: scheduled ? classes.selectedCard : classes.card }} onClick={setScheduled}>
          <CardContent>
            <div className={classes.scheduled}>
              <FormControlLabel
                checked={scheduled}
                control={<Radio color='primary' />}
                label='Set availability schedule'
                className={classes.radio}
                onClick={setScheduled}
              />
            </div>
            <div className={scheduled ? classes.cardInterior : classes.hidden}>
              <Typography variant='subtitle1'>Available for chat during these hours:</Typography>
              <div style={{ marginTop: 15 }}>
                {(availabilities.length < 1 && attempt_save) &&
                  <div style={{ color: 'red' }}>
                    Please set schedule time before you can save
                  </div>}
                {availabilities.map((availability, index) => (
                  <HourSelector
                    key={index}
                    values={availability}
                    index={index}
                    handler={dispatch}
                  />
                ))}
                <Button
                  onClick={() => dispatch({ type: 'addTime' })}
                  variant='outlined'
                  color='primary'
                  style={{ marginTop: 15 }}
                >
                  {availabilities.length < 1 ? '+ Add a time' : '+ Add another time'}
                </Button>
                <div className={classes.timezoneDiv}>
                  <Typography variant='subtitle1'>Timezone:</Typography>
                  <div className={classes.timezonePicker}>
                    <TimezonePicker value={timezone} handler={dispatch} />
                  </div>
                </div>
              </div>
              <br />
              <Divider />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoToggle}
                    onChange={() => dispatch({ type: 'toggleSetting', name: 'offline_when_unavailable' })}
                    name='autoToggle'
                    color='primary'
                  />
                }
                label='Go offline when all users are set to unavailable'
              />
            </div>
          </CardContent>
        </Card>
      </CardContent>
      {props.teamPage ? <></> : (
        <>
          <CardContent style={{ padding: '0px 0px 25px 25px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Checkbox
                  color='primary'
                  checked={mobile_disabled}
                  name='mobile_disabled'
                  onClick={() => dispatch({ type: 'toggleSetting', name: 'mobile_disabled' })}
                />
              </div>
              <div style={{ marginTop: '9px' }}>
                Disable chat prompt for visitors on mobile
              </div>
            </div>
          </CardContent>
          <CardContent style={{ padding: '0px 0px 25px 25px' }}>
            <div style={{ color: '#8D8D8D' }}>
              Note: This may conflict with your team availability. The availability of different teams can be found on the&nbsp;
              <a target='_blank' rel='noopener noreferrer' href='/#/user_management/teams' className={classes.urlLink}>
                Teams Page.
              </a>
            </div>
          </CardContent>
        </>
      )}
    </Card>
  )
}

export default AvailabilityCard
