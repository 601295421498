import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { InputAdornment } from '@material-ui/core';
import CancelRounded from '@material-ui/icons/CancelRounded';
import React, { useState } from 'react';
import { postURL, updateURL } from 'api/high-intent';

export default function HighIntentURL (props: any) {
  const [url, setURL] = useState(props.url)
  const id = props.id
  const isNew = props.isNew
  const [focus, setFocus] = useState(isNew)
  const [stateChanged, setStateChanged] = useState(false)

  const useStyles = makeStyles(theme => ({
    categoryURLEntry: {
      marginRight: theme.spacing(1),
      marginBottom: 5,
      width: '100%'
    },
    urlDeleteIcon: {
      color: '#985CE6',
      cursor: 'default',
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }))

  function addURL (pageURL: string) {
    postURL(categoryID, pageURL)
      .then(() => {
        props.loadURLs()
      })
  }

  function changeURL (id: number, pageURL: string) {
    updateURL(id, categoryID, pageURL)
      .then(() => setStateChanged(false))
  }

  const categoryID = props.categoryID
  const categorySubtitle = props.subtitle
  const removeURL = props.removeURL
  const classes = useStyles()

  return (
    <TextField
      inputRef={urlInput => focus && urlInput && urlInput.focus()}
      id={id}
      label={categorySubtitle}
      className={classes.categoryURLEntry}
      InputLabelProps={{ shrink: true }}
      placeholder='Paste URL Here'
      margin='dense'
      variant='outlined'
      value={url}
      InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          <CancelRounded
            className={classes.urlDeleteIcon}
            onClick={() => {
              removeURL(id)
            }}
          />
        </InputAdornment>
      )
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          setFocus(false)
          props.moveOn()
        }
      }}
      onChange={e => {
        setURL(e.target.value)
        setStateChanged(true)
      }}
      onBlur={() => {
        if (url !== '' && stateChanged) {
          if (isNew) {
            addURL(url)
          } else {
            changeURL(id, url)
          }
        }
      }}
    />
  )
}
