import React, { useEffect, useState, useRef } from 'react'
import BounceLoader from 'react-spinners/RiseLoader'
import theme from 'theme'
import { createPieChart } from './createPieChart'

const loaderStyles = {
  inlineSize: 'max-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: 242,
  iconSize: 30,
  alignItems: 'center'
}

function PieChart (props) {
  const chartRef = useRef(null)
  const data = props.data
  const [visibility, setVisibility] = useState('hidden')
  const size = props.size || 242
  const orderVertically = props.orderVertically || false

  useEffect(() => {
    if (data) {
      const id = props.id
      const chart = createPieChart({
        data,
        id,
        setVisibility,
        orderVertically,
        legendHeight: props.legendHeight,
        eventFunc: props.eventFunc
      })
      chartRef.current = chart
    }
  }, [data, props.id, props.legendHeight, props.eventFunc, orderVertically])

  return (
    <>
      <div
        style={{
          display: visibility === 'visible' ? 'none' : 'flex',
          ...loaderStyles
        }}
      >
        <BounceLoader
          color={theme.palette.primary.main}
        />
      </div>
      <div style={{ visibility: visibility, height: size }}>
        <div id={props.id} style={{ width: '100%', height: '100%' }} />
      </div>
    </>
  )
}

export default PieChart
