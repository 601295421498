import {
  useRef,
  useCallback
} from 'react'
import useBasicWebsocket from 'pages/chat/use-chat/useBasicWebsocket'
import useInterval from 'use-interval'

function useChat (settings) {
  const { url, token, messageCallback } = settings
  const messageCallbackRef = useRef(null)
  messageCallbackRef.current = messageCallback
  const pingRecievedRef = useRef(null)
  const isReconnectRef = useRef(false)

  const {
    sendMessage,
    readyState,
    reconnect
  } = useBasicWebsocket({
    url,
    onMessage: ({ event }) => {
      messageCallbackRef.current(event)
      try {
        const parsed = JSON.parse(event.data)
        if (parsed.type === 'ping') {
          pingRecievedRef.current = true
        }
      } catch (e) {
        console.log('received invalid json:', event.data)
      }
    },
    reconnectDelayBackoff: 1.25,
    maxReconnectDelay: 10000,
    shouldReconnect: () => {
      isReconnectRef.current = true
      return true
    },
    readyToConnect: Boolean(token),
    onOpen: ({ event, socket }) => {
      if (event.target !== socket) {
        event.target.close()
        return
      }
      if (token) {
        socket.send(
          JSON.stringify({
            type: 'id',
            token,
            is_reconnect: isReconnectRef.current
          })
        )
      }
    }
  })

  const connectionStatus = readyState

  const subscribeToConversations = useCallback((conversationIDs) => {
    setTimeout(() => {
      sendMessage({ type: 'subscribe', conversation_ids: conversationIDs })
    }, 1000)
  }, [sendMessage])

  useInterval(() => {
    if (readyState === WebSocket.OPEN) {
      sendMessage({ type: 'presence' })
      pingRecievedRef.current = null
      setTimeout(function () {
        if (!pingRecievedRef.current) {
          reconnect()
        }
      }, 4000)
    }
  }, 5000)

  return {
    connectionStatus,
    subscribeToConversations
  }
}

export default useChat
