import React, { useReducer, useMemo, useEffect } from 'react'
import { getFilteredRows } from './filter-bar/filterHelpers'
import FilterBar from './filter-bar/FilterBar'
import { CardList } from './CardList'
import { useDebounce } from 'use-debounce'
import { debounceDelay } from 'library/constants'
import { Actions } from './ActionsComponent'
import { Fields } from './CardContents'
import { makeStyles } from '@material-ui/core/styles'
export type { Fields, Actions, ColumnHeader }

const useStyles = makeStyles(theme => ({
  dataItem: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'poppins',
    minWidth: 100
  }
}))

interface TableProps<Class> {
  rows: Class[]
  fields: Fields<Class>
  columnStyles: string
  actions?: Actions<Class>
  isLoading?: boolean
  sortColumn?: string
  sortAscending?: boolean
  filterOptions?: any
  handleExport?: any
  hoverPopover?: any
  actionPopRef?: any
}

interface ControlledTableProps<Class> {
  fields: Fields<Class>
  columnStyles: string
  actions?: Actions<Class>
  ObjectClass: any
}

interface TableState {
  sortAscending: boolean
  sortCriteria: string
  search: string
  rowsPerPage: number
  pageNumber: number
}

interface ColumnHeader<Class = any> {
  label: string // The label for the column header
  column: keyof Class // The key in the object that the column represents
  tooltip: string | '' // Tooltip for the column header
  minWidth?: number // Minimum width of the column before it stops a potentially starts horizontal scroll
  emptyState?: string // Placeholder for empty cells default is '---'
  subColumn?: string // Used as a subheader to the column info
}

interface DataItemProps {
  value?: string | number | null
  emptyState?: string | number
  minWidth?: number
}
export const SimpleDataItem = ({ value, emptyState = '---', minWidth = 100 }: DataItemProps): JSX.Element => {
  const classes = useStyles()
  return (
    <div style={{ minWidth: minWidth }} className={classes.dataItem}>
      {value || emptyState}
    </div>
  )
}

const getInitialState = (fieldNames: string[], sortColumn: string | undefined, sortAscending: boolean): any => {
  const sortCriteria = sortColumn || fieldNames[0]
  return {
    sortAscending: sortAscending,
    sortCriteria: sortCriteria,
    search: '',
    rowsPerPage: 10,
    pageNumber: 1
  }
}

const reducer = (state: TableState, action: { type: string, value?: any }): TableState => {
  switch (action.type) {
    case 'sort':
      return {
        ...state,
        sortAscending: action.value === state.sortCriteria ? !state.sortAscending : state.sortAscending,
        sortCriteria: action.value,
        pageNumber: 1
      }
    case 'search':
      return {
        ...state,
        search: action.value
      }
    case 'setRowsPerPage':
      return {
        ...state,
        rowsPerPage: action.value
      }
    case 'incrementPageNumber':
      return { ...state, pageNumber: state.pageNumber + 1 }
    case 'decrementPageNumber':
      return { ...state, pageNumber: state.pageNumber - 1 }
    case 'resetPage':
      return { ...state, pageNumber: 1 }
    default:
      break
  }
  return state
}

function toCapitalizedWords (name: string): string {
  if (!name) {
    return ''
  }
  const words = name.match(/[A-Za-z][a-z]*/g) || []

  return words.map(capitalize).join(' ')
}

function capitalize (word: string): string {
  return word.charAt(0).toUpperCase() + word.substring(1)
}

export function Table<Class = any> (props: TableProps<Class>): JSX.Element {
  const rows = props.rows
  const fieldNames = props.fields.map(f => f.name as string)
  const initialState = getInitialState(fieldNames, props.sortColumn, props.sortAscending === undefined ? true : props.sortAscending)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [debouncedSearch] = useDebounce(state.search, debounceDelay)
  const filteredRows = useMemo(() => rows ? getFilteredRows(rows, { ...state, search: debouncedSearch }) : [], [debouncedSearch, rows, state])
  const fields = useMemo(() => props.fields.map(f => ({ ...f, label: f.label || toCapitalizedWords(f.name as string) })), [props.fields])

  useEffect(() => {
    dispatch({ type: 'resetPage' })
  }, [debouncedSearch])

  const columnStyles = props.actions ? props.columnStyles + ' 50px' : props.columnStyles

  return (
    <div style={{ padding: '10px 20px' }}>
      <FilterBar
        state={state}
        dispatch={dispatch}
        fields={fields}
        filterOptions={props.filterOptions}
        handleExport={props.handleExport}
      />
      <CardList
        rows={filteredRows}
        columnStyles={columnStyles}
        fields={fields}
        state={state}
        dispatch={dispatch}
        actions={props.actions}
        isLoading={props.isLoading}
        actionPopRef={props.actionPopRef}
        hoverPopover={props.hoverPopover}
      />
    </div>
  )
}

export function ControlledTable<Class = any> (props: ControlledTableProps<Class>): JSX.Element {
  const { data, isLoading } = props.ObjectClass.loadAll()
  const fieldNames = props.fields.map(f => f.name as string)
  const initialState = getInitialState(fieldNames, undefined, true)
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <div>
      <CardList
        rows={data.list}
        columnStyles={props.columnStyles}
        fields={props.fields}
        state={state}
        dispatch={dispatch}
        actions={props.actions}
        isLoading={isLoading}
      />
    </div>
  )
}
