import { forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link, useRouteMatch } from 'react-router-dom'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import AccessControl from 'components/AccessControl'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 230,
    height: '100vh',
    overflow: 'auto',
    fontFamily: 'Poppins, sans serif'
  },
  nested: {
    paddingLeft: theme.spacing(5)
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemSelected: {
    fontSize: '15px',
    color: '#2A2A2A',
    fontWeight: 600
  },
  listItemUnselected: {
    fontSize: '15px',
    color: '#7A7A7A'
  }
}))

const ConversationsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/conversations' {...props} />
))

const AgentsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/agent_report' {...props} />
))

const VisitorsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/reports/visitors' {...props} />
))

function ReportsNavBar () {
  const classes = useStyles()
  const reportNameMatch = useRouteMatch('/reports/:reportName')
  const reportName = reportNameMatch?.params?.reportName

  return (
    <div>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        disablePadding
        subheader={
          <ListSubheader component='div' id='nested-list-subheader' disableSticky>
            Reporting
          </ListSubheader>
        }
        className={classes.root}
      >
        <ListItem>
          <div className={classes.itemHeader}>
            Reports
          </div>
        </ListItem>
        <List component='div' disablePadding>
          <AccessControl requiredPermission='manager'>
            <ListItem
              button
              component={ConversationsLink}
              className={classes.nested}
            >
              <div
                className={reportName === 'conversations' ? classes.listItemSelected : classes.listItemUnselected}
              >
                Conversations
              </div>
            </ListItem>
          </AccessControl>
          <AccessControl requiredPermission='manager'>
            <ListItem
              button
              component={VisitorsLink}
              className={classes.nested}
            >
              <div
                className={reportName === 'visitors' ? classes.listItemSelected : classes.listItemUnselected}
              >
                Visitors
              </div>
            </ListItem>
          </AccessControl>
          <ListItem
            button
            component={AgentsLink}
            className={classes.nested}
          >
            <div
              className={reportName === 'agent_report' ? classes.listItemSelected : classes.listItemUnselected}
            >
              Agent Performance
            </div>
          </ListItem>
        </List>
      </List>
    </div>
  )
}

export default ReportsNavBar
