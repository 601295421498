import { makeStyles } from '@material-ui/core/styles'
import { createCustomField } from 'api/custom_fields'
import Form, { FormField } from 'classes/forms'
import CustomFieldsModal from 'components/CustomFieldsModal'
import Tooltip from '@material-ui/core/Tooltip'
// import { Icon, Radio } from 'library/materialUI'
import { Icon } from 'library/materialUI'
import { random } from 'lodash'
// import { accountFields as accFields, dropdownFields as contFields } from 'pages/settings/Integrations/MappingRow'
import { dropdownFields as contFields } from 'pages/settings/Integrations/MappingRow'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
// import { Modal } from 'cf-components/Modal'
import CustomField from 'classes/customFields'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import SearchBar from 'library/search/SearchBar';
import { IconType } from 'library/materialUI/Icon'

const useStyles = makeStyles(theme => ({
  fieldBar: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '288px',
    maxWidth: '288px',
    height: '80vh',
    backgroundColor: '#F9F9F9',
    overflow: 'auto',
    paddingTop: '10px'
  },
  fieldCell: {
    width: '244px',
    height: '44px',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '10px',
    paddingLeft: '10px',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '14px',
    cursor: 'pointer'
  },
  groupTitle: {
    justifySelf: 'flex-start',
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row'
  },
  iconDiv: {
    marginLeft: '5px',
    cursor: 'pointer'
  },
  tabSelectionDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabSelection: {
    fontSize: '12px',
    fontFamily: 'poppins',
    cursor: 'pointer',
    width: '138px',
    textAlign: 'center',
    padding: '15px'
  },
  selectionHighlight: {
    height: '2px',
    width: '138px',
    backgroundColor: '#9933FF',
    transition: 'margin 0.2s ease'
  },
  selectionDivider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#C4C4C4',
    marginBottom: '15px'
  },
  fieldIconDiv: {
    marginLeft: '10px',
    marginRight: '10px'
  }
}))

interface FormFieldBarProps {
  form: Form
  dispatch: any
  setIsDragging: (value: boolean) => void
  setDragInfo: (value: FieldInfo) => void
  setCanSave: (value: boolean) => void
}

const enrichmentFields = [
  'Contact Level', 'Name', 'Title', 'City', 'State', 'Country', 'LinkedIn Handle', 'Facebook Handle', 'X Handle',
  'Company Level', 'Company Name', 'Company Website', 'Company Industry', 'Company Employee Count', 'Company Annual Revenue',
  'Company Phone', 'Company Description', 'Company Location', 'Company Technologies', 'Company Linkedin Handle', 'Company X Handle'
];

function getIcon (type: string): IconType {
  if (type === 'date') {
    return 'dateRange'
  } else if (type === 'text') {
    return 'textIcon'
  } else if (type === 'numeric') {
    return 'numericIcon'
  } else if (type === 'true-false') {
    return 'check'
  } else if (type === 'dropdown') {
    return 'dropDownCircle'
  } else if (type === 'radio') {
    return 'radioChecked'
  } else if (type === 'multi') {
    return 'checkedBox'
  }
  return 'textIcon'
}

export interface FieldInfo {
  id: string
  label: string
  type: 'account' | 'contact'
  kind: string
  optionList: string[]
  isCustomField?: boolean
}

export default function FormFieldBar ({ form, dispatch, setIsDragging, setDragInfo, setCanSave }: FormFieldBarProps) {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const [contactExpanded, setContactExpanded] = useState(true)
  const [customContactExpanded, setCustomContactExpanded] = useState(true)
  // const [accountExpanded, setAccountExpanded] = useState(true)
  const [createNewTab, setCreateNewTab] = useState(false)
  // const [fieldTypeModalOpen, setFieldTypeModalOpen] = useState(false)
  // const [newFieldType, setNewFieldType] = useState('accounts')
  const [newFieldModalOpen, setNewFieldModalOpen] = useState(false)
  const [newFieldInfo, setNewFieldInfo] = useState()
  // const accountFields = accFields
  const contactFields = contFields.filter((field) => (field.group === 'Contact Fields' || field.group === 'Company Fields'))
  const { data: customContactFields, isLoading: contactsLoading } = CustomField.loadAll(undefined, 'people')
  // const { data: customAccountFields, isLoading: accountsLoading } = CustomField.loadAll(undefined, 'accounts')

  const handleDragStart = (info: FieldInfo) => {
    setIsDragging(true)
    setDragInfo({ id: info.id, label: info.label, type: info.type, kind: info.kind, optionList: info.optionList || [], isCustomField: info.isCustomField || false })
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  const fieldTypes = [
    { value: 'title', label: 'Header', icon: 'hIcon' },
    { value: 'subtitle', label: 'Paragraph', icon: 'textFields' },
    { value: 'date', label: 'Date', icon: 'dateRange' },
    { value: 'text', label: 'Text', icon: 'textIcon' },
    { value: 'numeric', label: 'Numeric', icon: 'numericIcon' },
    { value: 'true-false', label: 'True or False', icon: 'check' },
    { value: 'dropdown', label: 'Dropdown Menu', icon: 'dropDownCircle' },
    { value: 'radio', label: 'Radio Buttons', icon: 'radioChecked' },
    { value: 'multi', label: 'Multi Select', icon: 'checkedBox' }
  ]

  const handleAdd = (item: any) => {
    const newFormField: FormField = {
      id: random(1, 10000, false),
      fieldType: item.value || 'field',
      fieldID: item.id,
      fieldLabel: item.value === 'title' ? 'This is a sample header' : item.value === 'subtitle' ? 'This is a rich text area. Double click to edit.' : item.label,
      placeholder: '',
      helperText: '',
      isRequired: false,
      isHidden: false,
      options: [],
      blockEmail: false,
      spamUnwanted: false,
      nonBusinessUnwanted: false,
      blockedDomainsUnwanted: false,
      // sourceType: newFieldType === 'accounts' ? 'account' : 'contact',
      sourceType: 'contact',
      inputType: item.kind,
      hiddenDefault: ''
    }
    dispatch({ type: 'addRow', value: [newFormField] })
    setCanSave(true)
  }

  const handleNewItem = (item: any) => {
    if (item.value === 'title' || item.value === 'subtitle') {
      handleAdd(item)
    } else {
      // setFieldTypeModalOpen(true)
      setNewFieldInfo(item)
      setNewFieldModalOpen(true)
    }
  }

  function createNewField (values: any) {
    // const forObject = newFieldType
    const forObject = 'people'
    createCustomField({ values, forObject })
      .then((resp) => {
        const newID = resp.data.id
        const newFormField: FormField = {
          id: random(1, 10000, false),
          fieldType: 'field',
          fieldID: newID,
          fieldLabel: resp.data.attributes.label,
          placeholder: '',
          helperText: '',
          isRequired: false,
          isHidden: false,
          options: resp.data.attributes.options,
          blockEmail: false,
          spamUnwanted: false,
          nonBusinessUnwanted: false,
          blockedDomainsUnwanted: false,
          // sourceType: newFieldType === 'accounts' ? 'account' : 'contact',
          sourceType: 'contact',
          inputType: resp.data.attributes.kind,
          hiddenDefault: ''
        }
        dispatch({ type: 'addRow', value: [newFormField] })
        setCanSave(true)
        queryClient.invalidateQueries('custom_fields')
      })
  }

  return (
    <div className={classes.fieldBar}>
      <div>
        <div className={classes.tabSelectionDiv}>
          <div onClick={() => setCreateNewTab(false)} className={classes.tabSelection} style={{ color: createNewTab ? '#7C7C7C' : '#9933FF' }}>
            EXISTING FIELDS
          </div>
          <div onClick={() => setCreateNewTab(true)} className={classes.tabSelection} style={{ color: createNewTab ? '#9933FF' : '#7C7C7C' }}>
            CREATE NEW
          </div>
        </div>
        <div className={classes.selectionHighlight} style={{ marginLeft: createNewTab ? '50%' : '0px', marginRight: createNewTab ? '50%' : '0px' }} />
        <div className={classes.selectionDivider} />
      </div>
      {!createNewTab ? (
        <div>
          <div style={{ marginLeft: '12px', marginRight: '15px', marginBottom: '15px' }}>
            <SearchBar
              searchString={search}
              setSearchString={setSearch}
            />
          </div>
          <div className={classes.groupTitle}>
            <div>
              Contact Fields
            </div>
            <div onClick={() => setContactExpanded(!contactExpanded)} className={classes.iconDiv}>
              <Icon icon={contactExpanded ? 'collapse' : 'expand'} />
            </div>
          </div>
          <div>
            {contactExpanded && (
              <div>
                {contactFields.map((field, index) => {
                  if (search) {
                    if (!field.label.toLowerCase().includes(search.toLowerCase())) {
                      return <></>
                    }
                  }
                  let duplicate = false
                  if (form.formLayout) {
                    form.formLayout.forEach((row: FormField[]) => {
                      row.forEach((item: FormField) => {
                        if (item.fieldID === field.value) {
                          duplicate = true
                        }
                      })
                    })
                  }
                  return (
                    <div key={index} draggable={!duplicate} onDragStart={() => handleDragStart({ id: field.value, label: field.label, type: 'contact', kind: field.kind, optionList: field.options || [] })} onDragEnd={() => handleDragEnd()} className={classes.fieldCell} onDoubleClick={() => !duplicate ? handleAdd({ id: field.value, label: field.label, type: 'contact', kind: field.kind, optionList: field.options || [] }) : undefined}>
                      <DragIndicatorIcon />
                      <div className={classes.fieldIconDiv}>
                        <Icon icon={getIcon(field.kind)} size='md' color={duplicate ? '#C4C4C4' : undefined} />
                      </div>
                      <div style={{ color: duplicate ? '#C4C4C4' : undefined }}>
                        {field.label}
                      </div>
                      {enrichmentFields.includes(field.label) && (
                        <Tooltip title='Require visitors to provide a business email address to automatically gather this field through enrichment without placing it in your form. Enrichment is not guaranteed, so if you need to ensure you collect this information, ask for it directly'>
                          <div style={{ marginLeft: 'auto', marginRight: 10 }}>
                            <Icon icon='autoAwesomeYellow' />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className={classes.groupTitle} style={{ marginTop: '15px' }}>
            <div>
              Custom Fields
            </div>
            <div onClick={() => setCustomContactExpanded(!customContactExpanded)} className={classes.iconDiv}>
              <Icon icon={customContactExpanded ? 'collapse' : 'expand'} />
            </div>
          </div>
          <div>
            {customContactExpanded && (
              <div>
                {!contactsLoading && customContactFields.list.map((field, index) => {
                  if (search) {
                    if (!field.label.toLowerCase().includes(search.toLowerCase())) {
                      return <></>
                    }
                  }
                  let duplicate = false
                  if (form.formLayout) {
                    form.formLayout.forEach((row: FormField[]) => {
                      row.forEach((item: FormField) => {
                        if (item.fieldID === field.id) {
                          duplicate = true
                        }
                      })
                    })
                  }
                  return (
                    <div key={index} draggable={!duplicate} onDragStart={() => handleDragStart({ id: field.id.toString(), label: field.label, type: 'contact', kind: field.kind, optionList: field.options, isCustomField: true })} onDragEnd={() => handleDragEnd()} className={classes.fieldCell} onDoubleClick={() => !duplicate ? handleAdd({ id: field.id.toString(), label: field.label, type: 'contact', kind: field.kind, optionList: field.options }) : undefined}>
                      <DragIndicatorIcon />
                      <div className={classes.fieldIconDiv}>
                        <Icon icon={getIcon(field.kind)} size='md' color={duplicate ? '#C4C4C4' : undefined} />
                      </div>
                      <div style={{ color: duplicate ? '#C4C4C4' : undefined }}>
                        {field.label}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {/* <div className={classes.groupTitle} style={{ marginTop: '20px' }}>
            <div>
              Account Fields
            </div>
            <div onClick={() => setAccountExpanded(!accountExpanded)} className={classes.iconDiv}>
              <Icon icon={accountExpanded ? 'collapse' : 'expand'} />
            </div>
          </div>
          <div>
            {accountExpanded && (
              <div>
                {accountFields.map((field, index) => {
                  return (
                    <div key={index} onClick={() => handleAddItem(field.value, field.label, 'account', field.kind)} className={classes.fieldCell}>
                      <DragIndicatorIcon />
                      <div className={classes.fieldIconDiv}>
                        <Icon icon={getIcon(field.kind)} size='md' />
                      </div>
                      <div>
                        {field.label}
                      </div>
                    </div>
                  )
                })}
                {!accountsLoading && customAccountFields.list.map((field, index) => {
                  return (
                    <div key={index} onClick={() => handleAddItem(field.id.toString(), field.label, 'account', field.kind, field.options)} className={classes.fieldCell}>
                      <DragIndicatorIcon />
                      <div className={classes.fieldIconDiv}>
                        <Icon icon={getIcon(field.kind)} size='md' />
                      </div>
                      <div>
                        {field.label}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div> */}
        </div>
      ) : (
        <div>
          <div className={classes.groupTitle}>
            <div>
              Field Types
            </div>
          </div>
          <div>
            {fieldTypes.map((field, index) => {
              return (
                <div key={index} onClick={() => handleNewItem(field)} className={classes.fieldCell}>
                  <Icon icon='add' />
                  <div className={classes.fieldIconDiv}>
                    <Icon icon={getIcon(field.icon)} size='md' />
                  </div>
                  <div>
                    {field.label}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {/* {fieldTypeModalOpen && (
        <div>
          <Modal
            open={fieldTypeModalOpen}
            title='Create New Field'
            onHide={() => setFieldTypeModalOpen(false)}
            rightButtonProps={{
              action: () => { setNewFieldModalOpen(true); setFieldTypeModalOpen(false) },
              text: 'Next'
            }}
            leftButtonProps={{
              action: () => setFieldTypeModalOpen(false),
              text: 'Cancel'
            }}
          >
            <div>
              <div>
                What should this field be created under?
              </div>
              <div>
                <Radio
                  label='Accounts'
                  value={newFieldType === 'accounts'}
                  checked={newFieldType === 'accounts'}
                  handleChange={() => setNewFieldType('accounts')}
                />
                <Radio
                  label='Contacts'
                  value={newFieldType === 'people'}
                  checked={newFieldType === 'people'}
                  handleChange={() => setNewFieldType('people')}
                />
              </div>
            </div>
          </Modal>
        </div>
      )} */}
      {newFieldModalOpen && (
        <div>
          <CustomFieldsModal
            open={newFieldModalOpen}
            onHide={() => setNewFieldModalOpen(false)}
            save={createNewField}
            formProps={newFieldInfo}
          />
        </div>
      )}
    </div>
  )
}
