import { makeStyles } from '@material-ui/core/styles'
import SkeletonLabel from 'library/loading/skeleton/SkeletonLabel'
import SkeletonTableLoader from 'library/loading/skeleton/SkeletonTableLoader'
import { parameterizeFilters } from 'classes/queryHelpers'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  tabPlaceholder: {
    width: 200,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 20
  }
}))

function KeyWordGroupsTable (props) {
  const searchParams = {
    sortColumn: 'name',
    sortOrder: 'asc',
    pageSize: 10
  }
  const editGroup = props.editGroup
  const handleDelete = props.handleDelete
  const classes = useStyles()
  const url = parameterizeFilters({ path: '/api/core/web/chat_settings/keyword_groups', searchParams })

  const rowEventListener = (e) => {
    const tableID = e.detail.tableID
    const rowAction = e.detail.action
    const rowID = e.detail.rowID
    if (tableID === 'keyword-groups-body') {
      switch (rowAction) {
        case 'edit':
          editGroup(rowID)
          break
        case 'delete':
          handleDelete(rowID)
          break
        default:
          // console.warn('Unknown row action', rowAction)
      }
    }
  }

  useEffect(() => {
    const mywindow = window
    mywindow.addEventListener('rowEvent', rowEventListener)
    // eslint-disable-next-line
  }, [])

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div
        id='keyword-groups'
        hx-get={url}
        hx-trigger='revealed, paramChange'
      >
        <div style={{ display: 'flex', justifyContent: 'right', padding: 10 }}>
          <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
          <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
          <div className={classes.tabPlaceholder} style={{ margin: 10 }}> <SkeletonLabel size='full' /> </div>
        </div>
        <SkeletonTableLoader
          rowCount={10}
          types={['squareAvatar&Label', 'smLabel', 'number', 'smLabel', 'name&Subtitle', 'avatar']}
          columns='2fr 1fr 1fr 1fr 1fr 50px 50px'
          justifyCenter
        />
      </div>
    </div>
  )
}

export default KeyWordGroupsTable
