import FilterBarIcon from './FilterBarIcon'
import { Divider, Icon } from 'library/materialUI'
import { makeStyles } from '@material-ui/core/styles'
import { ReactNode, useRef } from 'react'
import { SearchParams } from 'classes/queryHelpers'

const useStyles = makeStyles(theme => ({
  menu: {
    padding: 10,
    minWidth: 220,
    backgroundColor: 'white',
    boxShadow: '0px 3px 6px #00000029'
  },
  menuHeader: {
    font: 'normal normal 600 16px/22px Poppins',
    display: 'flex',
    alignItems: 'center'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 2,
    cursor: 'pointer',
    backgroundColor: 'white'
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  controlButtons: {
    padding: 5,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    font: 'normal normal normal 16px/24px Poppins'
  },
  cancelButton: {
    color: '#000000BF',
    marginRight: 15,
    cursor: 'pointer'
  },
  applyButton: {
    color: '#9933FF',
    cursor: 'pointer'
  }
}))

interface FilterSubMenuProps {
  title: string
  menu: ReactNode
  goBack?: () => void
  field?: string
  hideControls?: boolean
  listFilterProps?: any
  searchParams: SearchParams
  handleFilter: (filters: any) => void
}

function FilterControls (props) {
  const classes = useStyles()

  if (props.goBack) {
    return (
      <div className={classes.controlButtons}>
        <div
          className={classes.cancelButton}
          onClick={props.goBack}
        >
          Cancel
        </div>
        <div
          className={classes.applyButton}
          onClick={props.applyFilter}
        >
          Apply Filter
        </div>
      </div>
    )
  } else {
    return (
      <></>
    )
  }
}

export interface FilterComponentProps {
  filterRef: any
  initialValues: any
  applyFilter: () => void
}

export function FilterSubMenu (props: FilterSubMenuProps) {
  const classes = useStyles()
  const Menu = props.menu
  const goBack = props.goBack ? props.goBack : null
  const filterRef = useRef(null)

  const applyFilter = () => {
    const filters = filterRef.current ? [[filterRef.current]] : [[{ field: props.field, operator: 'remove', value: [] }]]
    props.handleFilter(filters)
    if (props.listFilterProps?.handleFilterChange) {
      props.listFilterProps.handleFilterChange(filterRef.current)
    }
    if (goBack) goBack()
  }

  let initialValues = null
  if (props.listFilterProps?.initialValues && props.field) {
    initialValues = props.field ? props.listFilterProps.initialValues[props.field] : null
  }
  if (!initialValues && props.searchParams?.filters?.length) {
    props.searchParams.filters.forEach((queryFilter) => {
      queryFilter.forEach((filter) => {
        if (filter.field === props.field) {
          initialValues = filter.value
        }
      })
    })
  }

  return (
    <div className={classes.menu}>
      <div
        className={classes.menuHeader}
        onClick={() => { if (goBack) goBack() }}
      >
        {goBack && <Icon icon='leftArrow' />}
        {props.title}
      </div>
      <Divider className={classes.divider} />
      <Menu
        filterRef={filterRef}
        initialValues={initialValues}
        applyFilter={applyFilter}
      />
      {props.hideControls && goBack
        ? <></>
        : (
          <FilterControls
            goBack={() => { if (goBack) goBack() }}
            applyFilter={applyFilter}
          />)}
    </div>
  )
}

export default function FilterButton (props: any) {
  return (
    <FilterBarIcon listFilterProps={props.listFilterProps} iconOnly={props.iconOnly} placement={props.placement} />
  )
}
