/* eslint-disable @typescript-eslint/naming-convention */
import { useContext } from 'react'
import { DynamicSegmentContext } from './DynamicSegmentContext'
import SegmentCriteriaButton from './SegmentCriteriaButton'

export default function SegmentCriteriaButtons () {
  const { triggers, editFilter, getStartingBtnValue, handleDeleteTrigger, determineActionButton } = useContext(DynamicSegmentContext)
  return (
    <>
      {triggers[0].filters.map((segmentTrigger, index) => {
        return (
          <SegmentCriteriaButton
            segmentTrigger={segmentTrigger}
            index={index}
            buttonProps={getStartingBtnValue(segmentTrigger)}
            editFilter={editFilter}
            deleteTrigger={(index) => handleDeleteTrigger(index)}
            actionButton={determineActionButton(triggers[0].filters, index)}
            key={index + segmentTrigger.prop + segmentTrigger.value + segmentTrigger.cmp}
          />
        )
      })}
    </>
  )
}
