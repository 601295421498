import { forwardRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Collapse from '@material-ui/core/Collapse'
import { ReactComponent as MySettingsSelected } from 'img/MySettingsSelected.svg'
import { ReactComponent as MySettingsUnselected } from 'img/MySettingsUnselected.svg'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minWidth: 230,
    height: '100vh',
    overflow: 'auto',
    fontFamily: 'Poppins, sans serif'
  },
  nested: {
    paddingLeft: theme.spacing(5)
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  listItemSelected: {
    fontSize: '15px',
    color: '#2A2A2A',
    fontWeight: 600
  },
  listItemUnselected: {
    fontSize: '15px',
    color: '#7A7A7A'
  }
}))

const AdminTenantsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/tenants' {...props} />
))

const BugReportsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/bugs' {...props} />
))

const AdminFeatureAnnouncementsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/announcements' {...props} />
))

const ModerationLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/moderation' {...props} />
))

const BillingPageSettingsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/offers' {...props} />
))

const TrialSettingsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/trial' {...props} />
))

const TermsAndConditionsLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/terms_and_conditions' {...props} />
))

const NPSLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/nps' {...props} />
))

const ScriptManagementLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/status_management' {...props} />
))

const UsagePageLink = forwardRef((props, ref) => (
  <Link innerRef={ref} to='/admin_portal/usage' {...props} />
))

const AdminPortalNavBar = ({ location }) => {
  const classes = useStyles()
  let page = 'offers'
  if (location && location.pathname.split('/').length > 2) {
    page = location.pathname.split('/')[2]
  }
  const [billingOpen, setBillingOpen] = useState(['offers', 'trial'].includes(page))
  const [accountManagementOpen, setAccountManagementOpen] = useState(['tenants', 'announcements', 'bugs'].includes(page))

  function handleClickBillingOpen () {
    setBillingOpen(!billingOpen)
  }

  function handleClickAccountManagementOpen () {
    setAccountManagementOpen(!accountManagementOpen)
  }

  return (
    <div>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        disablePadding
        subheader={
          <ListSubheader component='div' id='nested-list-subheader' disableSticky>
            Admin Portal
          </ListSubheader>
        }
        className={classes.root}
      >
        <ListItem button onClick={handleClickAccountManagementOpen}>
          <div className={classes.itemHeader}>
            {['tenants', 'announcements', 'bugs'].includes(page)
              ? <MySettingsSelected style={{ marginRight: 15 }} />
              : <MySettingsUnselected style={{ marginRight: 15 }} />}
            Account Management
          </div>
        </ListItem>
        <Collapse in={accountManagementOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              button
              component={AdminTenantsLink}
              className={classes.nested}
            >
              <div className={page === 'tenants' ? classes.listItemSelected : classes.listItemUnselected}>Tenant List</div>
            </ListItem>
            <ListItem
              button
              component={AdminFeatureAnnouncementsLink}
              className={classes.nested}
            >
              <div className={page === 'announcements' ? classes.listItemSelected : classes.listItemUnselected}>Announcements</div>
            </ListItem>
            <ListItem
              button
              component={ModerationLink}
              className={classes.nested}
            >
              <div className={page === 'moderation' ? classes.listItemSelected : classes.listItemUnselected}>Moderation</div>
            </ListItem>
            <ListItem
              button
              component={NPSLink}
              className={classes.nested}
            >
              <div className={page === 'nps' ? classes.listItemSelected : classes.listItemUnselected}>NPS</div>
            </ListItem>
            <ListItem
              button
              component={ScriptManagementLink}
              className={classes.nested}
            >
              <div className={page === 'status_management' ? classes.listItemSelected : classes.listItemUnselected}>Tenant Status Management</div>
            </ListItem>
            <ListItem
              button
              component={UsagePageLink}
              className={classes.nested}
            >
              <div className={page === 'usage' ? classes.listItemSelected : classes.listItemUnselected}>Usage</div>
            </ListItem>
            <ListItem
              button
              component={BugReportsLink}
              className={classes.nested}
            >
              <div className={page === 'bugs' ? classes.listItemSelected : classes.listItemUnselected}>Bug Reports</div>
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={handleClickBillingOpen}>
          <div className={classes.itemHeader}>
            {['offers', 'trial'].includes(page)
              ? <MySettingsSelected style={{ marginRight: 15 }} />
              : <MySettingsUnselected style={{ marginRight: 15 }} />}
            Billing
          </div>
        </ListItem>
        <Collapse in={billingOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem
              button
              component={BillingPageSettingsLink}
              className={classes.nested}
            >
              <div className={page === 'offers' ? classes.listItemSelected : classes.listItemUnselected}>Chargify Offers</div>
            </ListItem>
          </List>
          <List component='div' disablePadding>
            <ListItem
              button
              component={TrialSettingsLink}
              className={classes.nested}
            >
              <div className={page === 'trial' ? classes.listItemSelected : classes.listItemUnselected}>Trial Offer</div>
            </ListItem>
          </List>
          <List component='div' disablePadding>
            <ListItem
              button
              component={TermsAndConditionsLink}
              className={classes.nested}
            >
              <div className={page === 'terms_and_conditions' ? classes.listItemSelected : classes.listItemUnselected}>Terms and Conditions</div>
            </ListItem>
          </List>
        </Collapse>

      </List>
    </div>
  )
}

export default AdminPortalNavBar
