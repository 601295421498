import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Badge, Avatar } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Tooltip } from 'library/materialUI'

function getColoredSVG (color) {
  const imageTemplate = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <defs>
        <style>.cls-1{fill:${color};}</style>
      </defs>
      <title>Agent Profile_Large Invite</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Invite_-_Large" data-name="Invite - Large">
          <path class="cls-1" d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm0,4.5A4.5,4.5,0,1,1,10.5,9,4.49,4.49,0,0,1,15,4.5Zm0,21.3A10.79,10.79,0,0,1,6,21c0-3,6-4.62,9-4.62S24,18,24,21A10.81,10.81,0,0,1,15,25.8Z"/>
        </g>
      </g>
    </svg>
    `

  const base64data = btoa(unescape(encodeURIComponent(imageTemplate)))
  return base64data
}

const useStyles = makeStyles({
  avatar: {
    fontFamily: 'poppins, sans serif',
    fontWeight: 'bold',
    backgroundColor: '#CCCCCC'
  },
  avatarImage: {
    backgroundColor: '#FFF'
  },
  avatarRoot: {
    fontSize: '1em'
  },
  badge: props => ({
    height: props.badgeSize,
    minWidth: props.badgeSize,
    padding: 0,
    backgroundColor: props.color || undefined,
    boxShadow: `0 0 0 2px ${props.available ? props.backgroundColor : '#00000000'}`
  }),
  link: {
    textDecoration: 'none',
    color: '#555'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  nameParagraph: props => ({
    margin: 0,
    fontSize: '1em',
    maxWidth: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    ...props.nameParagraph
  }),
  emailParagraph: props => ({
    margin: 0,
    fontSize: '.8em',
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...props.emailParagraph
  }),
  contactInfo: props => ({
    paddingLeft: 15,
    fontSize: 16,
    width: 'calc(100% - 60px)',
    ...props.contactInfo
  })
})

const OnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#08c20b',
    color: '#08c20b',
    '&::after': {
      position: 'absolute',
      top: -2,
      left: -2,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '2px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(1.8)',
      opacity: 0
    }
  }
}))(Badge)

export function AvatarImage ({ avatarUrl, initials, size, backgroundColor, color, variant, alt, round }) {
  const classes = useStyles()
  let height, fontSize
  if (size === 'sm') {
    height = '2em'
    fontSize = '1em'
  } else if (size === 'md') {
    height = '2.5em'
    fontSize = '1.2em'
  } else if (size === 'dash') {
    height = '32px'
    fontSize = '1.2em'
  } else if (size === 'lg') {
    height = '60px'
    fontSize = '2em'
  } else if (size === 'xs') {
    height = '1.5em'
    fontSize = '1em'
  } else if (size === 'xxs') {
    height = '2em'
    fontSize = '.5em'
  }

  // Replaced color and background color

  if (avatarUrl) {
    return (
      <Avatar
        className={classes.avatar}
        style={{ width: height, height: height, fontSize: fontSize, color: '#FFF', backgroundColor: '#303040', borderRadius: !round ? '5px' : undefined }}
        classes={{ img: classes.avatarImage, root: classes.avatarRoot }}
        src={avatarUrl}
        variant={variant || 'circular'}
        alt={alt}
      >
        {initials}
      </Avatar>
    )
  }

  if (initials) {
    return (
      <Avatar
        className={classes.avatar}
        variant={variant || 'circular'}
        style={{ width: height, height: height, fontSize: fontSize, color: '#FFF', backgroundColor: '#303040' }}
      >
        {initials}
      </Avatar>
    )
  }

  const coloredAvatar = getColoredSVG(color)

  return (
    <Avatar
      className={classes.avatar}
      style={{ width: height, height: height, fontSize: fontSize, color: backgroundColor, backgroundColor: color, borderRadius: !round ? '5px' : undefined }}
      classes={{ img: classes.avatarImage, root: classes.avatarRoot }}
      src={`data:image/svg+xml;base64,${coloredAvatar}`}
      variant={variant || 'circular'}
      alt='Visitor Avatar'
    >
      {initials}
    </Avatar>
  )
}

function CustomBadge (props) {
  const size = props.size
  let badgeSize
  badgeSize = 10
  if (size === 'lg') {
    badgeSize = 12
  } else if (size === 'md') {
    badgeSize = 10
  } else if (size === 'sm') {
    badgeSize = 10
  }

  const badgeProps = {
    backgroundColor: props.status === 'available' ? props.backgroundColor : undefined,
    badgeSize: badgeSize,
    color: props.status === 'available' ? '#08c20b' : '#db2221',
    available: props.status === 'available'
  }
  const classes = useStyles(badgeProps)
  if (props.status === 'available') {
    return (
      <OnlineBadge
        overlap='circular'
        badgeContent=''
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{ badge: classes.badge }}
      >
        {props.children}
      </OnlineBadge>
    )
  } else {
    return (
      <Badge
        overlap='circular'
        badgeContent=''
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{ badge: classes.badge }}
      >
        {props.children}
      </Badge>
    )
  }
}

export function ChatFunnelsAvatar ({
  status = '',
  size = '',
  user = undefined,
  contact = undefined,
  participantID = 0,
  backgroundColor = '',
  avatarUrl = '',
  color = '',
  initials,
  variant = '',
  alt = '',
  useLogos = '',
  domain = '',
  conversation = undefined,
  round = true
}) {
  if (contact?.attributes) {
    const attributes = contact.attributes
    avatarUrl = attributes.avatar_url
    if (!avatarUrl && contact.attributes.domain && useLogos) {
      avatarUrl = `/api/logo-service/logo/${attributes.domain}.png`
    }
    if (attributes.first_name) {
      initials = attributes.first_name.charAt(0)
    }
    if (attributes.last_name) {
      initials += attributes.last_name.charAt(0)
    }
    if (initials === '' && attributes.email) {
      initials = attributes.email.charAt(0).toUpperCase()
    }
    color = color || getColorByID(contact.id)
  } else if (conversation) {
    const r = conversation.relationships
    const a = conversation.attributes
    const id = r.for_contact.data.id || r.for_participant.data.id
    color = color || getColorByID(id)
    if (a.contact_avatar || a.domain) {
      avatarUrl = a.contact_avatar || `/api/logo-service/logo/${a.domain}.png`
    }
    if (a.first_name || a.last_name) {
      initials = a.first_name.charAt(0) + a.last_name.charAt(0)
    } else if (a.email) {
      initials = a.email.charAt(0).toUpperCase()
    }
  } else if (participantID) {
    color = color || getColorByID(participantID)
  } else if (user) {
    avatarUrl = user?.avatarUrl || user?.attributes?.profile_picture_url
    if (!avatarUrl && (user?.name || user?.attributes?.name)) {
      const name = user?.name || user?.attributes.name
      initials = getInitials(name)
    }
    color = '#CCC'
  } else if (domain) {
    avatarUrl = `/api/logo-service/logo/${domain}.png`
  }

  if (status) {
    return (
      <CustomBadge
        status={status}
        backgroundColor={backgroundColor}
        size={size}
      >
        <AvatarImage
          avatarUrl={avatarUrl}
          initials={initials}
          size={size}
          backgroundColor='#FFF'
          color={color}
          variant={variant}
          round={round}
        />
      </CustomBadge>
    )
  }

  return (
    <AvatarImage
      avatarUrl={avatarUrl}
      initials={initials}
      size={size}
      backgroundColor={backgroundColor}
      color={color}
      variant={variant}
      alt={alt}
      round={round}
    />
  )
}

function AvatarWithInfo (props) {
  const classes = useStyles(props.style || {})
  const textStyleMods = {}
  const subTextStyleMods = {}

  if (props.color) {
    textStyleMods.color = props.color
    subTextStyleMods.color = props.color
  }

  if (props.size === 'lg') {
    textStyleMods.fontSize = '1.2em'
    subTextStyleMods.fontSize = '1em'
  }

  const textStyle = { ...textStyleMods, ...props.textStyle }
  const subTextStyle = { ...subTextStyleMods, ...props.subTextStyle }

  // Replaced colors here

  return (
    <div className={classes.container}>
      <ChatFunnelsAvatar
        user={props.user}
        contact={props.contact}
        participantID={props.participantID}
        size={props.size || 'md'}
        initials={props.initials}
        backgroundColor='#303040'
        color='#FFF'
        variant={props.variant}
        domain={props.domain}
      />
      <div className={classes.contactInfo}>
        <Tooltip title={props.title} placement='bottom-start'>
          <h3 className={classes.nameParagraph} style={textStyle}>{props.title}</h3>
        </Tooltip>
        {Boolean(props.subtitle) &&
          <p className={classes.emailParagraph} style={subTextStyle}>{props.subtitle}</p>}
      </div>
    </div>
  )
}

function ContactInfoAvatar (props) {
  const classes = useStyles()

  // Replaced colors here

  if (props.user) {
    const user = props.user.attributes
    if (props.noLink) {
      return (
        <AvatarWithInfo
          user={props.user}
          title={user.name}
          subtitle={user.email}
          color='#000'
          backgroundColor='#303040'
          size={props.size}
        />
      )
    }

    return (
      <Link to={`/users/${props.user.id}`} className={classes.link}>
        <AvatarWithInfo
          user={props.user}
          title={user.name}
          subtitle={user.email}
          color='#000'
          backgroundColor='#303040'
          size={props.size}
        />
      </Link>
    )
  }

  let name = 'Site Visitor'
  let email = ''

  if (props.contact?.attributes) {
    const attributes = props.contact.attributes
    if (attributes.first_name || attributes.last_name) {
      name = attributes.first_name + ' ' + attributes.last_name
    }
    email = attributes.email
  }

  return (
    !props?.contact?.id || props.noLink ? (
      <AvatarWithInfo
        contact={props.contact}
        participantID={props.participantID}
        title={name}
        subtitle={email}
        backgroundColor={props.backgroundColor}
        size={props.size}
        color={props.color}
      />
    ) : (
      <Link to={`/contacts/${props.contact.id}`} className={classes.link} target='_blank'>
        <AvatarWithInfo
          contact={props.contact}
          participantID={props.participantID}
          title={name}
          subtitle={email}
          backgroundColor={props.backgroundColor}
          size={props.size}
          color={props.color}
        />
      </Link>
    )
  )
}

function getInitials (name, fallback) {
  let initials = fallback
  if (name) {
    const words = name.split(' ')
    if (words.length > 1) {
      initials = words[0].replace(/[^a-zA-Z]/g, '').charAt(0) + words[1].replace(/[^a-zA-Z]/g, '').charAt(0)
    } else if (words.length) {
      initials = words[0].replace(/[^a-zA-Z]/g, '').charAt(0)
    }
  }

  return (initials || 'C').toUpperCase()
}

function getColor (index) {
  const color = index % 2 ? '#fe9d10' : '#8e5ae2'
  return color
}

function getColorByID (id) {
  const VisitorAvatarColors = ['#7AC9FF', '#f0706f', '#8e5ae2', '#ff9a5c', '#e25ac0', '#5ae273', '#e2e25a']
  if (typeof id === 'string') { // this done for summary companies
    return VisitorAvatarColors[id.charCodeAt(2) % 7]
  }
  return VisitorAvatarColors[id ? id % 7 : 0]
}

export { ChatFunnelsAvatar as Avatar, ContactInfoAvatar, AvatarWithInfo, getInitials, getColor, getColorByID }
