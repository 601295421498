import { makeStyles } from '@material-ui/core/styles'
import Picker from 'cf-components/Pickers'
import { Modal } from 'library/Modal'
import { Typography } from 'library/materialUI'
import Card from 'library/materialUI/Card'
import { useEffect, useState } from 'react'
import { ObjectDependency } from 'classes/objectDependency'
import Loader from 'library/loading/Loader'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { hasComponent } from 'api/billing'
import ModalWarning from 'components/ModalWarning'
import { triggerSalesBot } from 'pages/bot/InAppBot/triggerSupportBot'

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 15
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 10
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  bodyText: {
    marginBottom: 30,
    fontSize: 16
  },
  select: {
    width: 250,
    height: 40
  },
  star: {
    fill: 'gold'
  },
  icon: {
    marginTop: 4,
    marginLeft: 4
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0
  },
  lineItem: {
    display: 'flex',
    flexDireciton: 'row',
    marginTop: '10px'
  }
}))

interface DependencyModalProps {
  open: boolean
  onHide: () => void
  object: any
}

/**
 * This dependency modal is very dependant on the ObjectDependency for crm_custom_field types
 * When we expand to Hubspot we'll need to make this modal more universal
 * @param param0 Open boolean, onHide function, and active object being removed
 * @returns Warning modal with list of segments that use the field being removed
 */

function DependencyModal ({ open, onHide, object }: DependencyModalProps): JSX.Element {
  const { data, isLoading } = ObjectDependency.loadAll(
    {
      searchParams: {
        filters: [
          [
            { field: 'child_id', operator: 'eq', value: object?.id },
            { field: 'child_type', operator: 'eq', value: 'crm_custom_field' }
          ]
        ]
      }
    })

  const classes = useStyles()
  return (
    <Modal
      title='Cannot remove fields in use'
      open={open}
      onHide={onHide}
      handleSave={onHide}
      cancelBtnText='Close'
      hideSaveButton
    >
      <Typography variant='body1' style={{ fontSize: 16 }}>
        The <b>{object?.name}</b> field is being used in the following segment(s):
      </Typography>
      {isLoading ? (
        <Loader type='spinner' />
      ) : (
        <ul>
          {data?.list.map((dependency: any) => (
            <li key={dependency.id}>
              <Link to={`/account_segments/${dependency.parentID}`} target='_blank'>
                {dependency.meta[0].name}
              </Link>
            </li>
          ))}
        </ul>
      )}
      <Typography variant='body1' className={classes.bodyText}>
        To remove this field, first remove or replace it in the segment(s) above
      </Typography>
    </Modal>
  )
}

interface AccountCustomFieldSegmentCardProps {
  handleEdit: ({ type, data }: { type: string, data: any }) => void
  setCanSave: (value: boolean) => void
  fields: any[]
  integration: string
}

/**
 * Card used in integration pages mainly for crm accounts
 * Some changes will need to be made if we notice big hinderances to segments when a field is removed, but there is a field active and it messes up the column order
 * There will need to be a way to keep mapping the same for each field
 * @param param0 { handleEdit: function to edit the integration, setCanSave: function to set the save button to enabled/disabled, fields: list of fields to map to crm fields }
 * @returns Segmentation card with picker to select fields used in segmentation
 */

export default function AccountCustomFieldSegmentCard ({ handleEdit, setCanSave, fields, integration }: AccountCustomFieldSegmentCardProps): JSX.Element {
  const classes = useStyles()
  const [open, setOpen] = useState<any>(false)
  const [activeFieldBeingRemoved, setActiveFieldBeingRemoved] = useState<any>(undefined)
  const { data: hasCRMCustomFieldComponent, isLoading } = useQuery('has-crm-custom-field-component', () => hasComponent('crm-segment-by-custom-field'))
  const [mapping, setMapping] = useState<Record<string, string>>({})

  const checkActiveRemoval = (newFields: any[]): boolean => {
    if (newFields.length < fields.length) {
      const newFieldIds = newFields.map((field) => field.id)
      const removedActiveField = fields.find((field) => !newFieldIds.includes(field.id) && field.active)
      setActiveFieldBeingRemoved(removedActiveField)
      if (removedActiveField) return true
    }
    return false
  }

  const createFieldMapping = (fields: any[]): any[] => {
    const _mapping = { ...mapping }
    const _reverseMapping: Record<string, string> = {}
    const used: Array<string> = []
    for (const [key, value] of Object.entries(_mapping)) {
      _reverseMapping[value] = key
    }
    const result: any[] = []
    fields.forEach((field) => {
      let prefix = 'crm_txt_field_'
      if (field.signalsType === 'number') {
        prefix = 'crm_num_field_'
      }
      if (_reverseMapping[field.apiName]) {
        used.push(_reverseMapping[field.apiName])
        result.push({ ...field, accountProp: _reverseMapping[field.apiName] })
      } else {
        let x = 1
        for (; x < 9; x++) {
          if (!_mapping[prefix + x]) {
            _mapping[prefix + x] = field.apiName
            _reverseMapping[field.apiName] = prefix + x
            used.push(prefix + x)
            result.push({ ...field, accountProp: prefix + x })
            break
          }
        }
      }
    })
    for (const key of Object.keys(_mapping)) {
      if (!used.includes(key)) {
        delete _mapping[key]
      }
    }
    setMapping({ ..._mapping })
    return result
  }
  useEffect(() => {
    if (!isLoading) {
      const _mapping: Record<string, string> = {}
      fields.forEach((field, index) => {
        if (field.accountProp) {
          _mapping[field.accountProp] = field.id
        } else {
          _mapping[`crm_txt_field_${index + 1}`] = field.id
        }
      })
      setMapping({ ..._mapping })
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loader type='spinner' />
  }

  return (
    <Card
      content={
        <>
          <div className={classes.header}>
            <Typography className={classes.subtitle1}>Custom {integration === 'hubspot' ? 'Company' : 'Account'} Fields for Segmentation</Typography>
          </div>
          <Typography variant='body2' className={classes.bodyText}>
            Select up to 8 fields to be used in creating segments.
          </Typography>
          <div style={{ width: 600 }}>
            {!hasCRMCustomFieldComponent ? (
              <ModalWarning
                body='Upgrade to segment using custom fields'
                type='upgrade'
                helpAction={() => triggerSalesBot()}
              />
            ) : (
              <Picker
                selection={fields.map((field) => field.id)}
                setSelection={(fields: any) => {
                  if (checkActiveRemoval(fields)) {
                    setOpen(fields)
                    return
                  }
                  const mapping = createFieldMapping(fields)
                  handleEdit({ type: 'field_custom_mapping', data: mapping })
                  setCanSave(true)
                }}
                objectType='crmFields'
                loadAllProps={{ searchParams: { sortColumn: 'accounts', extraHeaders: { 'x-integration': integration.toLowerCase() } } }}
                multiple
                max={8}
                returnFull
                sortOptions
                activeProps={{
                  icon: 'pieChart',
                  notActiveMsg: 'Not currently used in any segments'
                }}
              />
            )}
          </div>
          {activeFieldBeingRemoved && (
            <DependencyModal
              open={open}
              onHide={() => {
                setOpen(false)
                setActiveFieldBeingRemoved(undefined)
              }}
              object={activeFieldBeingRemoved}
            />
          )}
        </>
      }
    />
  )
}
