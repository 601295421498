import { makeStyles } from '@material-ui/core/styles'
import { Route, Switch, Redirect } from 'react-router-dom'
import AdminPortalNavBar from './AdminPortalNavBar'
import TrialSettingsPage from './TrialSettingsPage'
import AdminTenantsPage from './AdminTenantsPage'
import BugReportsPage from './BugReportsPage'
import AdminAnnouncementsPage from './AdminAnnouncementsPage'
import { NPSPage } from './nps/NPSPage'
import { ChargifyOfferList } from './billing/ChargifyOfferList'
import { AdminSingleTenantPage } from './AdminTenantPage'
import ScriptManagementPage from './AdminScriptManagement'
import AdminUsagePage from './usage/AdminUsagePage'
import AdminModerationPage from './AdminModerationPage'
import TermsAndConditionsTable from './termsandconditions/TermsAndConditionsTable'
import TermsAndConditionsPage from './termsandconditions/TermsAndConditionsPage'

const useStyles = makeStyles(theme => ({
  navbar: {
    marginTop: 0,
    height: '100%',
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderColor: '#d3d3d3'
  },
  settingsContent: {
    height: '100%',
    width: '100%',
    overflow: 'auto'
  },
  container: {
    display: 'flex',
    alignItems: 'stretch',
    height: '100%'
  }
}))

function AdminPortalRouter ({ match, location }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.navbar}>
        <AdminPortalNavBar location={location} />
      </div>
      <main className={classes.settingsContent}>
        <Switch>
          <Route exact path={`${match.path}`} />
          <Route
            path={`${match.path}/offers`}
            component={ChargifyOfferList}
          />
          <Route
            path={`${match.path}/trial`}
            component={TrialSettingsPage}
          />
          <Route
            path={`${match.path}/terms_and_conditions/:id`}
            component={TermsAndConditionsPage}
            exact={false}
          />
          <Route
            path={`${match.path}/terms_and_conditions`}
            component={TermsAndConditionsTable}
          />
          <Route
            path={`${match.path}/tenants/:tenantId`}
            // component={AdminUserListPage}
            component={AdminSingleTenantPage}
            exact={false}
          />
          <Route
            path={`${match.path}/tenants`}
            component={AdminTenantsPage}
          />
          <Route
            path={`${match.path}/bugs`}
            component={BugReportsPage}
          />
          <Route
            path={`${match.path}/moderation`}
            component={AdminModerationPage}
          />
          <Route
            path={`${match.path}/nps`}
            component={NPSPage}
          />
          <Route
            path={`${match.path}/announcements`}
            component={AdminAnnouncementsPage}
          />
          <Route
            path={`${match.path}/status_management`}
            component={ScriptManagementPage}
          />
          <Route
            path={`${match.path}/usage`}
            component={AdminUsagePage}
          />
          <Redirect path='*' exact to={`${match.path}/offers`} />
        </Switch>
      </main>
    </div>
  )
}

export default AdminPortalRouter
