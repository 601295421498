import { getTenantBillingSubscription } from 'api/billing'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { TextBox } from 'library/materialUI/TextBox'
import { Icon } from 'library/materialUI'
import { getTenantConsultingHours, updateTenantConsultingHours } from 'api/admin_only/tenants'

const useStyles = makeStyles(theme => ({
  planInfo: {
    marginBottom: 15
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr',
    gridGap: 5,
    marginBottom: 20,
    marginTop: 20
  },
  bold: {
    fontWeight: 600
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icon: {
    marginLeft: 15
  }
}))

/* eslint-disable */ // linting needs to be re-enabled once billing class is set up with variables in camel case instead of snake case
interface billingTypes {
  plan_name: string
  current_period_ends: string
  current_period_start: string
  total_revenue: number
}
/* eslint-enable */

export const AdminPlanInfo = (props: { tenantId: number | null }): JSX.Element => {
  const classes = useStyles()
  const tenantId = props.tenantId
  const [billing, setBilling] = useState<billingTypes | null>(null)
  const [editMode, setEditMode] = useState(false)
  const [consultingHrs, setConsultingHrs] = useState(0)

  useEffect(() => {
    if (tenantId) {
      getTenantBillingSubscription(1, tenantId)
        .then(response => {
          setBilling(response.data.attributes)
        })
        .catch(() => {
          setBilling({ plan_name: '', current_period_ends: '', current_period_start: '', total_revenue: 0 }) // eslint-disable-line
        })
      getTenantConsultingHours(tenantId)
        .then(response => {
          setConsultingHrs(response.data.attributes.consulting_hours)
        })
    }
  }, [tenantId])

  const handleSave = (val: number) => {
    updateTenantConsultingHours({ tenantId: tenantId, hours: val })
    setEditMode(false)
  }

  return (
    <div className={classes.grid}>
      <div>
        <div className={`${classes.planInfo} ${classes.bold}`}>Plan:</div>
        <div className={`${classes.planInfo} ${classes.bold}`}>Monthly Consulting Hrs:</div>
        <div className={`${classes.planInfo} ${classes.bold}`}>Billing Period Start:</div>
        <div className={`${classes.planInfo} ${classes.bold}`}>Next Payment Due:</div>
        <div className={`${classes.planInfo} ${classes.bold}`}>Yearly/All-time Value:</div>
      </div>
      <div>
        <div className={classes.planInfo}>{billing?.plan_name ? billing.plan_name : 'None'}</div>
        <div>
          {editMode ? (
            <div className={`${classes.flex} ${classes.planInfo}`}>
              <TextBox
                onChange={(val: number) => setConsultingHrs(val)}
                value={consultingHrs}
                type='number'
                class={{ style: { padding: '2.5px 4px' } }}
              />
              <div className={classes.icon}>
                <Icon
                  icon='save'
                  size='sm'
                  color='primary'
                  onClick={() => handleSave(consultingHrs)}
                />
              </div>
            </div>
          ) : (
            <div className={`${classes.flex} ${classes.planInfo}`}>
              <div>{consultingHrs}</div>
              <Icon
                icon='edit'
                size='sm'
                color='primary'
                onClick={() => setEditMode(true)}
              />
            </div>
          )}
        </div>
        <div className={classes.planInfo}>{billing?.current_period_start ? new Date(billing.current_period_start).toLocaleDateString() : 'Not Defined'}</div>
        <div className={classes.planInfo}>{billing?.current_period_ends ? new Date(billing.current_period_ends).toLocaleDateString() : 'Not Defined'}</div>
        {/* the line below takes the total revenue in cents, converts to dollars and then formats the number as currency */}
        <div className={classes.planInfo}>{billing?.total_revenue ? `$${(billing.total_revenue / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : '$0.00'}</div>
      </div>
    </div>
  )
}
