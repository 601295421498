import { ModalSection } from 'library/Modal'
import UpdatePlanModal from './UpdatePlanModal'
import { makeStyles } from '@material-ui/core/styles'
import { useContext, useEffect, useState } from 'react'
import { triggerSupportBot } from 'pages/bot/InAppBot/triggerSupportBot'
import { Switch, Typography } from 'library/materialUI'
import { BillingContext } from 'billing-context'
import { getComponentOptions } from 'api/billing'
import { Select, SelectOption } from 'cf-components/material-wrappers/MaterialComponents'
import Loader from 'library/loading/Loader'

const useStyles = makeStyles(theme => ({
  linkText: {
    color: '#0072CE',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: 5
  },
  switch: {
    marginLeft: 0
  }
}))

type UpdateIPLookupModalProps = {
  open: boolean
  onClose: () => void
  onSave: (newQty: number, autoProvision: boolean) => void
  planPrice: number
  allocated: number
  component: string
}

export default function UpdateIPLookupModal ({ open, onClose, onSave, planPrice, allocated, component }: UpdateIPLookupModalProps): JSX.Element {
  const classes = useStyles()
  const { billingSettings } = useContext(BillingContext)
  const [totalPrice, setTotalPrice] = useState(planPrice)
  const [autoProvision, setAutoProvision] = useState(billingSettings?.settings?.auto_provision?.[component] || false)
  const [options, setOptions] = useState<SelectOption[]>([])
  const [loadingOptions, setLoadingOptions] = useState(true)
  const CREDIT_INCREMENT = 100 // Increment amount of credits a user can buy
  const [currentValue, setCurrentValue] = useState(allocated + CREDIT_INCREMENT) // initialize to minimum a user can buy
  const [priceMap, setPriceMap] = useState<Record<number, number>>({})

  useEffect(() => {
    getComponentOptions('reverse-ip').then(
      (response: Record<string, any>) => {
        if (response.data) {
          setOptions(response.data
            .filter((option: Record<string, any>) => option.attributes.quantity !== 0)
            .map((option: Record<string, any>) => ({
              label: `${option.attributes.quantity} Credits (+$${(option.attributes.cost_in_cents / 100)}/month)`,
              value: allocated + option.attributes.quantity
            }))
          )
          setLoadingOptions(false)
          setPriceMap(response.data.reduce((acc: Record<number, number>, option: Record<string, any>) => {
            acc[allocated + option.attributes.quantity] = option.attributes.cost_in_cents / 100
            return acc
          }, {}))
        }
      }
    )
  }, [allocated])

  useEffect(() => {
    if (priceMap[currentValue] !== undefined) {
      setTotalPrice(planPrice + priceMap[currentValue])
    }
  }, [currentValue, priceMap, planPrice])

  return (
    <UpdatePlanModal
      open={open}
      saveDisabled={loadingOptions}
      onClose={onClose}
      planPrice={totalPrice}
      duePrice={priceMap[currentValue]}
      onSave={() => {
        onSave(currentValue, autoProvision)
        setLoadingOptions(true)
      }}
    >
      {loadingOptions ? (<div style={{ height: 291 }}><Loader size='lg' /></div>) : (
        <>
          <ModalSection
            title='Reverse IP Lookups'
            subtitle={`Current credit tier: ${allocated}`}
          >
            <Typography variant='body2'>
              Select additional credits to add to your account
            </Typography>
            <Select
              options={options}
              value={currentValue}
              onChange={(value: number) => {
                setCurrentValue(value)
                // setTotalPrice(totalPrice + (value.price / 100))
              }}
              disableClear
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className={classes.linkText} onClick={() => triggerSupportBot()}>
                Contact support
              </div>
              if you'd like to move to a lower tier
            </div>
          </ModalSection>
          <ModalSection
            title='Auto-upscaling'
          >
            <Switch
              handleChange={(checked) => setAutoProvision(checked)}
              checked={autoProvision}
              label={<div style={{ color: '#7f7f7f' }}>You'll automatically upgrade to the next tier at $30 per 100 lookups and stay there unless you choose a lower one.</div>}
              color='secondary'
              classes={{ root: classes.switch }}
            />
          </ModalSection>
        </>
      )}

    </UpdatePlanModal>
  )
}
