import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Form } from 'formik'
import { AttributeSelector as ContactInput, CustomFieldElement } from '../../components/AttributeFields'
import CustomField from '../../classes/customFields'
import { ModalSection } from 'library/Modal'

const useStyles = makeStyles(theme => ({
  infoDiv: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginTop: 20
  },
  contactInput: {
    width: '100%',
    fontFamily: 'Poppins, sans serif'
  },
  inputField: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    paddingLeft: 20,
    paddingRight: 20
  },
  inputLabel: {
    color: '#7C7C7C',
    fontSize: '13px',
    paddingLeft: 10
  },
  errorDiv: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    fontSize: '10px',
    color: 'red'
  },
  subHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 40,
    margin: '0px 15px 10px 15px',
    backgroundColor: 'rgba(0,0,0,.03)',
    border: '1px solid rgba(0,0,0,.125)'
  },
  dropDownTextBox: {
    display: 'flex',
    width: '100%',
    margin: 0
  },
  dropDownText: {
    fontFamily: 'Poppins, sans serif',
    display: 'flex',
    color: '#4A4A4A',
    fontSize: '14px',
    padding: '0px 5px',
    height: 30
  },
  inputRoot: {
    display: 'flex',
    flexWrap: 'nowrap',
    maxHeight: 33
  },
  accountOptions: {
    minHeight: 20,
    padding: '5px 15px 5px 25px',
    fontFamily: 'Poppins, sans serif',
    color: '#4A4A4A',
    fontSize: '14px'
  },
  businessIcon: {
    height: 16,
    paddingRight: 5,
    paddingBottom: 2
  },
  form: {
    maxHeight: '50vh'
  }
}))

const honorifics = [
  'Mr.',
  'Mrs.',
  'Miss',
  'Ms.',
  'Dr.'
]

function ContactMethods () {
  return (
    <>
      <ContactInput name='honorific' label='Honorific' type='dropdown' values={honorifics} />
      <ContactInput name='email' label='Email *' type='text' />
      <ContactInput name='first_name' label='First Name' type='text' />
      <ContactInput name='last_name' label='Last Name' type='text' />
      <ContactInput name='phone' label='Phone' type='phone' />
      <ContactInput name='mobile_phone' label='Mobile' type='phone' />
      <ContactInput name='city' label='City' type='text' />
      <ContactInput name='state' label='State' type='text' />
      <ContactInput name='country' label='Country' type='text' />
      <ContactInput name='title' label='Title' type='text' />
      <ContactInput name='role' label='Role' type='text' />
      <ContactInput name='seniority' label='Seniority' type='text' />
      <ContactInput name='linkedin_handle' label='LinkedIn Handle' type='text' />
      <ContactInput name='twitter_handle' label='Twitter' type='text' />
      <ContactInput name='facebook_handle' label='Facebook' type='text' />
      <ContactInput name='instagram_handle' label='Instagram' type='text' />
      <ContactInput name='other_phone' label='Alt. Phone' type='phone' />
      <ContactInput name='other_email' label='Alt. Email' type='text' />
      <ContactInput name='lead_source' label='Lead Source' type='text' />
    </>
  )
}

function CompanyMethods () {
  return (
    <>
      <ContactInput name='company_name' label='Company' type='text' />
      <ContactInput name='company_website' label='Website' type='text' />
      <ContactInput name='company_employee_count' label='Employee Count' type='text' />
      <ContactInput name='company_revenue' label='Annual Revenue' type='text' />
      <ContactInput name='company_industry' label='Industry' type='text' />
      <ContactInput name='company_sector' label='Sector' type='text' />
      <ContactInput name='company_phone' label='Company Phone' type='phone' />
      <ContactInput name='company_location' label='Company Location' type='text' />
      <ContactInput name='company_timezone' label='Time Zone' type='text' />
      <ContactInput name='company_twitter_handle' label='Twitter @' type='text' />
      <ContactInput name='company_linkedin_handle' label='LinkedIn Handle' type='text' />
    </>
  )
}

interface EditContactFormProps {
  customFields: CustomField[]
}

function EditContactForm (props: EditContactFormProps) {
  const classes = useStyles()
  return (
    <>
      <Form className={classes.form}>
        <ModalSection
          title='Contact Information'
        >
          <div className={classes.infoDiv}>
            <ContactMethods />
          </div>
          <ContactInput name='description' label='Description' type='text' />
        </ModalSection>
        <ModalSection
          title='Company Information'
        >
          <div className={classes.infoDiv}>
            <CompanyMethods />
          </div>
          <div>
            <ContactInput name='company_description' label='Company Description' type='description' />
          </div>
          <div>
            <ContactInput name='company_tech' label='Company Tech' type='multiple' />
          </div>
        </ModalSection>
        {props.customFields.length > 0 && (
          <ModalSection
            title='Additional Details'
          >
            <div className={classes.infoDiv}>
              {props.customFields.map((field: CustomField) => (
                <CustomFieldElement field={field} key={field.id} />
              ))}
            </div>
          </ModalSection>
        )}
      </Form>

    </>
  )
}

export default EditContactForm
