import { doPost, doDelete, doGet } from './api'

export type SegmentFilterValue = string | string[] | number | number[]

export interface SegmentAttributes {
  name: string
  segment_type: 'dynamic' | 'static'
  created_by_user_id: number
  tenant_id: number
  triggers: {
    filters: {
      cmp: string
      prop: string
      value: SegmentFilterValue
    }[]
  }[]
  deleted_timestamp: null | string
  created_timestamp: string
  updated_timestamp: string
  object_type: 'domains' | 'contacts'
  object_count: number
  description: string | null
  ui_version: string
}

export interface Segment<IsPartial extends boolean = false> {
  id: IsPartial extends false ? number : (number | undefined)
  attributes: IsPartial extends false ? SegmentAttributes : Partial<SegmentAttributes>
  relationships?: {
    count?: number
  }
}

export const getSegment = (segmentId: number | string): Promise<{ data: Segment }> => doGet({
  path: `/segments/${segmentId}`,
  useChatURL: true
})

export const getSegmentsFromDomain = (segmentDomain: string): Promise<{ data: Segment[] }> => {
  return doGet({
    path: `/segments/domain/${segmentDomain}`,
    useChatURL: true
  })
}

export const getSegments = ({ includes, objectType, connectionID }: {
  objectType: 'contacts' | 'domains';
  includes?: string[];
  connectionID?: number
}): Promise<{ data: Segment[] }> => {
  let url = `/segments?filter[object_type]=${objectType}`

  if (connectionID) {
    url += '&filter[connection_to_segment]=' + connectionID
  }

  if (includes?.length) {
    url += '&include=' + includes.join(',')
  }

  return doGet({ path: url, useChatURL: true })
}

export const reEvaluateSegment = ({ segmentID }: { segmentID: number }): Promise<any> => {
  return doPost({
    path: `/segments/${segmentID}/re_evaluate`,
    useChatURL: true,
    data: {}
  })
}

export const deleteSegment = ({ segmentId }: { segmentId: number }): Promise<any> => {
  return doDelete({ path: `/segments/${segmentId}`, useChatURL: true })
}

export const getSegmentOverview = (startDate: Date, endDate: Date): Promise<any> => {
  const params = new URLSearchParams()
  params.set('start_date', startDate.toISOString())
  params.set('end_date', endDate.toISOString())

  return doGet({ path: '/reports/account_segments_overview?' + params.toString(), useChatURL: true })
}
