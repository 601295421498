import { makeStyles } from '@material-ui/core/styles'
import Form, { FormField } from 'classes/forms'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { useEffect, useState } from 'react';
import { Icon, Radio } from 'library/materialUI';
import { SettingsDrawer } from 'library/SettingsDrawer';
import FieldSettings, { ButtonSettings } from './FieldSettings';
import { accountFields as accFields, dropdownFields as contFields } from 'pages/settings/Integrations/MappingRow'
import parse from 'html-react-parser'
import Checkbox from '@material-ui/core/Checkbox'
import { FieldInfo } from './FormFieldBar';
import { random } from 'lodash';
import { getIntegrationFeature } from 'api/integrations';

const useStyles = makeStyles(theme => ({
  formBuilder: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '82vh',
    overflow: 'auto',
    backgroundColor: '#F5F5F5'
  },
  formView: {
    marginTop: 'auto',
    marginBottom: 'auto',
    minWidth: '482px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '5px'
  },
  formContent: {
    margin: '30px',
    marginBottom: '2px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  formTitle: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 'bold',
    marginLeft: '9px',
    '& .ql-align-center': {
      textAlign: 'center'
    },
    '& .ql-align-left': {
      textAlign: 'left'
    },
    '& .ql-align-right': {
      textAlign: 'right'
    }
  },
  formSubtitle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    marginLeft: '10px',
    '& .ql-align-center': {
      textAlign: 'center'
    },
    '& .ql-align-left': {
      textAlign: 'left'
    },
    '& .ql-align-right': {
      textAlign: 'right'
    }
  },
  fieldItem: {
    padding: '10px',
    width: '100%',
    border: '1px solid #FFFFFF00',
    position: 'relative',
    transitionDuration: '0.001s',
    '&:hover': {
      border: '1px solid #9933FF',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px'
    }
  },
  fieldName: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#00000080',
    height: '20px',
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  submitOutline: {
    margin: '28px',
    marginTop: '1px',
    borderRadius: '5px',
    border: '1px solid #00000000',
    position: 'relative',
    '&:hover': {
      border: '2px solid #9933FF',
      backgroundColor: '#E2E2E2'
    }
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '34px',
    backgroundColor: '#343434',
    color: '#FFFFFF',
    fontSize: '14px',
    margin: '8px',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  textBox: {
    whiteSpace: 'nowrap',
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '35px',
    with: '100%',
    overflow: 'hidden',
    border: '1px solid #C4C4C4',
    color: '#C4C4C4',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px'
  },
  buttonDiv: {
    width: '21px',
    height: '21px',
    backgroundColor: '#9933FF',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  dropDiv: {
    height: '10px',
    width: '420px'
  },
  lastDropDiv: {
    border: '1px dashed #9933FF',
    backgroundColor: '#F6F2F8',
    height: '10px',
    width: '420px',
    '&:hover': {
      height: '40px'
    }
  },
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  checkboxLabel: {
    color: '#343434',
    fontFamily: 'poppins',
    fontSize: '14px',
    fontWeight: 'bold'
  }
}))

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const move = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}

const getFieldName = (fieldID: string) => {
  const matchingAccField = accFields.filter((field) => field.value === fieldID)
  const matchingContactField = contFields.filter((field) => field.value === fieldID)

  if (matchingAccField.length > 0) {
    return matchingAccField[0].label
  }
  if (matchingContactField.length > 0) {
    return matchingContactField[0].label
  }
}

const getButtonPlacement = (buttonPlace: string) => {
  if (buttonPlace === 'right') {
    return 'flex-end'
  } else if (buttonPlace === 'left') {
    return 'flex-start'
  } else if (buttonPlace === 'center') {
    return buttonPlace
  } else {
    return undefined
  }
}

interface FormBuilderProps {
  form: Form
  dispatch: any
  insertDrag: boolean
  insertInfo: FieldInfo | undefined
  setCanSave: (value: boolean) => void
}

export default function FormBuilder ({ form, dispatch, insertDrag, insertInfo, setCanSave }: FormBuilderProps) {
  const classes = useStyles()
  const [hoverOuter, setHoverOuter] = useState(-1)
  const [hoverInner, setHoverInner] = useState(-1)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [fieldID, setFieldID] = useState('')
  const [fieldType, setFieldType] = useState('')
  const [isCustomField, setIsCustomField] = useState(false)
  const [rowEdit, setRowEdit] = useState(0)
  const [itemEdit, setItemEdit] = useState(0)
  const [dragging, setDragging] = useState(false)
  const [currentRow, setCurrentRow] = useState(-1)
  const [newGroupIndex, setNewGroupIndex] = useState(-1)
  const [buttonSettingsOpen, setButtonSettingsOpen] = useState(false)
  const [buttonHover, setButtonHover] = useState(false)
  const [integration, setIntegration] = useState('')
  const [newFieldIndex, setNewFieldIndex] = useState(-1)

  const handleButtonSettings = () => {
    setDrawerOpen(false)
    setButtonSettingsOpen(true)
  }

  const handleMouseLeave = (innerIndex: number, outerIndex: number) => {
    if (hoverOuter === outerIndex && hoverInner === innerIndex) {
      setHoverInner(-1)
      setHoverOuter(-1)
    }
  }

  const isHovered = (innerIndex: number, outerIndex: number) => {
    if (hoverInner === innerIndex && hoverOuter === outerIndex) {
      return true
    }
    return false
  }

  const deleteEmptyRows = () => {
    if (form.formLayout) {
      form.formLayout.forEach((row, index) => {
        if (row.length < 1) {
          dispatch({ type: 'deleteRow', value: null, index: index })
          setCanSave(true)
        }
      })
    }
  }

  const handleEdit = () => {
    setButtonSettingsOpen(false)
    if (drawerOpen) {
      setDrawerOpen(false)
    }
    const layout = form?.formLayout
    if (layout) {
      setFieldID(layout[hoverOuter][hoverInner].fieldID)
      setFieldType(layout[hoverOuter][hoverInner].fieldType)
      setIsCustomField(layout[hoverOuter][hoverInner].isCustomField)
    }
    setDrawerOpen(true)
    setRowEdit(hoverOuter)
    setItemEdit(hoverInner)
  }

  const handleDelete = (row: number, index: number) => {
    if (drawerOpen) {
      setDrawerOpen(false)
    }
    const layout = form.formLayout
    if (layout) {
      const items = layout[row]
      items.splice(index, 1)
      dispatch({ type: 'updateRow', value: items, index: row })
      setCanSave(true)
    }
  }

  function onDragEnd (result: any) {
    if (form.formLayout) {
      const { source, destination } = result
      const sInd = +source.droppableId;
      let destIndex = -1
      if (destination) {
        destIndex = +destination.droppableId
      }
      const dInd = destIndex
      setDragging(false)
      // Moving existing item from existing row to new row
      if (newGroupIndex !== -1) {
        if (newGroupIndex === sInd && form.formLayout[sInd].length === 1) {
          return
        }
        dispatch({ type: 'addRow', value: [form.formLayout[sInd][source.index]], index: newGroupIndex })
        const tempRow = form.formLayout[sInd]
        const removed = [...tempRow.slice(0, source.index), ...tempRow.slice(source.index + 1)]
        dispatch({ type: 'updateRow', value: removed, index: (sInd >= newGroupIndex) ? sInd + 1 : sInd })
        setNewGroupIndex(-1)
        setCanSave(true)
      } else {
        // dropped outside the list
        if (!destination) {
          return
        }
        if (drawerOpen) {
          setDrawerOpen(false)
        }
        // Reorder row items - move from one group to another (prevent title and subtitle form doing so)
        if (form.formLayout[sInd][0].fieldType === 'field' && form.formLayout[dInd][0].fieldType === 'field') {
          if (sInd === dInd) {
            const items = reorder(form.formLayout[sInd], source.index, destination.index)
            dispatch({ type: 'updateRow', value: items, index: sInd })
            setCanSave(true)
          } else {
            // Reached max limit of 3 items per row
            if (form.formLayout[dInd].length >= 3) {
              return
            }
            const result = move(form.formLayout[sInd], form.formLayout[dInd], source, destination);
            dispatch({ type: 'updateRow', value: result[sInd], index: sInd })
            dispatch({ type: 'updateRow', value: result[dInd], index: dInd })
            setCanSave(true)
          }
        }
      }
    }
  }

  const handleAddItem = (id: string, label: string, type: 'account' | 'contact', kind: string, optionList: string[] = [], index: number, isCustomField: boolean) => {
    let duplicate = false
    if (form.formLayout) {
      form.formLayout.forEach((row: FormField[]) => {
        row.forEach((item: FormField) => {
          if (item.fieldID === id) {
            duplicate = true
          }
        })
      })
    }
    if (duplicate) {
      return
    }
    const newFormField: FormField = {
      id: random(1, 10000, false),
      fieldType: 'field',
      fieldID: id,
      fieldLabel: label,
      placeholder: kind === 'date' ? 'mm/dd/yyyy' : '',
      helperText: '',
      isRequired: false,
      isHidden: false,
      options: optionList,
      blockEmail: false,
      spamUnwanted: false,
      nonBusinessUnwanted: false,
      blockedDomainsUnwanted: false,
      sourceType: type,
      inputType: kind,
      hiddenDefault: '',
      isCustomField: isCustomField
    }
    dispatch({ type: 'addRow', value: [newFormField], index: index })
    setCanSave(true)
  }

  function getStyle (style: any, snapshot: any) {
    if (!snapshot.isDropAnimating) {
      return {
        ...style,
        width: snapshot.isDragging ? '200px' : undefined
      }
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: '0.001s',
      width: snapshot.isDragging ? '200px' : undefined
    };
  }

  useEffect(() => {
    if (!insertDrag) {
      if (newFieldIndex !== -1) {
        handleAddItem(insertInfo?.id || '', insertInfo?.label || '', 'contact', insertInfo?.kind || '', insertInfo?.optionList || [], newFieldIndex, insertInfo?.isCustomField || false)
        setCanSave(true)
      }
      setNewFieldIndex(-1)
    }
    // eslint-disable-next-line
  }, [insertDrag])

  useEffect(() => {
    if (form.formLayout) {
      deleteEmptyRows()
    }
    // eslint-disable-next-line
  }, [form.formLayout])

  useEffect(() => {
    getIntegrationFeature('crm.abe').then((resp: any) => {
      if (resp.data.attributes.integration.name) {
        setIntegration(resp.data.attributes.integration.name)
      }
    })
  }, [])

  return (
    <div className={classes.formBuilder}>
      <div className={classes.formView} style={{ marginRight: (drawerOpen || buttonSettingsOpen) ? '390px' : undefined }}>
        <div className={classes.formContent}>
          <DragDropContext onDragEnd={onDragEnd}>
            {(form?.formLayout?.length || 0) > 0 ? form.formLayout?.map((row, index) => {
              const outerIndex: number = index
              const rowSize = row.length
              return (
                <div key={index}>
                  <Droppable key={index} droppableId={`${index}`} direction='horizontal' isDropDisabled={newGroupIndex !== -1 || currentRow !== outerIndex}>
                    {(provided) => (
                      <>
                        <div onDragEnter={() => setNewFieldIndex(index)} onMouseEnter={() => setNewGroupIndex(outerIndex)} onMouseLeave={() => setNewGroupIndex(-1)} className={classes.dropDiv} style={{ display: 'block', height: ((newGroupIndex === outerIndex || newFieldIndex === outerIndex) && dragging) ? '45px' : '15px', backgroundColor: ((newGroupIndex === outerIndex || newFieldIndex === outerIndex) && dragging) ? '#C4C4C455' : '#C4C4C400' }} />
                        <div ref={provided.innerRef} onMouseEnter={() => setCurrentRow(index)} onMouseLeave={() => setCurrentRow(-1)} style={{ display: 'flex', flexDirection: 'row', backgroundColor: (dragging && currentRow === index) ? '#C4C4C455' : '#C4C4C400' }} {...provided.droppableProps}>
                          {
                            row.map((field, draggableIndex) => (
                              <Draggable
                                key={field.id}
                                draggableId={field.id.toString()}
                                index={draggableIndex}
                              >
                                {(provided, snapshot) => (
                                  <div onDoubleClick={() => handleEdit()} onMouseEnter={() => { setHoverOuter(outerIndex); setHoverInner(draggableIndex) }} onMouseLeave={() => handleMouseLeave(draggableIndex, outerIndex)} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={draggableIndex} className={classes.fieldItem} style={getStyle(provided.draggableProps.style, snapshot)}>
                                    <div style={{ display: isHovered(draggableIndex, outerIndex) ? 'flex' : 'none', float: 'left', height: '100%', marginRight: '10px', justifyContent: 'center', alignItems: 'center' }}>
                                      <DragIndicatorIcon />
                                    </div>
                                    {snapshot.isDragging && (setDragging(true))}
                                    {field.fieldType === 'field' ? (
                                      <div>
                                        <div className={classes.fieldName}>
                                          <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', maxWidth: rowSize === 3 ? '100px' : rowSize === 2 ? '140px' : '300px', overflow: 'hidden' }}>
                                            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                              {field.fieldLabel + (field.isRequired ? '*' : '')}
                                            </div>
                                            {field.isHidden && (
                                              <div style={{ marginLeft: '10px' }}>
                                                <Icon icon='hidePassword' color='#00000080' size='md' />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {(field.inputType === 'text' || field.inputType === 'numeric' || field.inputType === 'dropdown' || field.inputType === 'date') ? (
                                          <div className={classes.textBox} style={{ color: field.isHidden ? '#000000' : undefined }}>
                                            <div style={{ maxWidth: rowSize === 3 ? '100px' : rowSize === 2 ? '140px' : '300px', overflow: 'hidden', marginLeft: '8px' }}>
                                              {field.isHidden ? field.hiddenDefault : field.placeholder}
                                            </div>
                                            {field.inputType === 'dropdown' && (
                                              <div style={{ justifySelf: 'flex-end', marginLeft: 'auto', marginRight: '5px' }}>
                                                <Icon icon='arrowDropDown' size='lg' color='#343434' />
                                              </div>
                                            )}
                                            {field.inputType === 'date' && (
                                              <div style={{ justifySelf: 'flex-end', marginLeft: 'auto', marginRight: '5px' }}>
                                                <Icon icon='blankCalendar' size='md' color='#343434' />
                                              </div>
                                            )}
                                          </div>
                                        ) : field.inputType === 'true-false' ? (
                                          <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                            <div className={classes.checkboxDiv}>
                                              <Checkbox
                                                color='primary'
                                                checked
                                                name={field.fieldLabel}
                                                onClick={() => null}
                                              />
                                              <div className={classes.checkboxLabel}>
                                                {field.placeholder}
                                              </div>
                                            </div>
                                          </div>
                                        ) : field.inputType === 'radio' ? (
                                          <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                            {field.options.map((item, draggableIndex) => {
                                              return (
                                                <Radio
                                                  key={draggableIndex}
                                                  label={item}
                                                  value={draggableIndex === 1}
                                                  checked={draggableIndex === 1}
                                                  handleChange={() => null}
                                                />
                                              )
                                            })}
                                          </div>
                                        ) : field.inputType === 'multi' ? (
                                          <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                            {field.options.map((item, draggableIndex) => {
                                              return (
                                                <div key={draggableIndex} className={classes.checkboxDiv}>
                                                  <Checkbox
                                                    color='primary'
                                                    checked={draggableIndex === 1}
                                                    name={item}
                                                    onClick={() => null}
                                                  />
                                                  <div className={classes.checkboxLabel}>
                                                    {item}
                                                  </div>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        ) : <></>}
                                        <div style={{ whiteSpace: 'nowrap', fontSize: '12px', fontFamily: 'poppins', color: '#00000080' }}>
                                          <div style={{ maxWidth: rowSize === 3 ? '100px' : rowSize === 2 ? '200px' : '300px', overflow: 'hidden' }}>
                                            {field.helperText}
                                          </div>
                                        </div>
                                      </div>)
                                      : field.fieldType === 'title' ? (
                                        <div className={classes.formTitle}>
                                          {parse(field.fieldLabel)}
                                        </div>)
                                        : field.fieldType === 'subtitle' ? (
                                          <div className={classes.formSubtitle}>
                                            {parse(field.fieldLabel)}
                                          </div>)
                                          : <></>}
                                    <div style={{ display: isHovered(draggableIndex, outerIndex) ? 'block' : 'none', position: 'absolute', top: -10, right: 16 }}>
                                      <div onClick={() => handleEdit()} className={classes.buttonDiv}>
                                        <Icon icon='edit' size='sm' color='#FFFFFF' />
                                      </div>
                                    </div>
                                    <div style={{ display: isHovered(draggableIndex, outerIndex) ? 'block' : 'none', position: 'absolute', top: -10, right: -12 }}>
                                      <div onClick={() => handleDelete(outerIndex, draggableIndex)} className={classes.buttonDiv}>
                                        <Icon icon='delete' size='sm' color='#FFFFFF' />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))
                          }
                          {provided.placeholder}
                        </div>
                      </>
                    )}
                  </Droppable>
                  {form?.formLayout && outerIndex === form?.formLayout?.length - 1 && (
                    <div onDragEnter={() => setNewFieldIndex(form?.formLayout?.length || -1)} onMouseEnter={() => setNewGroupIndex(form?.formLayout?.length || -1)} onMouseLeave={() => setNewGroupIndex(-1)} className={classes.dropDiv} style={{ display: (dragging || insertDrag) ? 'block' : 'none', height: (newGroupIndex === form.formLayout.length || newFieldIndex === form.formLayout.length) ? '100px' : '20px', backgroundColor: (newGroupIndex === form.formLayout.length || newFieldIndex === form.formLayout.length) ? '#C4C4C455' : '#C4C4C400' }} />
                  )}
                </div>
              )
            }) : (
              <div onDragEnter={() => setNewFieldIndex(0)} onMouseEnter={() => setNewGroupIndex(0)} onMouseLeave={() => setNewGroupIndex(-1)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '140px', border: '2px dashed #C4C4C4', marginBottom: '20px', color: '#5F5F5F', fontSize: '14px' }}>
                Drag fields here
              </div>
            )}
          </DragDropContext>
        </div>
        <div style={{ display: 'flex', justifyContent: getButtonPlacement(form.buttonPlacement) }}>
          <div onMouseEnter={() => setButtonHover(true)} onMouseLeave={() => setButtonHover(false)} className={classes.submitOutline}>
            <div className={classes.submitButton} style={{ width: form.buttonPlacement === 'full' ? '100%' : undefined }}>
              <div style={{ margin: '18px', display: 'flex', alignItems: 'center' }}>
                <div>
                  {form.buttonText}
                </div>
                {form.useButtonIcon &&
                  <div style={{ marginLeft: 10 }}>
                    <Icon icon={form.buttonIcon} size='md' color='#FFFFFF' />
                  </div>}
              </div>
            </div>
            <div style={{ display: buttonHover ? 'block' : 'none', position: 'absolute', top: -10, right: -10 }}>
              <div onClick={() => handleButtonSettings()} className={classes.buttonDiv}>
                <Icon icon='edit' size='sm' color='#FFFFFF' />
              </div>
            </div>
          </div>
        </div>
      </div>
      {drawerOpen && (
        <SettingsDrawer
          open={drawerOpen}
          handleClose={() => setDrawerOpen(false)}
          title={'Edit ' + (getFieldName(fieldID) || 'field')}
          setWidth='390px'
          orientation='right'
          clickAwayClose
          stickyHeader
        >
          <FieldSettings form={form} dispatch={dispatch} row={rowEdit} itemIndex={itemEdit} fieldID={fieldID} fieldType={fieldType} setCanSave={setCanSave} integration={integration} isCustomField={isCustomField} />
        </SettingsDrawer>
      )}
      {buttonSettingsOpen && (
        <SettingsDrawer
          open={buttonSettingsOpen}
          handleClose={() => setButtonSettingsOpen(false)}
          title='Edit Button'
          setWidth='390px'
          orientation='right'
          clickAwayClose
          stickyHeader
        >
          <ButtonSettings dispatch={dispatch} buttonText={form?.buttonText || ''} buttonPlacement={form?.buttonPlacement || ''} buttonIcon={form.buttonIcon || 'arrowForward'} useButtonIcon={form.useButtonIcon || false} setCanSave={setCanSave} />
        </SettingsDrawer>
      )}
    </div>
  )
}
