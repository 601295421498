import { useEffect, useContext, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getFeatureFlags } from 'api/feature_flags'
import { SurfaceDropComponent } from 'jsplumbtoolkit-react-drop'
import { Card, Typography } from '@material-ui/core'
import { FlowBuilderContext, FlowType } from 'library/flowBuilder/canvas/FlowBuilderProvider'
import SearchBar from 'components/SearchBar'
import { Icon, Tabs } from 'library/materialUI'
import { Sequence } from 'classes/sequences'
import { SearchParams } from 'classes/queryHelpers'
import NewPlayButton from './NewPlayButton'
import { useQuery } from 'react-query'
import { hasComponent } from 'api/billing'
import { GetEnabledIntegrations } from 'helpers/integrations'
// import SalesloftPlayRunnerOnboarding from 'cf-components/SalesloftPlayRunnerOnboarding'

interface draggerProps {
  nodeType: string
  needsUpgrade?: boolean
}

const useStyles = makeStyles(theme => ({
  categoryHeader: {
    paddingBottom: 5,
    color: 'rgba(58, 61, 92, 0.56)',
    textTransform: 'uppercase',
    fontSize: '15px'
  },
  nodeContainer: {

  },
  contents: {
    padding: 8,
    borderTop: '1px solid rgba(0,0,0,0.2)'
  },
  dragger: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    width: 220,
    marginBottom: 15,
    zIndex: 1500,
    position: 'relative',
    cursor: 'grab',
    border: '1px solid #D3D3D3',
    borderRadius: '5px',
    boxShadow: '0 0 0 0',
    '&:active': {
      cursor: 'grabbing'
    }
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 10,
    borderRadius: 5
  },
  icon: {
    fontSize: '2em',
    marginRight: 10
  },
  searchDiv: {
    paddingTop: 10,
    paddingBottom: 20
  },
  sequenceHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgb(51, 51, 153)',
    padding: '5px 10px'
  },
  sequenceDescription: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: '13px'
  },
  newPlayButton: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
    width: '244px',
    height: '44px',
    borderRadius: '5px',
    boxShadow: '0px 3px 6px #00000029',
    cursor: 'pointer',
    marginBottom: '12px',
    marginTop: '12px'
  }
}))

function Dragger (props: draggerProps): JSX.Element {
  const classes = useStyles()
  const { nodeMapping } = useContext(FlowBuilderContext)
  const type: string = props.nodeType
  const mapping = nodeMapping[type]

  return (
    <Card
      node-type={type}
      title='Drag to add new skill'
      className={`${classes.dragger} dragger`}
    >
      <div className={classes.iconDiv}>
        <Icon
          icon={mapping.icon}
          size='lg'
          color={mapping.color}
        />
      </div>
      <Typography variant='h2'>
        {mapping.title}
      </Typography>
      {props.needsUpgrade && (
        <div style={{ marginLeft: 5, borderRadius: 20, backgroundColor: '#9933FF' }}>
          <Icon icon='upArrow' size='sm' color='white' />
        </div>
      )}
    </Card>
  )
}

function SequenceDragger ({ sequence }: { sequence: Sequence }) {
  const classes = useStyles()

  return (
    <Card
      node-type='sequence'
      className={`${classes.dragger} dragger`}
      sequence-description={sequence.description}
      sequence-name={sequence.name}
      sequence-id={sequence.id}
      sequence-ports={JSON.stringify(sequence.ports)}
      style={{ display: 'block', padding: 0, width: 240 }}
    >
      <div className={classes.sequenceHeader}>
        <div className={classes.iconDiv}>
          <Icon
            icon='play'
            size='lg'
            color='white'
          />
        </div>
        <Typography variant='h2' style={{ color: 'white' }}>
          {sequence.name}
        </Typography>
      </div>
      <div style={{ padding: 10 }}>
        <div className={classes.sequenceDescription}>
          {sequence.description}
        </div>
      </div>
    </Card>
  )
}

function PlaysSection ({ search }: any) {
  const searchParams: SearchParams = {
    filters: [[
      { field: 'object_type', operator: 'eq', value: 'plays' }
    ]],
    sortColumn: 'name'
  }

  const { data: plays } = Sequence.loadAll({ searchParams })

  return (
    <div>
      {plays.list.filter((play: Sequence) => play.name.toLowerCase().includes(search))
        .map((play: Sequence) => (<SequenceDragger sequence={play} key={play.id} />))}
    </div>
  )
}

function SkillsSection ({ search, value, objectType }: any) {
  const classes = useStyles()
  const { nodeCategories } = useContext(FlowBuilderContext)
  const [flags, setFlags] = useState({})
  const [loading, setLoading] = useState(true)
  const [enabledIntegrations, setEnabledIntegrations] = useState(
    {
      salesforce: false,
      marketo: false,
      hubspot: false,
      sendinblue: false,
      eloqua: false,
      salesloft: false
    }
  )
  const { data: hasCreateAccountComponent } = useQuery('has-create-account-component', () => hasComponent('playrunner-create-accounts'))
  const nodeTypeComponentMapping: Record<string, void | undefined> = {
    CreateAccount: hasCreateAccountComponent
  }
  useEffect(() => {
    getFeatureFlags().then((flags: any) => {
      // not using session context because of the challenges w/ jsplumb's React
      setFlags(flags)
      setLoading(false)
    })
  }, [])

  useMemo(() => {
    GetEnabledIntegrations().then((newIntegrations) => setEnabledIntegrations(newIntegrations))
  }, [])

  if (loading) {
    return <></>
  }

  if (value === 'skills') {
    return (
      <div>
        {nodeCategories.filter((c) => {
          return ['buying signals', 'attributes', 'internal'].includes(c.label.toLowerCase()) || objectType !== 'plays'
        }).map((c, index) => {
          const catLabel = c.label.toLowerCase()
          const nodes = c.nodes.filter((node: any) => {
            const nodeLabel = node.label.toLowerCase()
            if (objectType === 'plays' && catLabel === 'internal' && nodeLabel !== 'end play') {
              return false
            }
            if (catLabel === 'integrations') {
              return false
            }
            return nodeLabel.includes(search)
          })
          if (nodes.length === 0) {
            return (<></>)
          }
          return (
            <div key={index} style={{ paddingBottom: 10 }}>
              <Typography variant='h4'>{c.label}</Typography>
              <div className={classes.nodeContainer}>
                {nodes.filter((node: any) => {
                  if (node.label === 'Salesloft') {
                    return flags?.salesloft
                  }
                  if (node.label === 'Integration Signal') {
                    return enabledIntegrations.salesloft
                  }
                  return true
                }).map((node: any) => {
                  let nodeID = ''
                  if (node.label === 'Integration Signal' && objectType === 'sequences') {
                    nodeID = 'sl_visitor_branching'
                  } else if (node.label === 'Salesloft') {
                    nodeID = 'salesloft_skill'
                  }
                  if (['SendEmail', 'Salesforce', 'Marketo', 'Hubspot', 'Sendinblue', 'Eloqua', 'Salesloft'].includes(node.type)) {
                    return <></>
                  }
                  return (
                    <div
                      key={node.type}
                      id={nodeID}
                    >
                      <Dragger
                        nodeType={node.type}
                        needsUpgrade={nodeTypeComponentMapping[node.type] !== undefined ? !nodeTypeComponentMapping[node.type] : false}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  } else if (value === 'plays') {
    return (
      <PlaysSection
        search={search}
      />
    )
  }

  return (
    <div>
      Other
    </div>
  )
}

type DrawerTabsProps = {
  value: string
  setValue: (value: string) => void
  objectType: FlowType
}

function DrawerTabs ({ objectType, value, setValue }: DrawerTabsProps) {
  if (objectType === 'sequences') {
    return <></>
  }
  const tabs = objectType === 'plays' ? ['skills', 'plays'] : ['skills', 'sequences']

  return (
    <Tabs tabs={tabs} value={value} setValue={setValue} />
  )
}

function NodeContainer (): JSX.Element {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [value, setValue] = useState('skills')

  const { objectType } = useContext(FlowBuilderContext)

  return (
    <div id='flow-builder-drawer'>
      <DrawerTabs
        objectType={objectType}
        value={value}
        setValue={setValue}
      />
      <div className={classes.contents}>
        <div>
          {objectType === 'plays' && (<NewPlayButton />)}
        </div>
        <div className={classes.searchDiv}>
          <SearchBar search={search} setSearch={setSearch} />
        </div>
        <SkillsSection
          objectType={objectType}
          search={search.toLowerCase()}
          value={value}
        />
      </div>
      {/* <SalesloftPlayRunnerOnboarding /> */}
    </div>
  )
}

export class NodeSource extends SurfaceDropComponent {
  render (): JSX.Element {
    return (
      <NodeContainer />
    )
  }
}
