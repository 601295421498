
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import AgentReport from './AgentReportPage'
import Dashboard from './Dashboard'
import { track } from 'userpilot.js'
import ReportsNavBar from './ReportsNavBar'
import { Route, Switch } from 'react-router-dom'
import AccessControl from 'components/AccessControl'
import { components } from 'session-context'
import VisitorReportPage from './VisitorReporting'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  nav: {
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderColor: '#d3d3d3'
  },
  reportContent: {
    flexGrow: 1
  }
}))

function OverviewPage () {
  const classes = useStyles()
  useEffect(() => {
    track('Reports Viewed')
  })

  document.title = 'ROI Overview | Signals'

  return (

    <div className={classes.root}>
      <AccessControl requiredComponent={components.REPORTS}>
        <div className={classes.nav}>
          <ReportsNavBar />
        </div>
        <div className={classes.reportContent}>
          <Switch>
            <Route
              exact
              path='/reports/conversations'
              component={Dashboard}
            />
            <Route
              exact
              path='/reports/visitors'
              component={VisitorReportPage}
            />
            <Route
              exact
              path='/reports/agent_report'
              component={AgentReport}
            />
          </Switch>
        </div>
      </AccessControl>
    </div>
  )
}

export default OverviewPage
