import { makeStyles } from '@material-ui/core/styles'
import { Icon } from 'library/materialUI';
import DashboardCard from 'pages/dashboard/DashboardCard';
import { useContext } from 'react';
import { SessionContext } from 'session-context';

const useStyles = makeStyles(theme => ({
  attribute: {
    marginBottom: '10px'
  },
  label: {
    color: '#808080',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    fontSize: '15px'
  },
  value: {
    fontSize: '14px',
    marginLeft: '30px',
    marginTop: '5px'
  },
  contactDetail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxHeight: '446px',
    overflow: 'auto'
  }
}))

interface PhoneValueProps {
  title: string
  number?: string
}

function PhoneValue ({ title, number }: PhoneValueProps): JSX.Element {
  const classes = useStyles()
  const { setSnackState } = useContext(SessionContext)

  return (
    <div className={classes.attribute}>
      <div className={classes.label}>
        <Icon icon='call' color='#808080' />
        <div>
          {title}
        </div>
      </div>
      <div className={classes.value} style={{ display: 'flex' }}>
        {number || 'None'}
        {number && (
          <div
            style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => navigator.clipboard.writeText(number).then(
              () => {
                setSnackState({
                  open: true,
                  variant: 'success',
                  message: `${title} copied to clipboard!`
                })
              })}
          >
            <Icon icon='copy' color='#808080' />
          </div>
        )}
      </div>
    </div>
  )
}

interface ContactAboutProps {
  contact: any
  customFields: any
  setEditModalOpen: (value: boolean) => void
}

export default function ContactAbout ({ contact, customFields, setEditModalOpen }: ContactAboutProps): JSX.Element {
  const classes = useStyles()
  const { setSnackState } = useContext(SessionContext)

  return (
    <DashboardCard
      title='About'
      noScrollbox
      titleAction={
        <div onClick={() => setEditModalOpen(true)} style={{ border: '1px solid #D3D3D3', borderRadius: '5px', padding: '8px 15px 8px 15px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
          <div>
            <Icon icon='edit' color='#808080' size='sm' />
          </div>
          <div style={{ marginLeft: '10px', fontSize: '14px' }}>
            Edit
          </div>
        </div>
      }
    >
      <div className={classes.contactDetail}>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='email' color='#808080' />
            <div>
              Email
            </div>
          </div>
          <div className={classes.value} style={{ display: 'flex' }}>
            {contact.email || 'None'}
            {contact.email && (
              <div
                style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => navigator.clipboard.writeText(contact.email).then(
                  () => {
                    setSnackState({
                      open: true,
                      variant: 'success',
                      message: 'Email copied to clipboard!'
                    })
                  })}
              >
                <Icon icon='copy' color='#808080' />
              </div>
            )}
          </div>
        </div>
        <PhoneValue title='Phone' number={contact.phone} />
        <PhoneValue title='Mobile' number={contact.mobile_phone} />
        <PhoneValue title='Alt. Phone' number={contact.other_phone} />
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='work' color='#808080' />
            <div>
              Title
            </div>
          </div>
          <div className={classes.value}>
            {contact.title || 'None'}
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='star' color='#808080' />
            <div>
              Seniority
            </div>
          </div>
          <div className={classes.value}>
            {contact.seniority || 'None'}
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='location' color='#808080' />
            <div>
              City
            </div>
          </div>
          <div className={classes.value}>
            {contact.city || 'None'}
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='location' color='#808080' />
            <div>
              State
            </div>
          </div>
          <div className={classes.value}>
            {contact.state || 'None'}
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='location' color='#808080' />
            <div>
              Country
            </div>
          </div>
          <div className={classes.value}>
            {contact.country || 'None'}
          </div>
        </div>
        <div className={classes.attribute}>
          <div className={classes.label}>
            <Icon icon='star' color='#808080' />
            <div>
              Lead Source
            </div>
          </div>
          <div className={classes.value}>
            {contact.lead_source || 'None'}
          </div>
        </div>
        {customFields.map((field: any, index: number) => {
          return (
            <div key={index} className={classes.attribute}>
              <div className={classes.label}>
                <Icon icon='editSquare' color='#808080' />
                <div>
                  {field.name}
                </div>
              </div>
              <div className={classes.value}>
                {contact.custom_fields[field.slug] || 'None'}
              </div>
            </div>
          )
        })}
      </div>
    </DashboardCard>
  )
}
